<template>
  <div>
    <v-dialog v-model="data.dialog" width="600" :persistent="true">
      <v-card class="card-corner-radius">
        <v-card-title>
          <v-icon color="primary" class="mr-2">{{icon ? icon : `mdi-alert`}}</v-icon>
          {{title ? title : $t('areYouSure')}}
        </v-card-title>
        <v-card-title>
          {{ $t('operationCannotBeUndone') }} 
        </v-card-title>
        <v-card-text v-if="text">
          <div v-html="text"/>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="data.dialog = false" outlined color="primary" large :disabled="loading">
            {{$t('cancel')}}
          </v-btn>
          <v-spacer/>
          <v-btn @click="confim" color="primary" large :loading="loading">
            {{$t('toConfirm')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {
    name: 'ConfirmOperation',
    props: ['data', 'confimAction', 'text', 'title', 'icon'],
    components: {
  },
    data: () => ({
      loading: false
    }),
    mounted: function() {
    },
    computed: {
    },
    methods: {
      confim: function() {
        this.data.dialog = false
        this.confimAction()
      }
    }
  }
</script>
<style scoped>
</style>