<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2063436339360861" crossorigin="anonymous">
</script>
<template>
  <v-card elevation="0" color="transparent">
    <v-card-title class="primary--text mr-0 pr-0 ml-0 pl-0">
      <v-badge overlap bordered right color="blue" :value="Object.keys(filterData.filterApply).map((key) => filterData.filterApply[key]).filter(v => Array.isArray(v) ? v.length :  v).length" :content="Object.keys(filterData.filterApply).map((key) => filterData.filterApply[key]).filter(v => Array.isArray(v) ? v.length :  v).length">
        <v-btn
          color="primary"
          outlined
          @click="filterData.dialog = true"
          class="rounded-lg text-none"
        >
          <v-icon>mdi-filter-variant</v-icon>
          <label>{{ $t('filter') }}</label>
          <v-icon v-if="['xs'].includes($vuetify.breakpoint.name)">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-right</v-icon>
        </v-btn>
      </v-badge>
      <v-spacer/>
      <v-badge overlap left bordered color="primary" :value="!screenData.auth.user.config" content="!">
        <v-btn color="primary" @click="dialogConfig = true" outlined class="rounded-lg text-none">
          <v-icon>mdi-cog</v-icon>
          <label>{{ $t('config') }}</label>
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-badge>
    </v-card-title>
    <v-card-actions class="pl-0 pr-0" v-if="!loading || onlineAddressPage.totalElements">
      <small><b>{{onlineAddressPage.totalElements}} {{ $t('results') }}</b></small>
      <v-spacer/>
      <v-badge overlap bordered left color="primary" :value="Object.keys(filterData.orderBy).map((key) => filterData.orderBy[key]).filter(v => Array.isArray(v) ? v.length :  v).length" :content="Object.keys(filterData.orderBy).map((key) => filterData.orderBy[key]).filter(v => Array.isArray(v) ? v.length :  v).length">
        <v-menu offset-y right :open-on-hover="false" :close-on-content-click="true" rounded="lg">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              outlined
              dark
              class="rounded-lg text-none"
               v-bind="attrs" v-on="on"
            >
              {{ $t('orderBy') }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list v-for="item in [
              {id: 'dateCreateOrderBy', propertie: 'dateCreate'},
              {id: 'clicksOrderBy', propertie: 'clicks'},
            ]"
            :key="item.id"
            class="mb-0 pb-0 mt-0 pt-0"
            >
            <v-list-item-group
              v-model="filterData.orderBy[item.propertie]"
              color="primary"
            >
              <v-list-item
                v-for="order in ['ASC', 'DESC']" :key="order"
                link
                cli
                :value="`${item.propertie}:${order}`"
              >
                <v-list-item-title>
                  {{ $t(`${item.id}${order}`) }}
                </v-list-item-title>
                <v-list-item-icon v-if="filterData.orderByVector.indexOf(`${item.propertie}:${order}`) > -1">
                  {{filterData.orderByVector.indexOf(`${item.propertie}:${order}`) + 1}}º
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
            <v-divider/>
          </v-list>
        </v-menu>
      </v-badge>
    </v-card-actions>
    <v-card-actions class="pr-4 pl-4" v-else>
      <v-skeleton-loader type="chip"/>
      <v-spacer/>
      <v-skeleton-loader type="chip" class="mr-1"/>
      <v-skeleton-loader type="chip"/>
    </v-card-actions>
    <div>
      <v-slide-x-transition>
        <v-row v-if="loading">
          <v-col cols="12"
            sm="12"
            md="12"
            lg="12" v-for="i in itemsPerPage" :key="`loader-data-table-${i}`">
            <v-skeleton-loader
              type="article, actions"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" v-if="loadingItem">
            <v-card class="card-corner-radius" light elevation="0" height="200" :loading="true">
              <v-skeleton-loader
                class="mt-4"
              type="list-item-avatar, article"
            />
            </v-card>
          </v-col>
          <v-col
            v-for="item in onlineAddressPage.content"
            :key="item.id"
            :cols="12"
            sm="12"
            md="12"
            lg="12"
          >
            <v-card v-if="item.ads" elevation="0" class="card-corner-radius" style="margin-left: 1px; margin-right: 1px;" outlined>
              <a class="link" target="_blank" href="https://s.shopee.com.br/7AKzPXhnIA">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-img src="https://cf.shopee.sg/file/br-11134216-7r98o-lwz0ast7axyudf"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="primary--text">
                      {{ $t('yourCompanyOrProductHere') }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t('yourCompanyOrProductHereText') }}
                    </v-list-item-subtitle>
                    <v-card-actions>
                      <v-spacer/>
                      <a href="">
                        {{ $t('callToAction') }}
                      </a>
                    </v-card-actions>
                  </v-list-item-content>
                </v-list-item>
              </a>
            </v-card>
            <v-card v-else :color="onlineAddress == item || (onlineAddress && onlineAddress.id == item.id) ? 'primary' : ''" class="card-corner-radius" style="padding: 1px; margin: 1px;" elevation="1">
              <v-card class="card-corner-radius" light elevation="0">
                <v-list-item three-line >
                  <v-list-item-content>
                    <v-list-item three-line class="pa-0" @click="select(item), screenData.dashboard = true">
                      <v-list-item-avatar
                        :tile="!item.biography"
                        :size="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 50 : 80"
                      >
                        <template v-if="item.biography">
                          <v-img lazy-src="/images/logo/logo-02.png" :src="item.biography.picture && item.biography.picture.src ? item.biography.picture.src : null"/>
                        </template>
                        <template v-else-if="item.openGraph && item.openGraph.openGraphFields && item.openGraph.openGraphFields.filter(f => f.name == ('og:image'))[0]">
                          <v-img lazy-src="/images/logo/logo-02.png" v-for="(field) in item.openGraph.openGraphFields.filter(f => f.name == ('og:image'))" :key="field.id" :src="field.value" position="center center" contain style="border-radius: 10px 0px 0px 10px;" aspect-ratio="1"/>
                        </template>
                        <template v-else-if="item.infoproduct">
                          <v-img lazy-src="/images/logo/logo-02.png" :src="item.infoproduct.brandImage ? item.infoproduct.brandImage.src : null" position="center center" contain style="border-radius: 10px 0px 0px 10px;" aspect-ratio="1"/>
                        </template>
                        <v-img v-else src="/images/logo/logo-02.png" contain/>
                      </v-list-item-avatar>
                      <v-list-item-content
                        class="pointer mb-0 pb-0 mt-0 pt-0">
                        <div class="text-overline mb-0 pb-0">
                          <template v-if="item.biography">
                            {{ $t('bio') }} <v-icon x-small>mdi-circle-medium</v-icon> {{ item.code }}
                          </template>
                          <template v-else-if="item.openGraph && item.openGraph.openGraphFields && item.openGraph.openGraphFields.filter(f => f.name == ('og:site_name') && f.value.trim())[0]">
                            <div v-for="(field) in item.openGraph.openGraphFields.filter(f => f.name == ('og:site_name'))" :key="field.id">{{field.value}}</div>
                          </template>
                          <template v-else-if="item.infoproduct">
                            {{ $t('infoproduct') }}  <v-icon x-small>mdi-circle-medium</v-icon> {{ item.infoproduct.title }}
                          </template>
                          <div v-else-if="item.url">
                            {{item.url.replace('http://', '').replace('https://', '').substring(0, item.url.replace('http://', '').replace('https://', '').indexOf('/') > 0 ? item.url.replace('http://', '').replace('https://', '').indexOf('/') : item.url.replace('http://', '').replace('https://', '').length)}}
                          </div>
                        </div>
                        <v-list-item-subtitle class="mb-0 pb-0" style="white-space: inherit;" v-if="item.infoproduct || item.biography || item.openGraph && item.openGraph.openGraphFields">
                          <template v-if="item.biography">
                              <b>{{ item.biography.name }}</b>
                          </template>
                          <div v-else-if="item.openGraph && item.openGraph.openGraphFields && item.openGraph.openGraphFields.filter(f => f.name == ('og:title') && f.value.trim())[0]">
                            <b><p v-for="(field) in item.openGraph.openGraphFields.filter(f => f.name == ('og:title'))" :key="field.id">{{field.value}}</p></b>
                          </div>
                          <div v-else-if="item.infoproduct">
                            <b><p>{{ item.infoproduct.subtitle }}</p></b>
                          </div>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <template v-if="item.biography">
                            {{ item.biography.description }}
                          </template>
                          <div v-else-if="item.openGraph && item.openGraph.openGraphFields && item.openGraph.openGraphFields.filter(f => f.name == ('og:description' && f.value.trim()))[0]">
                            <p v-for="(field) in item.openGraph.openGraphFields.filter(f => f.name == ('og:description'))" :key="field.id">{{field.value}}</p>
                          </div>
                          <div v-else-if="item.infoproduct">
                            <p>{{item.infoproduct.shortDescription}}</p>
                          </div>
                          <div v-else>
                            {{ item.url }}
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-card-text class="pa-0">
                      <v-chip color="transparent" class="primary--text pl-0">
                        <input :id="`link-${item.id}`"  :value="`${host}/${item.code}`" class="primary--text" readonly style="min-width: 200px;"/>
                        <v-icon color="primary" class="ml-1" @click="copyLink(item)">
                          mdi-content-copy
                        </v-icon>
                        <v-slide-x-transition>
                          <v-icon color="green" class="ml-1" v-if="item.copy">
                            mdi-check-circle
                          </v-icon>
                        </v-slide-x-transition>
                      </v-chip>
                      <div style="max-height: 80px; overflow-x: auto;" v-if="item.tags">
                        <v-chip-group class="mt-0 pt-0 mb-0 pb-0" column  content-class="mt-0 pt-0">
                          <template v-if="item.tags"> 
                            <v-chip class="mr-1 mb-1" v-for="(tag, index) in item.tags.split(`||`)" :key="index" v-show="tag" small>
                              <v-icon x-small class="mr-1">mdi-tag</v-icon>
                              {{tag}}
                            </v-chip>
                          </template>
                        </v-chip-group>
                      </div>
                    </v-card-text>
                  </v-list-item-content>
                  <v-divider vertical class="mt-4 mb-4"/>
                  <v-list-item-action class="pl-0 ml-1" style="margin: auto;">
                    <a :href="`${host}/${item.code}`" target="_blank" style="text-decoration: none;" v-if="false">
                      <v-btn color="primary" small icon>
                        <v-icon>mdi-navigation-variant</v-icon>
                      </v-btn>
                    </a>
                    <v-badge overlap right bordered :color="item.error ? 'warning' : !item.openGraph || !item.openGraph.openGraphFields ? 'blue': ''" :content="item.error ? '!' : !item.openGraph || !item.openGraph.openGraphFields ? '!': false" :value="item.error || !item.openGraph || !item.openGraph.openGraphFields ? '!' : false">
                      <v-menu offset-y right :open-on-hover="false" :close-on-content-click="true">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" v-bind="attrs" v-on="on" small icon>
                            <v-icon>mdi-cog</v-icon>
                          </v-btn>
                        </template>
                        <v-card width="300">
                          <v-card-subtitle class="mb-0 pb-0">
                            <v-badge overlap left color="blue" :content="!item.openGraph || !item.openGraph.openGraphFields ? '!': false" :value="!item.openGraph || !item.openGraph.openGraphFields ? '!' : false">
                              <v-icon small color="primary" class="mb-1">mdi-octagram-edit-outline</v-icon>
                            </v-badge>
                            <a @click="openGraphEditData.dialog = true,openGraphEditData.address = item">
                              {{ $t('editAparenceLink') }}
                            </a>
                            <v-spacer/>
                          </v-card-subtitle>
                          <v-card-subtitle class="mb-0 pb-0" v-if="item.type != 'INFOPRODUCT'">
                            <v-icon small color="primary" class="mb-1">mdi-pencil</v-icon>
                            <a v-if="item.type == 'BIO'" @click="$router.push('/painel/links/page-bio')">
                              {{ $t('editLink') }}
                            </a>
                            <a v-else @click="LinkEditData.dialog = true, LinkEditData.address = item">
                              {{ $t('editCodeAndDirectionLink') }}
                            </a>
                            <v-spacer/>
                          </v-card-subtitle>
                          <v-card-subtitle v-if="item.type != 'INFOPRODUCT'">
                            <v-icon small color="primary" class="mb-1">mdi-tag-multiple</v-icon>
                            <a @click="LinkEditTagsData.dialog = true, LinkEditTagsData.address = item">
                              {{ $t('editLinkTags') }}
                            </a>
                            <v-spacer/>
                          </v-card-subtitle>
                          <v-divider v-if="item.error"/>
                          <v-card-text v-if="item.error" @click="LinkEditData.dialog = true, LinkEditData.address = item" class="pointer warning--text">
                            <v-icon color="warning" small class="mr-1">mdi-alert-circle-outline</v-icon>
                            {{ $t('linkErrorTitle') }}
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </v-badge>
                    <ShareOptions :onlineAddress="item"/>
                    <v-btn color="primary" small icon @click="open(item)">
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card-actions class="pl-0 pr-0">
              <v-chip-group>
                <v-btn x-small class="mr-1 primary--text" :disabled="(p -1) == onlineAddressPage.number" color="white" rounded light v-for="p in onlineAddressPage.totalPages" :key="p" @click="page = p -1, $router.replace({query: Object.assign({ ...$route.query }, { page: p -1 }),
                    },
                    () => {}
                  ),search(page)">
                  <v-icon x-small v-if="(p -1) == onlineAddressPage.number">mdi-circle-medium</v-icon>
                  {{$t('page')}} {{p}}
                </v-btn>
              </v-chip-group>
              <v-spacer/>
              <v-btn icon color="primary" :disabled="onlineAddressPage.first" @click="search(onlineAddressPage.number -1)">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn icon color="primary" :disabled="onlineAddressPage.last" @click="search(onlineAddressPage.number +1)">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="12" v-if="onlineAddressPage.empty">
            <v-card elevation="0" color="transparent" class="pa-4">
              <v-img src="/images/woman-home.png" />
            </v-card>
          </v-col>
        </v-row>
      </v-slide-x-transition>
    </div>
    <v-dialog v-model="dialogQrcode" width="300">
      <QRCodeMigly v-if="selectQrcodeItem" :value="`${host}/${selectQrcodeItem.code}`" :enableControlls="true"/>
    </v-dialog>
    <v-dialog v-model="planData.dialogPlansAndPrices" width="800">
      <v-card>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="planData.dialogPlansAndPrices = false" icon><v-icon color="red">mdi-close</v-icon></v-btn>
        </v-card-actions>
        <v-card-text>
          <SubscriptionAndPlans :screenData="screenData"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-if="['xs' , 'sm'].includes($vuetify.breakpoint.name)" v-model="openGraphEditData.dialog">
      <OpenGraphEdit :data="openGraphEditData" :screenData="screenData"/>
    </v-bottom-sheet>
    <v-navigation-drawer 
      v-else
      v-model="openGraphEditData.dialog"
      fixed
      width="60%"
      height="100%"
      class="grey lighten-5"
      >
      <OpenGraphEdit :data="openGraphEditData" :screenData="screenData"/>
    </v-navigation-drawer>
    <v-overlay :value="openGraphEditData.dialog"></v-overlay>

    <LinkEdit :data="LinkEditData" :screenData="screenData"/>
    <LinkEditTags :data="LinkEditTagsData" :screenData="screenData"/>

    <v-bottom-sheet v-if="['xs' , 'sm'].includes($vuetify.breakpoint.name)" v-model="dialogConfig">
      <v-sheet class="overflow">
        <v-card-actions>
          <v-btn icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer/>
          <b>{{ $t('config') }}</b>
          <v-spacer/>
          <v-btn icon color="#7E8EA6" @click="dialogConfig = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider class="ma-4 mt-0"/>
        <Config :screenData="screenData"/>
      </v-sheet>
    </v-bottom-sheet>
    <v-navigation-drawer 
      v-else
      v-model="dialogConfig"
      fixed
      width="50%"
      height="100%"
      class="grey lighten-5"
      >
      <v-sheet>
        <v-card-actions>
          <v-btn icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer/>
          <b>{{ $t('config') }}</b>
          <v-spacer/>
          <v-btn icon color="#7E8EA6" @click="dialogConfig = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider class="ma-4 mt-0"/>
        <Config :screenData="screenData"/>
      </v-sheet>
    </v-navigation-drawer>
    <v-overlay :value="dialogConfig"></v-overlay>
    <v-bottom-sheet v-model="filterData.dialog" v-if="['xs' , 'sm'].includes($vuetify.breakpoint.name)">
      <LinksFilter :data="filterData" :search="search"/>
    </v-bottom-sheet>
    <v-navigation-drawer 
      v-else
      v-model="filterData.dialog"
      fixed
      width="450"
      height="100%"
      class="grey lighten-5"
      >
      <LinksFilter :data="filterData" :search="search"/>
    </v-navigation-drawer>
    <v-overlay :value="filterData.dialog"></v-overlay>
  </v-card>
</template>
<script>
    (adsbygoogle = window.adsbygoogle || []).push({});
</script>
<script>
import {apiV1OnlineAddresses} from "../../../service/resources/api-v1-online-addresses";
import {apiV1OpenGraph} from "../../../service/resources/api-v1-open-graph";
import BiographySmartphoneView from "./BiographySmartphoneView.vue";
import QRCodeMigly from "./QRCodeMigly.vue";
import IconInfoSubscriblePlan from './IconInfoSubscriblePlan.vue';
import SubscriptionAndPlans from './SubscriptionAndPlans.vue';
import ShareOptions from './ShareOptions.vue'
import OpenGraphEdit from './OpenGraphEdit.vue'
import LinkEdit from './LinkEdit.vue';
import LinkEditTags from './LinkEditTags.vue';
import Config from './Config.vue';
import LinksFilter from './LinksFilter.vue'
  export default {
    name: 'UrlShortener',
    props: ['screenData'],
    components: {
      BiographySmartphoneView,
      QRCodeMigly,
      IconInfoSubscriblePlan,
      SubscriptionAndPlans,
      ShareOptions,
      apiV1OpenGraph,
      OpenGraphEdit,
      LinkEdit,
      LinkEditTags,
      Config,
      LinksFilter
    },
    data: () => ({
      filterData: {
        dialog: false,
        filter: {
          domain: null,
          errorIn: [],
          typeIn: [],
          dateCreateIni: null,
          dateCreateEnd: null,
          withClicks: null,
          withoutClicks: null,
          clickDateRange: []
        },
        orderBy: {
          "dateCreate": "dateCreate:DESC"
        },
        filterApply: {},
        orderByVector: [
          "dateCreate:DESC"
        ],
      },
      dialogConfig: false,
      onlineAddress: null,
      onlineAddressPage: {
        content: [],
      },

      openGraphEditData: {dialog: false, address: null},
      LinkEditData: {dialog: false, address: null},
      LinkEditTagsData: {dialog: false, address: null},
      planData: {
        dialogPlansAndPrices: false
      },
      requestNumber: 0,
      dialogQrcode: false,
      selectQrcodeItem: null,
      dialogDelete: false,
      dialogFilter: false,
      dialogEdit: false,
      host: null,
      loading: false,
      loadingSave: false,
      loadingDelete: false,
      itemsPerPageArray: [10, 50, 100],
      text: '',

      sortDesc: false,
      itemsPerPage: 10,
      page: 0,
      error: null,
      domain: null,
      loadingItem: false,
    }),
    mounted: function() {
      this.setFilter()
      this.host = `${window.location.protocol}//${window.location.host.replace('www.', '')}`;
      this.filterData.filterApply = JSON.parse(JSON.stringify(this.filterData.filter))
      this.search(this.page)
    },
    computed: {
    },
    watch: {
      '$route.query.shortenedRecent': function(id) {
        if(id)
          this.focusAddress(id)
      },
      '$route.query.errorIn': function(values) {
        const sortedArr1 = Array.isArray(this.filterData.filter.errorIn) ? this.filterData.filter.errorIn.slice().sort() : [];
        const sortedArr2 = Array.isArray(values) ? values.slice().sort() : [values]

        if(sortedArr2.length != sortedArr1.length) {
          this.updateQueryFilter()
          return
        }
        for (let i = 0; i < sortedArr1.length; i++) {
          if (sortedArr1[i] !== sortedArr2[i]) {
            this.updateQueryFilter()
            return
          }
        }

      },
      '$route.query.text': function(text) {
        if(this.filterData.filter.text != text)
          this.updateQueryFilter()
      },
      'dialogEdit': function() {
        this.onlineAddress = structuredClone(this.onlineAddress);
      },
      'filterData.orderBy.clicks' : function(value) {
        if(value)
          this.addOrderBy(value, true)
        else
          this.addOrderBy('clicks:ASC', false)
      },
      'filterData.orderBy.dateCreate' : function(value) {
        if(value)
          this.addOrderBy(value, true)
        else
          this.addOrderBy('dateCreate:ASC', false)
      }
    },
    methods: {
      addOrderBy: function(value, add) {
        this.filterData.orderByVector = this.filterData.orderByVector.filter(v => v.split(':')[0] != value.split(':')[0])
        if(add)
          this.filterData.orderByVector.push(value)
        this.search(this.page)
      },
      updateQueryFilter: function() {
        this.setFilter()
        this.search(this.page)
      },
      setFilter: function() {
        if(this.$route.query.page)
          this.page = this.$route.query.page
        if(this.$route.query.text)
          this.filterData.filter.text = this.$route.query.text
        if(this.$route.query.domain)
          this.filterData.filter.domain = this.$route.query.domain
        if(this.$route.query.errorIn)
          this.filterData.filter.errorIn = Array.isArray(this.$route.query.errorIn) ? this.$route.query.errorIn: [this.$route.query.errorIn]
        if(this.$route.query.typeIn)
          this.filterData.filter.typeIn = Array.isArray(this.$route.query.typeIn) ? this.$route.query.typeIn : [this.$route.query.typeIn]
        if(this.$route.query.dateCreateIni)
          this.filterData.filter.dateCreateIni = this.$route.query.dateCreateIni
        if(this.$route.query.dateCreateEnd)
          this.filterData.filter.dateCreateEnd = this.$route.query.dateCreateEnd
        if(this.$route.query.withClicks)
          this.filterData.filter.withClicks = this.$route.query.withClicks === "true"
        if(this.$route.query.withoutClicks)
          this.filterData.filter.withoutClicks = this.$route.query.withoutClicks === "true"
        if(this.$route.query.clickDateRange)
          this.filterData.filter.clickDateRange = Array.isArray(this.$route.query.clickDateRange) ?  this.filterData.filter.clickDateRange : []
        if(this.$route.query.onlineAddressId)
          this.filterData.filter.onlineAddressId = this.$route.query.onlineAddressId
        if(this.$route.query.orderBy) {
          this.filterData.orderBy = JSON.parse(this.$route.query.orderBy)
          if(filterData.orderBy.dateCreate && !filterData.orderByVector.includes(filterData.orderBy.dateCreate)){
            filterData.orderByVector.push(filterData.orderBy.dateCreate)
          }
          if(filterData.orderBy.clicks && !filterData.orderByVector.includes(filterData.orderBy.clicks)){
            filterData.orderByVector.push(filterData.orderBy.clicks)
          }
        }
      },
      isUrl: function(value) {
        const pattern = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi
        // const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim
        return pattern.test(value)
      },
      open: function(item){
        const routeData = this.$router.resolve({ path: `/${item.code}`, query:  {countClick: false}});
        window.open(routeData.href, '_blank');
      },
      select: function(item) {
        if(this.onlineAddress != item) {
          this.onlineAddress = item
        } else {
          this.onlineAddress = null
        }
        var query = JSON.parse(JSON.stringify(this.$route.query))

        if(query.onlineAddressId == item.id) {
          query.onlineAddressId = null
        }else {          
          query.onlineAddressId = item.id
        }
        this.$router.replace({query:query})
      },
      copyLink: function (item) {
        let element = document.querySelector('#link-'+ item.id);
        element.setAttribute('type', 'text') 
        element.select()
        document.execCommand('copy');
        console.log(element)
        item.copy = true
        this.$forceUpdate()
        setTimeout(() => {
          item.copy = false
          this.$forceUpdate()
        }, 3000);
      },
      search: function(page) {
        this.requestNumber = `${crypto.randomUUID()}`
        let request = `${this.requestNumber}`
        this.loading = true
        apiV1OnlineAddresses.page({
          page: page, 
          size: this.itemsPerPage, 
          text: this.filterData.filter.text,
          errorIn:this.filterData.filter.errorIn,  
          typeIn: this.filterData.filter.typeIn, 
          dateCreateIni: this.filterData.filter.dateCreateIni? `${new Date(`${this.filterData.filter.dateCreateIni}T00:00:00`).toISOString()}`  : null, 
          dateCreateEnd: this.filterData.filter.dateCreateEnd ? `${new Date(`${this.filterData.filter.dateCreateEnd}T23:59:59`).toISOString()}`  : null, 
          embedBiography: true, 
          orderBy:  this.filterData.orderByVector,
          error: this.filterData.filtererror, 
          domain: this.filterData.filter.domain,
          withClicks: this.filterData.filter.withClicks,
          withoutClicks: this.filterData.filter.withoutClicks, 
          clickDateRange: this.filterData.filter.clickDateRange.map((v, index) => index ? `${new Date(`${v}T23:59:59`).toISOString()}` : `${new Date(`${v}T00:00:00`).toISOString()}`),
        }).then(response => {
          if(request == this.requestNumber) {
            this.onlineAddressPage = response.data
            this.onlineAddressPage.content.forEach(i => {
              i.showUrl = false;
            })
            if(this.$route.query.shortenedRecent)
              this.focusAddress(this.$route.query.shortenedRecent)
            if(this.screenData.auth && !this.screenData.auth.user.userHasSubscriptionPlans.filter(v => !v.subscriptionPlan.free)[0])
              this.onlineAddressPage.content.splice(Math.floor(Math.random() * (this.onlineAddressPage.content.length - 0 + 1) + 0), 0, {ads: true, id: 0})
          }
          this.loading = false
        })
      },
      focusAddress: function(id) {
        this.loadingItem = true
        apiV1OnlineAddresses.get(id).then(response => {
          this.onlineAddressPage.content = this.onlineAddressPage.content.filter(v => v.id != response.data.id)
          this.onlineAddressPage.content.splice(0, 0, response.data)
          this.loadingItem = false
          this.onlineAddress = response.data

          var query = JSON.parse(JSON.stringify(this.$route.query))
          query.shortenedRecent = null
          this.$router.replace({query:query})
        })
      },
    },
  }
</script>
<style scoped>
.height-100{
  height: 100%;
}

.item-verticle-center {
  min-height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
}
.item-horizontaly-center {
  margin: auto;
}

input:focus {
    outline: none; /* Remove a borda padrão do navegador */
    border: none;  /* Remove a borda definida */
}
</style>