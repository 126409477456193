import http from "../axios";

let apiV1SmsShootingHasContacts = {
	baseUrl: '/api/v1/sms-shootings-has-contacts',
	get: async (id) => {
		return await http.get(`${apiV1SmsShootingHasContacts.baseUrl}/${id}`);
	},
	page: async (filter) => {
		return await http.get(`${apiV1SmsShootingHasContacts.baseUrl}
		?page=${filter.page}
		&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.smsShootingId ? `&smsShootingId=${filter.smsShootingId}` : ''}
		${filter.contactNumber ? `&contactNumber=${filter.contactNumber}` : ''}
		${filter.embedContactProperties ? `&embedContactProperties=${filter.embedContactProperties}` : ''}
		${filter.contactNumberStartsWith ? `&contactNumberStartsWith=${filter.contactNumberStartsWith}` : ''}
		
		`);
	}
}

export  { apiV1SmsShootingHasContacts }