import http from "../axios";

let apiV1SubscriptionPlan = {
	baseUrl: '/api/v1/subscriptions-plans',
	get: async (id) => {
		return await http.get(`${apiV1SubscriptionPlan.baseUrl}/${id}`);
	},
	page: async (filter) => {
		return await http.get(`${apiV1SubscriptionPlan.baseUrl}?page=${filter.page}&size=${filter.size}${filter.active ? `&active=${filter.active}` : ''}${filter.embedSubscriptionPlanInclude ? `&embedSubscriptionPlanInclude=${filter.embedSubscriptionPlanInclude}` : ''}`);
	}
}

export  { apiV1SubscriptionPlan }