import http from "../axios";

let apiV1Infoproduct = {
	baseUrl: '/api/v1/infoproduts',
	save: async (dto) => {
		return await http.post(`${apiV1Infoproduct.baseUrl}`, dto);
	},
	delete: async (id) => {
		return await http.delete(`${apiV1Infoproduct.baseUrl}/${id}`);
	},
	page: async (filter) => {
		return await http.get(`${apiV1Infoproduct.baseUrl}
		?page=${filter.page}
		&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.embedItens ? `&embedItens=${filter.embedItens}` : ''}
		${filter.embedfooterLinks ? `&embedfooterLinks=${filter.embedfooterLinks}` : ''}

		${filter.embedInfoproductFormFields ? `&embedInfoproductFormFields=${filter.embedInfoproductFormFields}` : ''}
		${filter.embedInfoproductFormFieldsOptions ? `&embedInfoproductFormFieldsOptions=${filter.embedInfoproductFormFieldsOptions}` : ''}
		`);
	},
}

export  { apiV1Infoproduct }