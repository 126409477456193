import http from "../axios";

let apiV1ContactAccessAddress = {
	baseUrl: '/api/v1/contacts-access-address',
	page: async (filter) => {
		return await http.get(`${apiV1ContactAccessAddress.baseUrl}
		?page=${filter.page}
		&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.smsShootingId ? `&smsShootingId=${filter.smsShootingId}` : ''}
		`);
	},
	save: async (dto) => {
		return await http.post(`${apiV1ContactAccessAddress.baseUrl}`, dto);
	}
}

export  { apiV1ContactAccessAddress }