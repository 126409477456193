<template>
  <v-tabs v-if="block" full-width fixed-tabs v-model="tab" style="border-top: 1px solid #E7EBF2" @change="$router.push(tab ? '/painel/links/page-bio' : '/painel/links/shortened')">
    <v-tabs-slider></v-tabs-slider>
    <v-tab :value="0" style="text-transform:capitalize" >
      <div>{{ $t('links') }}</div>
    </v-tab>
    <v-tab :value="1" style="text-transform:capitalize">
      <div >{{ $t('pageBio') }}</div>
    </v-tab>
  </v-tabs>
  <v-btn-toggle
    v-else
    :value="$route.path"
    :rounded="!block"
    color="blue"
    background-color="#7E8EA6"
    readonly
    :flat="!block"
    borderless
    :dense="!block"
    block
  >
    <v-btn :small="!block" value="'/painel/links/shortened" @click="$router.push(`/painel/links/shortened`)" :color="['shortened'].includes($route.path)  ? 'primary': ''" light>
      <div>{{ $t('shortenAddress') }}</div>
    </v-btn>
    <v-btn :small="!block" value="'/painel/links/page-bio'" @click="$router.push(`/painel/links/page-bio`)"  :color="['page-bio'].includes($route.path)  ? 'primary': ''" light>
      <div >{{ $t('pageBio') }}</div>
    </v-btn>
  </v-btn-toggle>
</template>
<script>

  export default {
    name: 'TabOptions',
    props: ['block'],
    components: {
    },
    data: () => ({
      tab: 0,
    }),
    watch: {
      '$route': function() {
        this.verifytab()
      },
    },
    mounted: function() {
      this.verifytab()
    },
    computed: {
    },
    methods: {
      verifytab: function() {
        this.tab = this.$route.path.includes('page-bio')  ? 1 : 0
      },
    },
  }
</script>
<style scoped>
</style>