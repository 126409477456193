<template>
  <v-main>
    <v-card v-if="loading" class="mt-4">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="7">
          <v-card elevation="0" class="ma-4">
            <v-skeleton-loader type="card-avatar, article, list-item-three-line, card-avatar, actions"/>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="5">
          <v-card elevation="0" class="ma-4" height="90%" width="90%">
            <v-progress-circular indeterminate  class="verticle-center horizontaly-center" color="primary"/>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-row no-gutters v-else>
      <v-col cols="12" xs="12" sm="12" md="7" :class="!['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'full-height' : ''">
        <div >
          <section>
            <v-row no-gutters>
              <v-col cols="12">
                <v-card-title class="primary--text">
                  <b>{{$t('pageBio')}}</b>
                  <v-spacer/>
                  <v-badge overlap right bordered color="blue" :value="!screenData.auth.user.config" content="!">
                    <v-btn color="primary" @click="dialogConfig = true" outlined class="rounded-lg text-none">
                      <v-icon>mdi-cog</v-icon>
                      <label>{{ $t('config') }}</label>
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </v-badge>
                </v-card-title>
                <!-- <v-card-subtitle>{{$t('createBio')}}</v-card-subtitle> -->
              </v-col>
              <v-col cols="12">
                <v-card class="card-corner-radius mt-0" elevation="0">
                  <v-card-title v-if="loading">
                    <v-skeleton-loader type="chip"/>
                  </v-card-title>
                  <v-card-text v-else-if="bio.id">
                    <v-row>
                      <v-col cols="12" sm="8">
                        <v-btn color="primary" class="primary--text pa-2" outlined rounded block @click="copyLink(bio.onlineAddress)">
                          <input :id="`link-${bio.onlineAddress.id}`"  :value="`${host}/${bio.onlineAddress.code}`" class="primary--text" readonly  style="width: 100%;border: 0px;focu"/>
                          <v-icon color="primary" class="ml-1" >
                            mdi-content-copy
                          </v-icon>
                          <v-slide-x-transition>
                            <v-icon color="green" class="ml-1" v-if="bio.onlineAddress.copy">
                              mdi-check-circle
                            </v-icon>
                          </v-slide-x-transition>
                        </v-btn>
                      </v-col>
                      <v-spacer/>
                      <v-col cols="12" sm="4">
                        <ShareOptions :onlineAddress="bio.onlineAddress" :title="$t('share')"/>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </section>
          <section>
            <v-card elevation="0" class="rounded-xl mt-4">
              <v-tabs v-model="tab" slider-color="transparent" centered @change="validateForms, rolarParaDiv('container-mobile-preview', `section-${tab}`)">
                <v-tabs-slider />
                <v-tab v-for="(item) in tabs" :key="item.title" class="text-none">
                  {{ $t(item.title) }}
                </v-tab>
              </v-tabs>
            </v-card>
          </section>
          <section>
            <v-card class="card-corner-radius mt-4 overflow-auto" elevation="0" id="form">
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="(item, index) in tabs" :key="index">
                  <v-form v-model="item.form" :ref="`form_tab_${index}`">
                    <div v-if="tab == 0">
                      <v-card-title><b>{{ $t('profile') }}</b></v-card-title>
                      <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('profileDesc') }}</v-card-subtitle>
                      <v-card-text>
                        <v-card class="card-corner-radius" :color="bio.headerColor">
                          <v-img :src="bio.cover && !bio.cover.deleted ? bio.cover.src : null" height="250" aspect-ratio position="top">
                            <v-app-bar flat color="rgba(0, 0, 0, 0)">
                              <div class="ml-2 mr-2">
                                <ColorMenu :data="bio" :attribute="`headerColor`" :title="$t('headerColor')" :fab="true"/>
                              </div>
                              <v-btn color="primary" rounded class="pl-5 ml-2" dark @click="$refs[`image_cover_${0}`][0].$refs.input.click()">
                                <v-icon v-if="!bio.cover || bio.cover.deleted">mdi-image-plus</v-icon>
                                <v-icon v-else>mdi-image-edit</v-icon>
                                <v-file-input
                                  :ref="`image_cover_${0}`"
                                  v-show="false"
                                  @change="handleCover"
                                  color="white"
                                  hide-input
                                  hide-details
                                  hide-actions
                                  prepend-icon="mdi-image"
                                  icon
                                  
                                  accept="image/png, image/jpeg, image/bmp"
                                  />
                              </v-btn>
                              <v-spacer/>
                              <v-btn color="red" rounded class="ml-2 mr-2" @click="bio.cover ? bio.cover.id ? bio.cover.deleted = true : bio.cover = null : bio.cover = null,rolarParaDiv('container-mobile-preview', `section-cover`)" :disabled="!bio.cover || bio.cover.deleted">
                                <v-icon color="white">mdi-delete</v-icon>
                              </v-btn>
                            </v-app-bar>
                            <v-card-actions class="white--text mt-8">
                              <v-spacer/>
                              <v-btn color="primary" rounded dark class="mr-2 pl-5" @click="$refs[`image_profile_${0}`][0].$refs.input.click()">
                                <v-icon v-if="!bio.picture || !bio.picture.src || bio.picture.deleted">mdi-image-plus</v-icon>
                                <v-icon v-else>mdi-image-edit</v-icon>
                                <v-file-input
                                  :ref="`image_profile_${0}`"
                                  v-show="false"
                                  class="mt-0 pt-0 mr-0 pr-0"
                                  @change="handlePicture"
                                  hide-input
                                  prepend-icon="mdi-camera"
                                  hide-details
                                  hide-actions
                                  hide-controls
                                  accept="image/png, image/jpeg, image/bmp"
                                />
                              </v-btn>
                              <v-card class="rounded-card" @click="$refs[`image_profile_${0}`][0].$refs.input.click()">
                                <v-card-text class="pt-1 pl-1 pr-1 pb-1">
                                  <v-avatar size="100" >
                                    <v-img :src="bio.picture.src" v-if="bio.picture && !bio.picture.deleted"/>
                                    <v-icon v-else>mdi-camera</v-icon>
                                  </v-avatar>
                                </v-card-text>
                              </v-card>
                              <v-btn color="red" rounded class="ml-2" @click="bio.picture ? bio.picture.id ? bio.picture.deleted = true : bio.picture = null : bio.picture = null,rolarParaDiv('container-mobile-preview', `section-picture`)" :disabled="!bio.picture || bio.picture.deleted">
                                <v-icon color="white">mdi-delete</v-icon>
                              </v-btn>
                              <v-spacer/>
                            </v-card-actions>
                          </v-img>
  
                        </v-card>
                      </v-card-text>
                      <v-card-text>
                        <v-divider/>
                      </v-card-text>
                      <v-card-title><b>{{ $t('about') }}</b></v-card-title>
                      <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('aboutDesc') }}</v-card-subtitle>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" xs="12" sm="6">
                            <v-text-field
                              v-model="bio.onlineAddress.code"
                              required
                              @keyup="bio.onlineAddress.code ? verifyUserName() : null"
                              :rules="[
                                v => !!v || $t('required'),
                                v => !v.includes(' ') || $t('invalid')
                              ]"
                              :label="$t('username')"
                              placeholder="ex. exatamente.solucoes"
                              outlined
                              maxlength="15"
                              counter
                              filled
                              rounded
                              dense
                              hide-actions
                              hide-details>
                              <template v-slot:append>
                                <v-slide-x-transition hide-on-leave leave-absolute>
                                  <v-progress-circular indeterminate size="25" width="1" color="primary" v-if="loadingVerifyUserName"/>
                                  <v-icon v-else-if="canUseUserName" color="green">mdi-check</v-icon>
                                  <v-icon v-else-if="!canUseUserName" color="red">mdi-cancel</v-icon>
                                </v-slide-x-transition>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="bio.name"
                              required
                              :rules="[
                                v => !!v || $t('required')
                              ]"
                              :label="$t('name')"
                              placeholder="ex. Exatamente Solucoes"
                              outlined
                              maxlength="20"
                              counter
                              filled
                              rounded
                              @keyup="rolarParaDiv('container-mobile-preview', `section-name`)"
                              dense/>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              v-model="bio.description"
                              no-resize
                              :label="$t('description')"
                              :rules="[
                                v => !!v || $t('required')
                              ]"
                              outlined
                              maxlength="100"
                              counter
                              filled
                              rounded
                              @keyup="rolarParaDiv('container-mobile-preview', `section-description`)"
                              dense/>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </div>
                    <div v-else-if="tab == 1">
                      <v-card-title><b>{{ $t('sectionsAndlinks') }}</b></v-card-title>
                      <v-card-actions class="ml-2">
                        <v-btn class="rounded-lg text-none mr-1" color="primary" @click="dialogSelectSection = true">
                          {{ $t('newSection') }}
                        </v-btn>
                      </v-card-actions>
                      <v-card-subtitle v-if="bio.biographyHasOnlineAddresses[0]" class="description--text">{{ $t('draggableLinksInfo') }}</v-card-subtitle>
                      <draggable v-model="bio.biographyHasOnlineAddresses" @end="onEnd,bio.biographyHasOnlineAddresses.forEach((i, index) => i.order = index)"       
                        :animation="200"
                        ghost-class="ghost"
                        chosen-class="chosen"
                        drag-class="drag">
                        <transition-group type="transition" name="flip-list" tag="div">
                          <v-card-text :id="`section-edit-links-${index}`" v-for="(biographyHasOnlineAddress, index) in bio.biographyHasOnlineAddresses" :key="`section-links-${index}`" class="rounded-lg">
                            <v-slide-x-transition>
                              <v-card class="card-corner-radius card-link" v-if="!biographyHasOnlineAddress.deleted" elevation="0" :color="biographyHasOnlineAddress.onlineAddress.error ? 'warning' : '#D2DBEB'" @click="rolarParaDiv('container-mobile-preview', `section-link-${index}`)">
                                <v-card class="card-corner-radius" elevation="0">
                                  <v-card-title>
                                    <b>{{index + 1}}º {{biographyHasOnlineAddress.type ?  $t(biographyHasOnlineAddress.type.toLowerCase()) : $t('sample_link')}}</b>
                                    <v-spacer/>
                                    <v-menu offset-y centered v-if="biographyHasOnlineAddress.onlineAddress.error" >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          color="warning"
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                          icon
                                          class="mt-2"
                                        >
                                          <v-icon>mdi-alert-circle</v-icon>
                                        </v-btn>
                                      </template>
                                      <v-card color="warning" style="padding: 1px;" max-width="500">
                                        <v-card elevation="0">
                                          <v-card-title>
                                            <b>{{ $t('linkErrorTitle') }}</b>
                                          </v-card-title>
                                          <v-card-subtitle>
                                            {{`${$t('thisLinkError')} ${new Date(biographyHasOnlineAddress.onlineAddress.lastCheck.replace('[UTC]' , ''))}`}}.
                                          </v-card-subtitle>
                                          <v-card-subtitle>{{ $t('possibleCauses') }}</v-card-subtitle>
                                          <v-card-text>
                                            <p v-for="item in $t('linkErrorCauses')" :key="item">
                                              - {{ item }}
                                            </p>
                                          </v-card-text>
                                        </v-card>
                                      </v-card>
                                    </v-menu>
                                    <v-btn icon color="red" class="mt-2" @click="biographyHasOnlineAddress.deleted = true, biographyHasOnlineAddress.onlineAddress.deleted = true">
                                      <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                  </v-card-title>
                                  <template v-if="!biographyHasOnlineAddress.type || biographyHasOnlineAddress.type == 'SAMPLE_LINK'">
                                    <v-card-text>
                                      <v-row>
                                        <v-col cols="12" xs="12" sm="12">
                                          <v-text-field
                                            :disabled="biographyHasOnlineAddress.onlineAddress.id && !screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_BASIC', 'URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]"
                                            v-model="biographyHasOnlineAddress.onlineAddress.url"
                                            outlined
                                            rounded
                                            dense
                                            flat
                                            :rules="[rules.url]"
                                            prepend-inner-icon="mdi-link"
                                            hide-details
                                            placeholder="https://"
                                            label="Link"
                                            class="lowercase"/>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12">
                                          <v-row>
                                            <v-col cols="5" xs="5" sm="4" md="3">
                                              <v-autocomplete dense v-model="biographyHasOnlineAddress.icon" :items="mdiIcons" flat rounded outlined :label="$t('icon')" hide-actions hide-details>
                                                <template v-slot:selection="{item}">
                                                  <v-icon v-if="item">{{ item }}</v-icon>
                                                  <label v-else>{{ $t('withoutIcon') }}</label>
                                                </template>
                                                <template v-slot:item="{item}">
                                                  <v-icon v-if="item">{{ item }}</v-icon>
                                                  <label v-else>{{ $t('withoutIcon') }}</label>
                                                </template>
                                              </v-autocomplete>
                                            </v-col>
                                            <v-col cols="7" xs="7" sm="8" md="9">
                                              <v-text-field
                                                v-model="biographyHasOnlineAddress.title"
                                                outlined
                                                hide-details
                                                hide-actions
                                                dense
                                                rounded
                                                :label="$t('title')"
                                                maxlength="50">
                                                <template v-slot:prepend>
              
                                                </template>
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                              <v-textarea
                                                v-model="biographyHasOnlineAddress.description"
                                                outlined
                                                hide-details
                                                hide-actions
                                                dense
                                                rounded
                                                maxlength="100"
                                                :label="$t('description')"
                                                no-resize
                                                rows="2">
                                                <template v-slot:prepend>
              
                                                </template>
                                              </v-textarea>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </template>
                                  <template v-else-if="biographyHasOnlineAddress.type == 'IMAGE'">
                                    <v-card-text>
                                      <v-card elevation="0" outlined width="100%" class="mr-2" @click="$refs[`image_section_${index}`][0].$refs.input.click(), sectionImageData.biographyHasOnlineAddress = biographyHasOnlineAddress">
                                        <v-img v-if="biographyHasOnlineAddress.image && biographyHasOnlineAddress.image.src" :src="biographyHasOnlineAddress.image.src" :aspect-ratio="biographyHasOnlineAddress.aspectRatio ? biographyHasOnlineAddress.aspectRatio : 1"/>
                                        <v-img v-else>
                                          <v-card-actions>
                                            <v-spacer/>
                                            <v-icon x-large  v-if="!biographyHasOnlineAddress.image || !biographyHasOnlineAddress.image.src || biographyHasOnlineAddress.image.deleted">mdi-file-image-plus-outline</v-icon>
                                            <v-spacer/>
                                          </v-card-actions>
                                        </v-img>
                                      </v-card>
                                    </v-card-text>
                                    <v-card-actions>
                                      <v-btn color="primary" class="text-none rounded-lg" @click="$refs[`image_section_${index}`][0].$refs.input.click(), sectionImageData.biographyHasOnlineAddress = biographyHasOnlineAddress">
                                        {{ $t('add_image') }}
                                      </v-btn>
                                      <v-file-input
                                          x-small
                                          :ref="`image_section_${index}`"
                                          accept="image/*"
                                          dark
                                          color="primary"
                                          hide-input
                                          hide-details
                                          hide-actions
                                          prepend-icon="mdi-file-upload"
                                          icon
                                          density="density"
                                          rounded
                                          flat
                                          dense
                                          @change="handleCrop($event, sectionImageData)"
                                          v-show="false"
                                        />
                                    </v-card-actions>
                                    <div v-if="biographyHasOnlineAddress.image && biographyHasOnlineAddress.image.src && !biographyHasOnlineAddress.image.deleted">
                                      <v-card-subtitle>
                                        <b>{{ $t('aspectRatio') }}</b>
                                      </v-card-subtitle>
                                      <v-card-text>
                                        <v-chip-group column>
                                          <v-chip v-for="item in [
                                            {value: null, name: $t('original'), icon: 'mdi-aspect-ratio'}, 
                                            {value: 1/1, name: '1:1', icon: null}, 
                                            {value: 3/2, name: '3:2', icon: null}, 
                                            {value: Math.round(16/9), name: '16:9', icon: null}
                                          ]" :key="item.value" 
                                          color="primary" 
                                          :outlined="biographyHasOnlineAddress.aspectRatio != item.value"
                                          @click="biographyHasOnlineAddress.aspectRatio = item.value">
                                            <v-icon class="mr-2" v-if="item.icon">{{ item.icon }}</v-icon>
                                              {{ item.name }}
                                          </v-chip>
                                        </v-chip-group>
                                      </v-card-text>
                                      <v-card-text>
                                        <v-text-field
                                          :disabled="biographyHasOnlineAddress.onlineAddress.id && !screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_BASIC', 'URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]"
                                          v-model="biographyHasOnlineAddress.onlineAddress.url"
                                          placeholder="https://"
                                          :label="`${$t('destinationURL')}`"
                                          filled
                                          rounded
                                          dense
                                          hide-actions
                                          hide-details
                                          :rules="[rules.url]"
                                          prepend-inner-icon="mdi-link"
                                          class="lowercase"/>
                                      </v-card-text>
                                      <v-card-text>
                                        <v-text-field
                                          v-model="biographyHasOnlineAddress.title"
                                          :placeholder="$t('title')"
                                          :label="`${$t('title')} (${$t('optional')})`"
                                          filled
                                          rounded
                                          dense
                                          hide-details
                                          hide-actions
                                          maxlength="50"
                                        />
                                      </v-card-text>
                                      <v-card-text>
                                        <v-text-field
                                          v-model="biographyHasOnlineAddress.description"
                                          :placeholder="$t('description')"
                                          :label="`${$t('description')} (${$t('optional')})`"
                                          filled
                                          rounded
                                          dense
                                          hide-actions
                                          maxlength="140"
                                          counter
                                        />
                                      </v-card-text>
                                    </div>
                                  </template>
                                  <template v-else-if="biographyHasOnlineAddress.type == 'YOUTUBE'">
                                    <v-card-text>
                                      <v-text-field
                                        :disabled="biographyHasOnlineAddress.onlineAddress.id && !screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_BASIC', 'URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]"
                                        v-model="biographyHasOnlineAddress.onlineAddress.url"
                                        placeholder="ex. https://www.youtube.com/embed/id"
                                        :label="`${$t('add_youtube')}`"
                                        filled
                                        rounded
                                        dense
                                        hide-actions
                                        hide-details
                                        :rules="[rules.url, v => v.includes('youtu') || $t('invalid')]"
                                        prepend-inner-icon="mdi-youtube"
                                        class="lowercase"
                                        @blur="convertToEmbedUrl()"/>
                                    </v-card-text>
                                    <v-card-text v-if="biographyHasOnlineAddress.onlineAddress.url">
                                      <v-card elevation="0">
                                        <iframe
                                          :src="convertToEmbedUrl(biographyHasOnlineAddress.onlineAddress.url)"
                                          width="100%"
                                          frameborder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                          allowfullscreen
                                        ></iframe>
                                      </v-card>
                                    </v-card-text>
                                  </template>
                                  <template v-else-if="biographyHasOnlineAddress.type == 'WHATSAPP'">
                                    <v-card-text>
                                      <v-text-field
                                        v-model="biographyHasOnlineAddress.title"
                                        placeholder="ex. 5571992839758"
                                        :label="`${$t('whattsappNumber')}`"
                                        filled
                                        rounded
                                        dense
                                        hide-actions
                                        type="number"
                                        hide-details
                                        :rules="[v => !!v || $t('invalid')]"
                                        prepend-inner-icon="mdi-whatsapp"
                                        class="lowercase"
                                        maxlength="15"
                                        @blur="biographyHasOnlineAddress.onlineAddress.url = `https://api.whatsapp.com/send/?phone=${biographyHasOnlineAddress.title}${biographyHasOnlineAddress.description?`&text=${biographyHasOnlineAddress.description}`: ''}`"/>
                                    </v-card-text>
                                    <v-card-text>
                                      <v-textarea
                                        v-model="biographyHasOnlineAddress.description"
                                        :placeholder="$t('message')"
                                        :label="`${$t('message')} (${$t('optional')})`"
                                        filled
                                        rounded
                                        dense
                                        hide-details
                                        hide-actions
                                        counter
                                        maxlength="100"
                                        @blur="biographyHasOnlineAddress.onlineAddress.url = `https://api.whatsapp.com/send/?phone=${biographyHasOnlineAddress.title}${biographyHasOnlineAddress.description?`&text=${biographyHasOnlineAddress.description}`: ''}`"
                                      />
                                    </v-card-text>
                                    <v-textarea
                                        v-show="false"
                                        :rules="[v => !!v || $t('invalid')]"
                                        v-model="biographyHasOnlineAddress.onlineAddress.url"
                                        :placeholder="$t('message')"
                                        :label="`${$t('message')} (${$t('optional')})`"
                                        filled
                                        rounded
                                        dense
                                        hide-details
                                        hide-actions
                                        counter
                                        maxlength="100"
                                      />
                                    <v-card-text>
                                      <v-text-field
                                        v-model="biographyHasOnlineAddress.onlineAddress.tags"
                                        :placeholder="$t('description')"
                                        :label="`${$t('description')}`"
                                        filled
                                        rounded
                                        dense
                                        hide-details
                                        hide-actions
                                        :rules="[
                                          v => !!v || $t('required')
                                        ]"
                                        maxlength="100"/>
                                    </v-card-text>
                                  </template>
                                  <template v-else-if="biographyHasOnlineAddress.type == 'INSTAGRAM'">
                                    <v-card-text>
                                      <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="5">
                                          <v-text-field
                                            v-model="biographyHasOnlineAddress.title"
                                            placeholder="ex. @migly"
                                            :label="`${$t('username_instagram_profile')}`"
                                            filled
                                            rounded
                                            dense
                                            hide-actions
                                            hide-details
                                            prepend-inner-icon="mdi-instagram"
                                            @change="(v) => {biographyHasOnlineAddress.onlineAddress.url = `https://www.instagram.com/${v}/profilecard`, loadInstagramInfo(biographyHasOnlineAddress)}"
                                            maxlength="30"/>
                                          <v-textarea
                                            v-show="false"
                                            :rules="[v => !!v || $t('invalid')]"
                                            v-model="biographyHasOnlineAddress.onlineAddress.url"
                                            :placeholder="$t('message')"
                                            :label="`${$t('message')} (${$t('optional')})`"
                                            filled
                                            rounded
                                            dense
                                            hide-details
                                            hide-actions
                                            counter
                                            maxlength="100"
                                          />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="7">
                                          <v-text-field
                                            v-model="biographyHasOnlineAddress.description"
                                            placeholder="ex. Mais em nosso instagram"
                                            :label="`${$t('description')}`"
                                            filled
                                            rounded
                                            dense
                                            hide-actions
                                            hide-details
                                            class="lowercase"
                                            maxlength="50"/>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </template>
                                </v-card>
                              </v-card>
                              <v-card v-else class="card-corner-radius"  elevation="0" color="grey lighten-2">
                                <v-card class="card-corner-radius" elevation="0" color="grey lighten-2">
                                  <v-card-title>
                                    <b>{{index + 1}}º {{$t('link')}}</b>
                                  </v-card-title>
                                  <v-card-subtitle>
                                    {{ biographyHasOnlineAddress.onlineAddress.tags }} - {{ biographyHasOnlineAddress.onlineAddress.url }}
                                  </v-card-subtitle>
                                  <v-card-actions>
                                    <v-spacer/>
                                    <i>{{ $t('excluded') }}</i>
                                  </v-card-actions>
                                </v-card>
                              </v-card>
                            </v-slide-x-transition>
                          </v-card-text>
                        </transition-group>
                      </draggable>
                      <v-card-subtitle v-if="bio.biographyHasOnlineAddresses[0]" class="description--text">{{ $t('draggableLinksInfo') }}</v-card-subtitle>
                      <v-card-actions class="ml-2">
                        <v-btn class="rounded-lg text-none mr-1" color="primary" @click="dialogSelectSection = true">
                          {{ $t('newSection') }}
                        </v-btn>
                      </v-card-actions>
                      <v-card-text v-if="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_BASIC', 'URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
                        <v-alert dense small color="#78FFFF">
                          <v-card-actions style="color: #0C9B93;">
                            <v-icon color="#0C9B93" class="mr-2">mdi-information-slab-circle-outline</v-icon>
                            {{$t('deleteAddressInfoPlan')}}
                          </v-card-actions>
                          <v-card-actions>
                            <v-spacer/>
                            <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_BASIC')" :planData="planData"/>
                          </v-card-actions>
                        </v-alert>
                      </v-card-text>
                    </div>
                    <div v-else-if="tab == 2">
                      <v-card-title><b>{{ $t('generalAparence') }}</b></v-card-title>
                      <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('generalAparenceDesc') }}</v-card-subtitle>
                      <v-card-subtitle><b>{{ $t('layout') }}</b></v-card-subtitle>
                      <v-card-text>
                        <v-row>
                          <v-col v-for="l in [
                            {id: 1},
                            {id: 2},
                            {id: 3},
                            {id: 4}
                          ]" :key="`layout-${l.id}`" cols="6" xs="6" sm="4" md="3">
    
                            <v-card elevation="0" outlined @click="bio.layout = l.id, rolarParaDiv('container-mobile-preview', `section-2`)">
                              <div v-if="l.id == 1">
                                <v-card-actions>
                                  <v-spacer/>
                                  <v-avatar color="grey" class="mt-2"/>
                                  <v-spacer/>
                                </v-card-actions>
                                <v-card-actions class="ma-0 mt-2 pa-0">
                                  <v-spacer/>
                                  <b>{{ $t('title') }}</b>
                                  <v-spacer/>
                                </v-card-actions>
                                <v-card-actions class="ma-0 pa-0">
                                  <v-spacer/>
                                  {{ $t('description') }}
                                  <v-spacer/>
                                </v-card-actions>
                              </div>
                              <div v-if="l.id == 2">
                                <v-app-bar elevation="0" color="grey lighten-2" absolute class="">
                                  <div class="inverted-border-radius"></div>
                                </v-app-bar>
                                <v-card-actions>
                                  <v-spacer/>
                                  <v-avatar color="grey" class="mt-2"/>
                                  <v-spacer/>
                                </v-card-actions>
                                <v-card-actions class="ma-0 mt-2 pa-0">
                                  <v-spacer/>
                                  <b>{{ $t('title') }}</b>
                                  <v-spacer/>
                                </v-card-actions>
                                <v-card-actions class="ma-0 pa-0">
                                  <v-spacer/>
                                  {{ $t('description') }}
                                  <v-spacer/>
                                </v-card-actions>
                              </div>
                              <div v-if="l.id == 3">
                                <v-app-bar elevation="0" color="grey lighten-2" absolute height="70">
                                </v-app-bar>
                                <v-card-actions>
                                  <v-avatar color="grey" class="mt-2"/>
                                  <v-spacer/>
                                </v-card-actions>
                                <v-card-actions class="ma-0 mt-2 pa-0 pl-2">
                                  <b>{{ $t('title') }}</b>
                                  <v-spacer/>
                                </v-card-actions>
                                <v-card-actions class="ma-0 pa-0 pl-2">
                                  {{ $t('description') }}
                                  <v-spacer/>
                                </v-card-actions>
                              </div>
                              <div v-if="l.id == 4">
                                <v-app-bar elevation="0" color="grey lighten-2" absolute height="70">
                                </v-app-bar>
                                <v-card elevation="0" color="transparent">
                                  <v-card-actions class="ma-0 mt-2 pa-0 pl-2">
                                    <v-spacer/>
                                    <b>{{ $t('title') }}</b>
                                    <v-spacer/>
                                  </v-card-actions>
                                </v-card>
                                <v-card-actions>
                                  <v-spacer/>
                                  <v-avatar color="grey" class="mt-2"/>
                                  <v-spacer/>
                                </v-card-actions>
                                <v-card-actions class="ma-0 pa-0 pl-2">
                                  <v-spacer/>
                                  {{ $t('description') }}
                                  <v-spacer/>
                                </v-card-actions>
                              </div>
                              <v-card height="80" elevation="0" color="transparent"/>
                            </v-card>
                            <v-card-actions>
                              <v-spacer/>
                              <v-icon v-if="!!bio.layout && bio.layout == l.id" color="primary">mdi-check-circle</v-icon>
                              <v-spacer/>
                            </v-card-actions>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-title><b>{{ $t('background') }}</b></v-card-title>
                      <v-card-subtitle><b>{{ $t('color') }}</b></v-card-subtitle>
                      <v-card-text>
                        <ColorMenu :data="bio" :attribute="`backgroundColor`" :title="$t('backgroundColor')" :fab="true"/>
                      </v-card-text>
                      <v-card-title><b>{{ $t('textColor') }}</b></v-card-title>
                      <v-row no-gutters>
                        <v-col cols="12" xs="12" sm="12" md="6">
                          <v-card-subtitle class="pt-0"><b>{{ $t('title') }}</b></v-card-subtitle>
                          <v-card-text>
                            <ColorMenu :data="bio" :attribute="`titleColor`" :title="$t('titleColor')" :fab="true" :change="() => rolarParaDiv('container-mobile-preview', `section-name`)"/>
                          </v-card-text>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6">
                          <v-card-subtitle class="pt-0"><b>{{ $t('description') }}</b></v-card-subtitle>
                          <v-card-text>
                            <ColorMenu :data="bio" :attribute="`descriptionColor`" :title="$t('descriptionColor')" :fab="true" :change="() => rolarParaDiv('container-mobile-preview', `section-description`)"/>
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-card-subtitle><b>{{ $t('font') }}</b></v-card-subtitle>
                      <v-card-text>
                        <v-row>
                          <v-col cols="6" xs="6" sm="4" md="4" v-for="font in [
                            {id: 1, font: 'roboto'},
                            {id: 2, font: 'open-sans'},
                            {id: 3, font: 'lora'},
                            {id: 4, font: 'montserrat'},
                            {id: 5, font: 'merriweather'},
                            {id: 6, font: 'raleway'},
                            {id: 7, font: 'noto-serif-oriya'},
                            {id: 8, font: 'architects-daughter'},
                            {id: 9, font: 'permanent-marker-regular'},
                            ]" :key="`font-${font.id}`">
                            <v-card outlined :class="`text-none ${font.font} pa-2 text-center`" block @click="bio.font = font.id">
                              <v-icon v-if="bio.font == font.id" color="primary">mdi-check-circle</v-icon>
                              {{ $t(font.font) }}
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-text>
                        <v-divider/>
                      </v-card-text>
                      <v-card-title><b>{{ $t('socials') }}</b></v-card-title>
                      <v-card-subtitle><b>{{ $t('selectYourSocialNetworks') }}</b></v-card-subtitle>
                      <v-card-text>
                        <v-chip-group column>
                          <v-chip outlined color="primary"  v-for="s in $t('socialOptions')" :key="s.name" @click="bio.socialLinks.push({title: s.name, onlineAddress: {url : s.url}, deleted: false}), validateForms(), rolarParaDiv('container-mobile-preview', `section-link-social`), bio.socialLinks.forEach((i, index) => i.order = index)" :disabled="!!bio.socialLinks.filter(v => v.title == s.name && !v.deleted)[0]">
                            <v-icon class="mr-1">{{ s.icon }}</v-icon>
                            {{ s.name }}
                          </v-chip>
                        </v-chip-group>
                      </v-card-text>
                      <v-card-subtitle v-if="bio.socialLinks[0]" class="description--text mt-0 pt-0">{{ $t('draggableLinksInfo') }}</v-card-subtitle>
                      <draggable v-model="bio.socialLinks" @end="onEnd,bio.socialLinks.forEach((i, index) => i.order = index)"       
                        :animation="200"
                        ghost-class="ghost"
                        chosen-class="chosen"
                        drag-class="drag">
                        <transition-group type="transition" name="flip-list" tag="div">
                          <v-card-actions v-for="(link, index) in bio.socialLinks.filter(v => !v.deleted)" :key="`${link.id}-${index}`" class="rounded-lg">
                            <v-text-field
                              v-model="link.onlineAddress.url"
                              required
                              :rules="[
                                v => !!v || $t('required'),
                                v => !!v.startsWith($t('socialOptions').filter(v => v.name == link.title)[0].url) || `${$t('invalid')} (${$t('socialOptions').filter(v => v.name == link.title)[0].url})`,
                                v => v != ($t('socialOptions').filter(v => v.name == link.title)[0].url) || `${$t('invalid')} (${$t('socialOptions').filter(v => v.name == link.title)[0].url}username)`
                              ]"
                              :label="link.title"
                              :placeholder="$t('socialOptions').filter(v => v.name == link.title)[0].url"
                              outlined
                              maxlength="100"
                              filled
                              flat
                              rounded
                              @keyup="rolarParaDiv('container-mobile-preview', `section-link-social`)"
                              dense>
                              <template v-slot:prepend-inner>
                                <v-icon class="mr-1">{{$t('socialOptions').filter(v => v.name == link.title)[0].icon}}</v-icon>
                              </template>
                              <template v-slot:append>
                                <v-btn icon color="red" @click="link.deleted = true"><v-icon>mdi-delete</v-icon></v-btn>
                              </template>
                            </v-text-field>
                          </v-card-actions>
                        </transition-group>
                      </draggable>
                      <div v-if="bio.socialLinks[0]">
                        <v-card-subtitle class="pb-0"><b>{{ $t('placement') }}</b></v-card-subtitle>
                        <v-card-subtitle class="mt-0 pt-0 pb-0">
                          <v-radio-group
                            v-model="bio.placementSocialLinksOnTop"
                            column
                          >
                            <v-radio
                              v-for="op in [
                                {description: 'topOfPage', value: true},
                                {description: 'bottomOfPage', value: false}
                                ]" :key="op.value"
                              :label="$t(op.description)"
                              :value="op.value"
                              @change="() => rolarParaDiv('container-mobile-preview', `section-link-social`)"
                            />
                          </v-radio-group>
                        </v-card-subtitle>
                        <v-card-subtitle><b>{{ $t('socialIconsColor') }}</b></v-card-subtitle>
                        <v-card-text>
                          <ColorMenu :data="bio" :attribute="`socialIconsColor`" :title="$t('socialIconsColor')" :fab="true" :change="() => rolarParaDiv('container-mobile-preview', `section-link-social`)"/>
                        </v-card-text>
                      </div>
                      <v-card-text>
                        <v-divider/>
                      </v-card-text>
                      <v-card-title><b>{{ $t('sections') }}</b></v-card-title>
                      <v-card-subtitle><b>{{ $t('shape') }}</b></v-card-subtitle>
                      <v-card-text>
                        <v-row>
                          <v-col cols="6" xs="6" sm="4" md="3"
                            v-for="shape in [
                            {id: 1, borderRadius: 'rounded-0', outlined: true},
                            {id: 2, borderRadius: 'rounded-sm', outlined: true},
                            {id: 3, borderRadius: 'rounded', outlined: true},
                            {id: 5, borderRadius: 'rounded-xl', outlined: true},
                            {id: 6, borderRadius: 'rounded-0', outlined: false},
                            {id: 7, borderRadius: 'rounded-sm', outlined: false},
                            {id: 8, borderRadius: 'rounded', outlined: false},
                            {id: 9, borderRadius: 'rounded-xl', outlined: false},
                          ]" :key="`shape-${shape.id}`">
                            <v-btn :class="`${shape.borderRadius}`" :outlined="shape.outlined" color="grey" block @click="bio.shape = shape.id, rolarParaDiv('container-mobile-preview', `section-1`)">
                              <v-card-actions v-if="shape.id == bio.shape">
                                <v-spacer/>
                                <v-icon color="primary">mdi-check-circle</v-icon>
                                <v-spacer/>
                              </v-card-actions>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-subtitle><b>{{ $t('shadow') }}</b></v-card-subtitle>
                      <v-card-text>
                        <v-row>
                          <v-col cols="6" xs="6" sm="4" md="3"
                            v-for="shadow in [
                            {id: 1, shadow: 'sombra-leve'},
                            {id: 2, shadow: 'sombra-interna'},
                            {id: 3, shadow: 'sombra-difusa'},
                            {id: 4, shadow: 'sombra-intensa'},
                          ]" :key="`shadow-${shadow.id}`">
                            <v-btn :class="`${shadow.shadow}`" outlined color="grey" block @click="bio.shadow = shadow.id,rolarParaDiv('container-mobile-preview', `section-1`)">
                              <v-card-actions v-if="shadow.id == bio.shadow">
                                <v-spacer/>
                                <v-icon color="primary">mdi-check-circle</v-icon>
                                <v-spacer/>
                              </v-card-actions>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-subtitle><b>{{ $t('color') }}</b></v-card-subtitle>
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" v-for="propertie in ['sectionColor', 'sectionTextColor']" :key="propertie">
                          <v-card-subtitle><b>{{ $t(propertie) }}</b></v-card-subtitle>
                          <v-card-text>
                            <ColorMenu :data="bio" :attribute="propertie" :title="$t(propertie)" :fab="true" :change="() => rolarParaDiv('container-mobile-preview', `section-1`)"/>
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </div>
                    <v-card-actions>
                      <v-btn color="primary" @click="dialogPreview = true, scrollIntoView('container-mobile-preview'), rolarParaDiv('container-mobile-preview', `section-${tab}`)" class="rounded-lg text-none" v-if="['xs' , 'sm'].includes($vuetify.breakpoint.name)">
                        {{ $t('toSee') }}
                      </v-btn>
                      <v-spacer/>
                      <v-btn outlined color="primary" @click="tab--, validateForms(), !bio.id ? scrollIntoView('form') : '', rolarParaDiv('container-mobile-preview', `section-${tab}`)" class="rounded-lg text-none" v-if="tab > 0" :disabled="loadingSave">
                        {{ $t('toGoBack') }}
                      </v-btn>
                      <v-btn color="primary" @click="tab++, validateForms(), !bio.id ? scrollIntoView('form') : '', rolarParaDiv('container-mobile-preview', `section-${tab}`)" class="rounded-lg text-none" :disabled="loadingSave || !item.form || !canUseUserName" v-if="tab < 2">
                        {{ $t('moveForward') }}
                      </v-btn>
                      <v-btn color="primary" @click="save" class="rounded-lg text-none" :loading="loadingSave" :disabled="!item.form || (!bio.id && tab < 2)">
                        {{ $t('save') }}
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </section>
        </div>
      </v-col>
      <v-col v-if="!['xs' , 'sm'].includes($vuetify.breakpoint.name)" cols="12" xs="12" sm="12" md="5" :class="!['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'full-height' : ''">
        <v-card class="card-corner-radius mt-4 ml-4" elevation="0" style="height: 100%;">
          <v-card class="center" elevation="0">
            <v-card-title>
              <v-spacer/>
                <v-chip color="transparent" text @click="$router.push(`/painel/links/shortened/?onlineAddressId=${bio.onlineAddress.id}&shortenedRecent=${bio.onlineAddress.id}&typeIn=BIO`)" :disabled="loadingSave || !bio.id">
                  <v-icon small color="blue">mdi-chart-bar</v-icon>
                  <span class="ml-2 blue--text">{{$t('followMetrics')}}</span>
                </v-chip>
              <v-spacer/>
            </v-card-title>
            <v-card-text>
              <v-img src="/mockup-iphone-02.svg" class="mobile-preview">
                <div class="mobile-preview-container" id="container-mobile-preview">
                  <BiographySmartphoneView :bio="bio" :onlySmartphone="false"/>
                </div>
              </v-img>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <a :href="`${protocol}//${host}/${bio.onlineAddress.code}?countClick=false`" target="_blank" class="link">
                <v-chip text v-if="bio.id" color="transparent" :disabled="loadingSave">
                  <v-icon small color="blue">mdi-navigation-variant</v-icon>
                  <span class="ml-1 blue--text">{{$t(`viewUrl`)}}</span>
                </v-chip>
              </a>
              <v-spacer/>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-col>  
    </v-row>
    <v-bottom-sheet v-if="['xs' , 'sm'].includes($vuetify.breakpoint.name)" v-model="dialogConfig">
      <v-sheet class="overflow">
        <v-card-actions>
          <v-btn icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer/>
          <b>{{ $t('config') }}</b>
          <v-spacer/>
          <v-btn icon color="#7E8EA6" @click="dialogConfig = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider class="ma-4 mt-0"/>
        <Config :screenData="screenData"/>
      </v-sheet>
    </v-bottom-sheet>
    <v-navigation-drawer 
      v-else
      v-model="dialogConfig"
      fixed
      width="50%"
      height="100%"
      class="grey lighten-5"
      >
      <v-sheet>
        <v-card-actions>
          <v-btn icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer/>
          <b>{{ $t('config') }}</b>
          <v-spacer/>
          <v-btn icon color="#7E8EA6" @click="dialogConfig = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider class="ma-4 mt-0"/>
        <Config :screenData="screenData"/>
      </v-sheet>
    </v-navigation-drawer>
    <v-overlay :value="dialogConfig"></v-overlay>
    <v-dialog v-model="planData.dialogPlansAndPrices" width="800">
      <v-card>
        <v-card-actions>
          <v-btn @click="planData.dialogPlansAndPrices = false" icon><v-icon color="primary">mdi-arrow-left</v-icon></v-btn>
          <v-spacer/>
        </v-card-actions>
        <v-card-text>
          <SubscriptionAndPlans :screenData="screenData"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSelectSection" width="900">
      <v-card elevation="0">
        <v-card-actions>
          <v-btn icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer/>
          <b>{{ $t('newSection') }}</b>
          <v-spacer/>
          <v-btn icon color="#7E8EA6" @click="dialogSelectSection = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider class="mr-4 ml-4"/>
        <v-card elevation="0" max-height="85vh" class="overflow-auto">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="6">
              <v-card-title>
                {{ $t('content') }}
              </v-card-title>
              <v-list three-line>
                <template v-for="type, index in [
                  {id: 'SAMPLE_LINK', icon: 'mdi-link'},
                  {id: 'IMAGE', icon: 'mdi-image-area'},
                  {id: 'YOUTUBE', icon: 'mdi-youtube'},
                  {id: 'WHATSAPP', icon: 'mdi-whatsapp'},
                  {id: 'INSTAGRAM', icon: 'mdi-instagram'}
                  ]">
                  <v-list-item v-bind:key="type.id">
                    <v-list-item-avatar>
                      <v-avatar color="primary">
                        <v-icon color="white">{{type.icon}}</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t(type.id.toLowerCase()) }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t(`add_${type.id.toLowerCase()}`) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn text class="text-none" color="primary" @click="bio.biographyHasOnlineAddresses.push(getNewLink(type.id)), bio.biographyHasOnlineAddresses.forEach((v,index) => v.order = index), dialogSelectSection = false, rolarParaDiv('container-mobile-preview', `section-link-${bio.biographyHasOnlineAddresses.filter(v => !v.deleted).length -1}`), scrollIntoView(`section-edit-links-${bio.biographyHasOnlineAddresses.length -1}`)">
                        <b>{{ $t('add') }}</b>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-bind:key="type.id" class="ml-4 mr-4" v-if="index < 0"/>
                </template>
              </v-list>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
    <CropImage v-if="sectionImageData.biographyHasOnlineAddress" :data="sectionImageData" :object="sectionImageData.biographyHasOnlineAddress.image" field="src"  :stencilSize="{width: 1000, height: 500}" :maxSizeInMB="10"/>
    <v-dialog v-model="dialogTemplate" width="1000" persistent :fullscreen="['xs' , 'sm'].includes($vuetify.breakpoint.name)">
      <v-card elevation="0">
        <v-card-actions>
          <v-btn icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer/>
          <b>{{ $t('templates') }}</b>
          <v-spacer/>
          <v-btn icon color="#7E8EA6" @click="dialogTemplate = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider class="ma-4"/>
        <v-card-subtitle><b>{{ $t('selectATemplate') }}</b></v-card-subtitle>
        <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('selectATemplateDesc') }}</v-card-subtitle>
        <v-slide-group
          v-model="bio"
          class="pa-4"
          active-class="primary"
          show-arrows
        >
          <v-slide-item
            v-for="t in $t('bioTemplates')" :key="t.key"
            v-slot="{ active, toggle }"
            :value="t"
          >
            <v-card elevation="0" max-height="400" class="overflow-auto mr-4 pa-1" width="250" :color="active ? undefined : 'transparent'" @click="toggle"  >
              <BiographySmartphoneView :bio="t"/>
            </v-card>
          </v-slide-item>
        </v-slide-group>
        <v-footer absolute color="transparent"> 
          <v-spacer/>
          <v-btn color="primary" @click="dialogTemplate = false" class="rounded-lg text-none">
            {{ $t('toConfirm') }}
          </v-btn>
        </v-footer>
      </v-card>
    </v-dialog>

    <v-bottom-sheet v-if="['xs' , 'sm'].includes($vuetify.breakpoint.name)" v-model="dialogPreview">
      <v-sheet class="overflow">
        <v-card-actions>
          <v-btn icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer/>
          <b>{{ $t('preview') }}</b>
          <v-spacer/>
          <v-btn icon color="#7E8EA6" @click="dialogPreview = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider class="ma-4 mb-0 mt-0"/>
        <v-card-text>
          <v-card elevation="0" class="overflow-auto" max-height="500">
            <BiographySmartphoneView :bio="bio" :onlySmartphone="false"/>
          </v-card>
        </v-card-text>
      </v-sheet>
    </v-bottom-sheet>

  </v-main>
</template>
<script>
import {apiV1Biographies} from "../../../service/resources/api-v1-biography"
import { apiV1OnlineAddresses } from "../../../service/resources/api-v1-online-addresses"
import { apiV1OpenGraph } from "../../../service/resources/api-v1-open-graph"

import BiographySmartphoneView from "../fragments/BiographySmartphoneView.vue"
import ShareOptions from "../fragments/ShareOptions.vue"
import Config from "../fragments/Config.vue"
import SubscriptionAndPlans from '../fragments/SubscriptionAndPlans.vue';
import IconInfoSubscriblePlan from "../fragments/IconInfoSubscriblePlan.vue";
import CropImage from '../fragments/CropImage.vue';
import ColorMenu from '../fragments/ColorMenu.vue';
import draggable from 'vuedraggable';
import * as mdiIcons from '@mdi/js';
  export default {
    name: 'PainelBioPage',
    components: {
    SubscriptionAndPlans,
    BiographySmartphoneView,
    ShareOptions,
    Config,
    IconInfoSubscriblePlan,
    CropImage,
    ColorMenu,
    draggable
},
    props: ['screenData'],
    data: () => ({
      dialogTemplate: false,
      tab: 1,
      dialogPreview: false,
      tabs: [
        {title: 'profile', form: false},
        {title: 'content', form: false},
        {title: 'generalAparence', form: false}
      ],
      dialogSelectSection: false,
      dialogConfig: false,
      sectionImageData: {
        dialog: false,
        img: null,
        biographyHasOnlineAddress: null
      },
      planData: {
        dialogPlansAndPrices: false
      },
      form: false,
      whatsappForm: false,
      host: null,
      protocol: null,
      bio: null,
      loadingSave: false,
      loading: true,
      rules: {url:false},
      loadingVerifyUserName: false,
      canUseUserName: true,
      mdiIcons: []
    }),
    computed: {
      valid: function() {
        return !this.bio.name
          || !this.bio.onlineAddress.code
          || this.bio.biographyHasOnlineAddresses
          .filter(i => !i.withoutOnlineAddress)
          .filter(i => !i.deleted)
          .filter(i => !i.onlineAddress.url).length != 0
          || this.bio.biographyHasOnlineAddresses
          .filter(i => !i.deleted)
          .filter(i => !i.id)
          .filter(i => !this.isUrl(i.onlineAddress.url)).length != 0
      }
    },
    mounted: function() {
      this.rules = {
        url: value => {
          return this.isUrl(value) || this.$t('invalidUrl')
        }
      }
      this.protocol = window.location.protocol
      this.host = window.location.host.replace('www.', '')
      this.loading = true
      apiV1Biographies.page({
        page: 0,
        size: 100,
        embedBiographyHasOnlineAddress: true,
        embedSocialLink: true,
        userAuth: true
      }).then(result => {
        if(result.data.content[0]) {
          this.bio = result.data.content[0]
          this.bio.biographyHasOnlineAddresses.filter(v => ['INSTAGRAM'].includes(v.type)).forEach(v => {
            this.loadOpenGraph(v)
          })
        } else {
          this.tab = 0
          this.bio = this.$t('bioTemplates')[0]
          this.dialogTemplate = true
        }
        this.loading = false
        this.validateForms()
      })

      this.mdiIcons = [''].concat(Object.entries(mdiIcons)
        .filter(([key]) => key.startsWith('mdi'))
        .map(([name]) => name.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()));
    },
    methods: {
      onEnd(event) {
        // Você pode manipular o evento de fim de arraste aqui, se necessário
        console.log('Drag ended', event);
      },
      validateForms: function() {
        this.tabs.forEach((t,index) => setTimeout(() => {
            if(this.$refs[`form_tab_${index}`] && this.$refs[`form_tab_${index}`][0])
              this.$refs[`form_tab_${index}`][0].validate()
            if(this.$refs[`form_tab_${index}`] && this.$refs[`form_tab_${index}`].validate)
              this.$refs[`form_tab_${index}`].validate()
          }, 1000))
      },
      scrollIntoView: function(id) {
        setTimeout(() => {
          if(document.getElementById(id))
            document.getElementById(id).scrollIntoView({
              behavior: "smooth"
            });
        }, 500);
      },
      handleCrop: function(event, data){
        var reader = new FileReader()
        reader.readAsDataURL(event)
        if(reader.size > 2000000) {
          this.$commons.updateSnackbar({show: true, color: 'error', text: this.$t('invalid')})
          return
        }
        reader.onload = function () {
          data.img = {src: reader.result}
          data.dialog = true
        }
      },
      loadInstagramInfo: async function(item) {
        item.loading = true
        this.$forceUpdate()
        try {
          const response = await this.loadOpenGraph(item)

          if(!item.image)
            item.image = {src: null, deleted: false}
          item.image.deleted = false
          item.image.src = response['og:image']
          item.loading = false
          this.$forceUpdate()
        } catch (error) {
          this.$commons.updateSnackbar({show: true, color: 'error', text: this.$t('errorLinkLoad')})
          item.loading = false
          this.$forceUpdate()
        }
      },
      loadOpenGraph: async function(item) {
        const response = (await apiV1OpenGraph.tags({source: item.onlineAddress.url})).data;
        return response
      },
      prepareOG: function(item) {
        if(!item.onlineAddress.openGraph)
          item.onlineAddress.openGraph = {openGraphFields: []}
        if(!item.onlineAddress.openGraph.openGraphFields)
          item.onlineAddress.openGraph.openGraphFields = []
        item.onlineAddress.openGraph.onlineAddress = {id: item.onlineAddress.id}
      },
      verifyUserName: function() {
        this.loadingVerifyUserName = true
        this.canUseUserName = false
        apiV1OnlineAddresses.get(this.bio.onlineAddress.code).then(response => {
          if(response.data) {
            this.canUseUserName = response.data.id == this.bio.onlineAddress.id
          } else {
            this.canUseUserName = true
          }
          this.loadingVerifyUserName = false
        }).catch(()=>{
          this.loadingVerifyUserName = false
        })
      },
      convertToEmbedUrl: function(youtubeUrl) {
        // Verifica se a URL é válida
        const urlPattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
        if (!youtubeUrl.match(urlPattern)) {
          return null;
        }

        // Extrai o ID do vídeo da URL
        const videoIdPattern = /(?:youtube\.com\/(?:[^\\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = youtubeUrl.match(videoIdPattern);

        if (match && match[1]) {
          return `https://www.youtube.com/embed/${match[1]}`;
        }

        return null;
      },
      isWhatsapp: function(address) {
        return (address.url.includes('whatsapp') && address.url.includes('send')) || address.url.includes('wa.me')
      },
      getNumber: function(address) {
        return address.url
        .replace('https://api.whatsapp.com/send/?', '')
        .replace('phone=', 'N: ')
        .replace('&text=', ' M: ')
        .replace('&app_absent=0', '')
      },
      isUrl: function(value) {
        const pattern = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi
        return pattern.test(value)
      },
      save: function() {
        this.loadingSave = true
        if(this.bio.cover && !this.bio.cover.src)
          this.bio.cover = null
        if(this.bio.picture && !this.bio.picture.src)
          this.bio.picture = null
        apiV1Biographies.post(this.bio).then(result => {
          this.bio = result.data
          this.bio.biographyHasOnlineAddresses.filter(v => ['INSTAGRAM'].includes(v.type)).forEach(v => {
            this.loadOpenGraph(v)
          })
          this.loadingSave = false
          this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('saved')})
        }).catch(() => {
          this.loadingSave = false
        })
      },
      getNewLink: function(type) {
        return {
          deleted: false,
          biography: null,
          onlineAddress: {
            tags: '',
            url: '',
            active: true,
            type: 'BIO'
          },
          type: type,
          image: type == 'IMAGE' ? {src: null} : null,
          aspectRatio: `1`,
          title: null,
          description: null,
          icon: null
        }
      },
      handleCover: function(event) {
        var reader = new FileReader()
        reader.readAsDataURL(event)
        let self = this
        reader.onload = function () {
          if(!self.bio.cover) {
            self.bio.cover = {}
          }
          self.bio.cover.deleted = false
          self.bio.cover.src = reader.result
          self.rolarParaDiv('container-mobile-preview', `section-cover`)
        }
      },
      handlePicture: function(event) {
        var reader = new FileReader()
        reader.readAsDataURL(event)
        let self = this
        reader.onload = function () {
          if(!self.bio.picture)
            self.bio.picture = {}
          self.bio.picture.src = reader.result
          self.bio.picture.deleted = false
          self.rolarParaDiv('container-mobile-preview', `section-picture`)
        }
      },
      copyLink: function (item) {
        let element = document.querySelector('#link-'+ item.id);
        element.setAttribute('type', 'text') 
        element.select()
        document.execCommand('copy');
        console.log(element)
        item.copy = true
        this.$forceUpdate()
        setTimeout(() => {
          item.copy = false
          this.$forceUpdate()
        }, 3000);
      },
      rolarParaDiv: function(containerID, itemID) {
        setTimeout(() => {
          var container = document.getElementById(containerID);
          var target = document.getElementById(itemID);
          if(container)
            container.scrollTo({
              top: target.offsetTop,
              behavior: 'smooth'
            });
        }, 500);
      }
    },
  }
</script>
<style>
.mobile-preview{
  margin: auto;
  /* top: 50%; */
  /* -ms-transform: translateY(-50%); */
  /* transform: translateY(-50%); */
  width: 300px;
  height: 600px;
}
.center{
  margin: auto;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: fit-content;
}
.mobile-preview-container{
  height: 92%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x:hidden;
  margin-top: 36px !important;
  margin-left: 16.7px !important;
  margin-right: 17px !important;
  border-radius: 5px 5px 32px 33px;
}

.mobile-preview-container::-webkit-scrollbar {
  width: 0px;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.card-link{
  padding: 1px;
}

input:focus {
    outline: none; /* Remove a borda padrão do navegador */
    border: none;  /* Remove a borda definida */
}

.inverted-border-radius {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.inverted-border-radius::after {
    content: '';
    position: absolute;
    bottom: -10%; /* ajuste a altura conforme necessário */
    left: 0;
    width: 100%;
    height: 60%; /* ajuste a altura conforme necessário */
    background-color: white; /* a mesma cor do fundo do contêiner pai */
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
}

.sombra-leve {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
.sombra-intensa {
    box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.5);
}
.sombra-difusa {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}
.sombra-interna {
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.2);
}
.ghost {
  opacity: 0.4;
}
.chosen {
  background-color: #f0f0f0;
}
.drag {
  opacity: 0.6;
}
/* Animação de transição */
.flip-list-move {
  transition: transform 0.2s;
}
</style>