import http from "../axios";

let apiV1Subscription = {
	baseUrl: '/api/v1/subscriptions',
	subscrible: async (dto) => {
		return await http.post(`${apiV1Subscription.baseUrl}`, dto);
	},
	get: async () => {
		return await http.get(`${apiV1Subscription.baseUrl}`);
	},
	delete: async (id) => {
		return await http.delete(`${apiV1Subscription.baseUrl}/${id}`);
	},
}

export  { apiV1Subscription }