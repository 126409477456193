<template>
  <v-sheet>
    <v-card-actions>
      <v-btn icon color="white">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer/>
      <b>{{ $t('reportLink') }}</b>
      <v-spacer/>
      <v-btn icon color="#7E8EA6" @click="data.dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-actions>
    <v-divider class="mr-4 ml-4"/>
    <v-card v-if="sended" elevation="0">
      <v-card elevation="0" outlined class="ma-4">
          <v-card-title>
            <b>{{ $t('reportLinkSendTitle') }}</b>
          </v-card-title>
          <v-card-subtitle>
            <b>{{ $t('reportLinkSendDescription') }}</b>
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer/>
            <a href="migly.in">
              <v-btn elevation="1" class="rounded-lg text-none pointer" outlined small color="primary">
                <v-card-actions>
                  <v-img src="/images/logo/logo-02.png" contain key="2" width="20" class="mr-2"/> 
                  {{ $t('tryMigly') }}
                </v-card-actions>
              </v-btn>
            </a>
            <v-spacer/>
          </v-card-actions>
          <v-card-actions>
            <v-spacer/>
            <small><p><a class="link" target="_self" href="https://migly.in">migly.in</a> é um produto <a class="link" target="_blank" href="http://exatamente-solucoes.com"> exatamente soluções.</a></p></small>
            <v-spacer/>
          </v-card-actions>
      </v-card>
    </v-card>
    <v-card v-else elevation="0" max-height="55vh" class="overflow-auto">
      <v-form v-model="form">
        <v-card-subtitle><b>{{ $t('reportLinkTitle') }}</b></v-card-subtitle>
        <v-card-text>
          <v-textarea
            :label="$t('message')"
            v-model="dto.message"
            :placeholder="$t('reportLinkPlaceholder')"
            filled
            rounded
            dense
            hide-actions
            :rules="[
              v => !!v || $t('required')
            ]"
            counter
            maxlength="500"
            minlength="10"
            rows="5"
            no-resize
          />
        </v-card-text>
        <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('reportLinkDescription') }}</v-card-subtitle>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="send()" width="55%" class="rounded-lg text-none" :loading="loading" :disabled="!form">
            {{ $t('send') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-sheet>
</template>
<script>
import { apiV1LinkReport } from "../../../service/resources/api-v1-link-report"
export default {
    name: 'LinkReport',
    props: ['data', 'onlineAddress'],
    components: {
    },
    data: () => ({
      sended: false,
      loading: false,
      form: false,
      dto: {
        message: '',
        onlineAddress: {
          id: 0
        }
      }
    }),
    watch: {
      'data.dialog': function() {
        this.sended = false
      },
      'onlineAddress' : function() {
        this.dto.onlineAddress = this.onlineAddress
      }
    },
    mounted: function() {
      this.dto.onlineAddress = this.onlineAddress
    },
    computed: {
    },
    methods: {
      send: function() {
        this.loading = true
        apiV1LinkReport.post(this.dto).then(() => {
          this.loading = false
          this.sended = true
          this.dto.message = ''
        })
      }
    },
  }
</script>
<style scoped>
</style>