import http from "../axios";

let apiV1ClickDashboard = {
	baseUrl: '/api/v1/click-dashboards',
	get: async (requestParams) => {
		return await http.get(`${apiV1ClickDashboard.baseUrl}?
		${requestParams.onlineAddressId? `onlineAddressId=${requestParams.onlineAddressId}` : ''}
		${requestParams.period? `&period=${requestParams.period}` : ''}
		${requestParams.domain? `&domain=${requestParams.domain}` : ''}
		${requestParams.errorIn? `&errorIn=${requestParams.errorIn}` : ''}
		${requestParams.typeIn? `&typeIn=${requestParams.typeIn}` : ''}
		${requestParams.dateCreateIni? `&dateCreateIni=${requestParams.dateCreateIni}` : ''}
		${requestParams.dateCreateEnd? `&dateCreateEnd=${requestParams.dateCreateEnd}` : ''}
		${requestParams.withClicks? `&withClicks=${requestParams.withClicks}` : ''}
		${requestParams.withoutClicks? `&withoutClicks=${requestParams.withoutClicks}` : ''}
		${requestParams.clickDateRange? `&clickDateRange=${requestParams.clickDateRange}` : ''}
		${requestParams.text? `&text=${requestParams.text}` : ''}
		`);
	},
	getByDate: async (requestParams) => {
		return await http.get(`${apiV1ClickDashboard.baseUrl}/dates?
		${requestParams.onlineAddressId ? `onlineAddressId=${requestParams.onlineAddressId}` : ''}
		${requestParams.period ? `&period=${requestParams.period}` : ''}
		${requestParams.timeUnit ? `&timeUnit=${requestParams.timeUnit}` : ''}
		${`&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`}
		${requestParams.text? `&text=${requestParams.text}` : ''}
		${requestParams.domain? `&domain=${requestParams.domain}` : ''}
		${requestParams.errorIn? `&errorIn=${requestParams.errorIn}` : ''}
		${requestParams.typeIn? `&typeIn=${requestParams.typeIn}` : ''}
		${requestParams.dateCreateIni? `&dateCreateIni=${requestParams.dateCreateIni}` : ''}
		${requestParams.dateCreateEnd? `&dateCreateEnd=${requestParams.dateCreateEnd}` : ''}
		${requestParams.withClicks? `&withClicks=${requestParams.withClicks}` : ''}
		${requestParams.withoutClicks? `&withoutClicks=${requestParams.withoutClicks}` : ''}
		${requestParams.clickDateRange? `&clickDateRange=${requestParams.clickDateRange}` : ''}
		`);
	},
	getByPropertie: async (requestParams) => {
		return await http.get(`${apiV1ClickDashboard.baseUrl}/properties?
		${requestParams.onlineAddressId ? `onlineAddressId=${requestParams.onlineAddressId}` : ''}
		${requestParams.period ? `&period=${requestParams.period}` : ''}
		${requestParams.name ? `&name=${requestParams.name}` : ''}
		${requestParams.value ? `&value=${requestParams.value}` : ''}
		${requestParams.text? `&text=${requestParams.text}` : ''}
		${requestParams.domain? `&domain=${requestParams.domain}` : ''}
		${requestParams.errorIn? `&errorIn=${requestParams.errorIn}` : ''}
		${requestParams.typeIn? `&typeIn=${requestParams.typeIn}` : ''}
		${requestParams.dateCreateIni? `&dateCreateIni=${requestParams.dateCreateIni}` : ''}
		${requestParams.dateCreateEnd? `&dateCreateEnd=${requestParams.dateCreateEnd}` : ''}
		${requestParams.withClicks? `&withClicks=${requestParams.withClicks}` : ''}
		${requestParams.withoutClicks? `&withoutClicks=${requestParams.withoutClicks}` : ''}
		${requestParams.clickDateRange? `&clickDateRange=${requestParams.clickDateRange}` : ''}
		`);
	},
	getByRegion: async (requestParams) => {
		return await http.get(`${apiV1ClickDashboard.baseUrl}/regions?
		${requestParams.onlineAddressId ? `onlineAddressId=${requestParams.onlineAddressId}` : ''}
		${requestParams.period ? `&period=${requestParams.period}` : ''}
		${requestParams.text? `&text=${requestParams.text}` : ''}
		${requestParams.domain? `&domain=${requestParams.domain}` : ''}
		${requestParams.errorIn? `&errorIn=${requestParams.errorIn}` : ''}
		${requestParams.typeIn? `&typeIn=${requestParams.typeIn}` : ''}
		${requestParams.dateCreateIni? `&dateCreateIni=${requestParams.dateCreateIni}` : ''}
		${requestParams.dateCreateEnd? `&dateCreateEnd=${requestParams.dateCreateEnd}` : ''}
		${requestParams.withClicks? `&withClicks=${requestParams.withClicks}` : ''}
		${requestParams.withoutClicks? `&withoutClicks=${requestParams.withoutClicks}` : ''}
		${requestParams.clickDateRange? `&clickDateRange=${requestParams.clickDateRange}` : ''}
		`);
	},
	getByDomain: async (requestParams) => {
		return await http.get(`${apiV1ClickDashboard.baseUrl}/domains
		?page=${requestParams.page}
		&size=${requestParams.size}
		${requestParams.onlineAddressId ? `&onlineAddressId=${requestParams.onlineAddressId}` : ''}
		${requestParams.period ? `&period=${requestParams.period}` : ''}
		${requestParams.text? `&text=${requestParams.text}` : ''}
		${requestParams.domain? `&domain=${requestParams.domain}` : ''}
		${requestParams.errorIn? `&errorIn=${requestParams.errorIn}` : ''}
		${requestParams.typeIn? `&typeIn=${requestParams.typeIn}` : ''}
		${requestParams.dateCreateIni? `&dateCreateIni=${requestParams.dateCreateIni}` : ''}
		${requestParams.dateCreateEnd? `&dateCreateEnd=${requestParams.dateCreateEnd}` : ''}
		${requestParams.withClicks? `&withClicks=${requestParams.withClicks}` : ''}
		${requestParams.withoutClicks? `&withoutClicks=${requestParams.withoutClicks}` : ''}
		${requestParams.clickDateRange? `&clickDateRange=${requestParams.clickDateRange}` : ''}
		`);
	},
}

export  { apiV1ClickDashboard }