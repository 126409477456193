<template>
  <div v-if="data.address">
    <v-dialog v-model="data.dialog" width="600" persistent :fullscreen="['xs', 'sm'].includes($vuetify.breakpoint.name)">
      <v-card v-if="data.address" class="card-corner-radius" color="grey lighten-3">
        <v-row no-gutters>
          <v-col cols="12" sm="12">
            <v-card-title class="primary--text">
              <v-btn icon color="primary" @click="JSON.stringify(data.address) == JSON.stringify(aux) ? data.dialog = false : confimCancel.dialog = true">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <b>{{$t(`editLinkTags`)}}</b>
              <v-spacer/>
            </v-card-title>
            <v-card-subtitle class="primary--text mt-2">
              {{$t(`updateTagsInfo`)}}
            </v-card-subtitle>
            <v-card class="ma-4">
              <v-card-title>
                <b>{{ $t('tags') }}</b>
              </v-card-title>
              <v-card-text>
                <v-chip :readonly="loadingSave" close class="mr-1 mb-1" v-for="(tag, index) in data.address.tags.split(`||`)" :key="index" v-show="tag" @click:close="data.address.tags = data.address.tags.trim().replace(`, ${tag}`, ``).replace(tag, ``)">
                  <v-icon small class="mr-1">mdi-tag</v-icon>
                  {{tag}}
                </v-chip>
                <v-chip :readonly="loadingSave" outlined close close-icon="mdi-check">
                  <v-text-field 
                    v-model="tagAux"
                    flat
                    solo
                    color="transparent"
                    hide-details
                    :readonly="loadingSave"
                    @blur="data.address.tags = `${data.address.tags.trim()}||${tagAux.trim()}`, tagAux =''"
                    />
                </v-chip>
              </v-card-text>
            </v-card>
            
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="save" :loading="loadingSave" rounded>
            <span>{{$t(`save`)}}</span>
            <v-icon>mdi-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmOperation :data="confimCancel" :confimAction="() => {data.dialog = false, Object.assign(data.address, aux)}" />
  </div>
</template>
<script>
import { apiV1OnlineAddresses } from '../../../service/resources/api-v1-online-addresses';
import ConfirmOperation from './ConfirmOperation.vue';

  export default {
    name: 'LinkEditTags',
    props: ['data', 'screenData'],
    components: {
    ConfirmOperation
},
    data: () => ({
      confimCancel: {dialog: false},
      confimDelete: {dialog: false},
      loadingSave: false,
      host: null,
      formAddress: false,
      tagAux: null
    }),
    watch: {
      'data.dialog': function(value) {
        if(value)
          this.aux = JSON.parse(JSON.stringify(this.data.address))
        this.canUseCode = true
      },
    },
    mounted: function() {
      this.host = `${window.location.protocol}//${window.location.host.replace('www.', '')}`;

    },
    computed: {
    },
    methods: {
      save: function() {
        this.loadingSave = true
        apiV1OnlineAddresses.put(this.data.address).then(response => {
          if(response.data) {
            Object.assign(this.data.address, response.data) 
            this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('saved')})
            this.data.dialog = false
          }
        }).finally(() => {
          this.loadingSave = false
        })
      },
    },
  }
</script>
<style scoped>
</style>