<template>
  <v-card :width="width" :elevation="border">
    <div class="qrcode-migly" ref="container">
      <v-card class="rounded-lg" elevation="0" outlined style="border: 4px solid;">
        <v-img :src="base64"></v-img>
      </v-card>
      <!-- <vue-qr :text="`${value}?s=q`" logoSrc="/images/logo/logo-05.png" backgroundDimming="#fff" :margin='0' :callback="load" :logoCornerRadius="10"/> -->
      <div ref="qrcode" v-show="false"></div>
    </div>
    <v-card-actions v-if="enableControlls">
      <v-spacer/>
      <a download="migly-qrcode" :href="base64" class="link">
        <v-icon color="primary">mdi-download</v-icon>
      </a>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>
<script>
import QRCodeStyling from 'qr-code-styling';
  export default {
    name: 'QRCodeMigly',
    props: ['value', 'enableControlls', 'width', 'border', 'data', 'logo'],
    components: {
    },
    data: () => ({
      base64: null,
      qrCode: null,
    }),
    mounted: function() {
      this.qrCode = new QRCodeStyling({
        width: 500,
        height: 500,
        data: `${this.value}?s=q`, // URL ou texto que você deseja codificar
        // image: "/images/logo/logo-05.png", // Caminho para o logo da sua empresa
        dotsOptions: {
          color: "#000", // Cor dos pontos
          type: "rounded", // Define os pontos como redondos
        },
        cornersSquareOptions: {
          color: "#000", // Cor dos cantos
          type: "extra-rounded", // Deixa os cantos ainda mais arredondados
        },
        backgroundOptions: {
          color: "#ffffff", // Cor de fundo
        },
        imageOptions: {
          crossOrigin: "anonymous",
          // margin: 20, // Margem ao redor do logo
        },
      });

      this.qrCode.append(this.$refs.qrcode);

      // Adiciona um círculo no meio do QR Code
      this.addCircleToCenter();

      
    },
    computed: {

    },
    methods: {
      load: function (data) {
         this.src = data
         if(this.data)
          this.data.base64 = data
      },
      getQRCodeBase64() {
        const canvas = this.$refs.qrcode.querySelector("canvas");
        if (canvas) {
          this.base64 = canvas.toDataURL("image/png"); // Obtém o Base64 do canvas
        }
      },
      addCircleToCenter() {
        // Usa um pequeno timeout para garantir que o QR Code foi renderizado antes de desenhar o círculo
        setTimeout(() => {
          const canvas = this.$refs.qrcode.querySelector("canvas");
          if (canvas) {
            const ctx = canvas.getContext("2d");


            
            // Define as propriedades do círculo
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
            const radius = canvas.width / 8; // Tamanho do círculo (10% do tamanho do QR Code)

            // Desenha o círculo
            ctx.beginPath();
            ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
            ctx.fillStyle = "#000"; // Cor de preenchimento do círculo (ajuste conforme necessário)
            ctx.fill();

            // Adiciona uma borda no círculo (opcional)
            ctx.lineWidth = 6;
            ctx.strokeStyle = "#FFF"; // Cor da borda do círculo
            ctx.stroke();

            // Carrega a logo da URL
            const logo = new Image();
            logo.src = this.logo ? this.logo : '/images/logo/logo-02.png' //this.logoUrl;
            logo.crossOrigin = "anonymous"; // Para evitar problemas de CORS
            logo.onload = () => {
              const logoSize = radius * 2; // Tamanho do logo
              const startX = centerX - logoSize / 2; // Posição X para centralizar
              const startY = centerY - logoSize / 2; // Posição Y para centralizar
              
              // Preenche o círculo
              ctx.beginPath();
              ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
              ctx.closePath();
              ctx.clip(); // Faz o canvas só desenhar dentro do círculo

              // Desenha a imagem no círculo
              ctx.drawImage(logo, startX, startY, logoSize, logoSize);
              
              // Restaura o canvas para permitir desenhar fora do círculo
              ctx.restore();
            };
            logo.onerror = () => {
              console.error("Erro ao carregar a imagem da logo.");
            };
          }
          setTimeout(() => {
            this.getQRCodeBase64()
          }, 100);
        }, 500); // Timeout para garantir que o QR code foi renderizado
      }
    },
  }
</script>
<style scoped>
.qrcode-migly img {
  width: 100% !important;
}
</style>