<template>
  <v-container class="pt-0 pb-0 mt-0 mb-0">
      <v-card-title class="primary--text pr-4 pl-4">
        <b>{{ $t('userSignTermsAndPrivacyPolicy') }}</b>
        <v-spacer/>
      </v-card-title>
      <v-card-text>
        <v-card outlined elevation="0">
          <v-card-text id="terms-of-use" v-if="termsOfUse" v-html="$t('termsOfUseHtml')[termsOfUse.name].replace('DATEUPDATE', new Date(`${termsOfUse.dateUpdate.replace('[UTC]', '')}`).toLocaleString())"></v-card-text>
          <v-card-text id="privacy-policy" v-if="privacyPolicy" v-html="$t('privacyPolicyHtml')[privacyPolicy.name].replace('DATEUPDATE', new Date(`${privacyPolicy.dateUpdate.replace('[UTC]', '')}`).toLocaleString())"></v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        Exatamente Soluções - CNPJ - 41.228.429/0001-18.
        <v-spacer/>
      </v-card-actions>
  </v-container>
</template>
<script>
import { apiV1TermsOfUse } from '../../service/resources/api-v1-terms-of-use'
import { apiV1PrivacyPolicy } from '../../service/resources/api-v1-privacy-policy'
  export default {
    name: 'TermsAndPrivacyPolicy',
    props: [],
    components: {
    },
    data: () => ({
      termsOfUse: null,
      privacyPolicy: null,
    }),
    watch: {
    },
    mounted: function() {
      this.getTerms()
      this.getPolicy()
    },
    computed: {
    },
    methods: {
      getTerms: function() {
        apiV1TermsOfUse.page({page: 0, size: 1, orderBy: 'id:DESC'}).then(result => {
          if(result.data.content[0])
            this.termsOfUse = result.data.content[0]
        })
      },
      getPolicy: function() {
        apiV1PrivacyPolicy.page({page: 0, size: 1, orderBy: 'id:DESC'}).then(result => {
          if(result.data.content[0])
            this.privacyPolicy = result.data.content[0]
        })
      },
      save: function() {
      }
    },
  }
</script>
<style scoped>
</style>