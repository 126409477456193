<template>
  <v-card elevation="0">
    <v-btn elevation="0" color="transparent"/>
    <v-card elevation="0" color="primary" class="ma-4 pa-4" dark>
      <v-card-title>
        <v-spacer/>
        <h1>{{ data.title }}</h1>
        <v-spacer/>
      </v-card-title>
      <v-card-title>
        <v-spacer/>
        {{ data.subtitle }}
        <v-spacer/>
      </v-card-title>
      <v-footer color="transparent" v-if="true">
        <v-spacer/>
        <v-avatar color="white" class="ma-4">
          <v-img src="/images/woman-home.png" alt="migly" /> 
        </v-avatar>
        <v-spacer/>
      </v-footer>
    </v-card>
    <v-container>
      <v-row v-for="item, index in data.news" :key="index">
        <v-col cols="12" xs="12" sm="5" v-if="index % 2 && !['xs'].includes($vuetify.breakpoint.name)">
          <div class="verticle-center">
            <v-card elevation="0" class="rounded-lg" outlined>
              <v-img :src="item.img" height="100%"/>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="7">
          <div class="verticle-center">
            <v-card elevation="0">
              <v-card-actions class="ml-2">
                <v-card class="pa-1 rounded-lg" elevation="0" outlined color="primary" dark>
                  <b>{{ $t(item.type) }}</b>
                </v-card>
              </v-card-actions>
              <v-card-title>
                <b>{{ $t(item.title) }}</b>
              </v-card-title>
              <v-card-subtitle v-html="item.text">
              </v-card-subtitle>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="5" v-if="!(index % 2) || ['xs'].includes($vuetify.breakpoint.name)">
          <div class="verticle-center">
            <v-card elevation="0" class="rounded-lg " outlined>
              <v-img :src="item.img"/>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-card height="100" elevation="0"/>
      <v-card-title >
        <v-spacer/>
        <h1>{{ $t('otherNews') }}</h1>
        <v-spacer/>
      </v-card-title>
      <v-card height="100" elevation="0"/>
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="4" v-for="item, index in data.otherNews" :key="index">
          <v-card elevation="0" color="primary" outlined dark>
            <v-card-title>
                <b>{{ $t(item.title) }}</b>
              </v-card-title>
              <v-card-subtitle>
                {{ item.text }}
              </v-card-subtitle>
              <v-card-actions class="mt-0 pt-0 mb-0 pb-0 ml-2 mr-2">
                <a @click="$router.push(item.src)" class="link white--text">{{ $t('accessPainel') }}</a>
                <v-icon color="white" small>mdi-arrow-top-right-thin</v-icon>
              </v-card-actions>
              <v-card-text>
                <v-card elevation="0">
                  <v-img :src="item.img" height="200"/>
                </v-card>
              </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  export default {
    name: 'WhatsNew',
    props: [],
    components: {
    },
    data: () => ({
      data: null
    }),
    mounted: function() {
      this.data = this.$t('newsMigly')
    },
    computed: {

    },
    methods: {
      login: function() {
        return `${process.env.VUE_APP_API_V1_URL}/oauth2/authorization/google`
      },
    },
  }
</script>
<style scoped>
</style>