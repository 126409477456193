<template>
  <v-app>
    <v-app-bar
      fixed
      color="white"
      elevation="0"
      style="z-index: 9;"
    >
      <v-container>
        <v-card-actions>
          <a href="/lp" class="link">
            <v-img src="/images/logo/logo-04.png" alt="migly" width="100"/> 
          </a>
          <v-spacer/>
          <v-btn text class="text-none">
            <a href="https://migly.in/#sec-3" class="link">
              {{ $t('pricing') }}
            </a>
          </v-btn>
          <v-divider vertical/>
          <v-btn text class="text-none">
            <a href="https://api.migly.in/oauth2/authorization/google" class="link">
              {{ $t('login') }}
            </a>
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-app-bar>
    <v-sheet>
      <v-app-bar elevation="0">
        <v-card-actions>
          <v-btn />
        </v-card-actions>
      </v-app-bar>
      <v-card class="hero-section" elevation="0" color="transparent">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="7">
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="5">
            <v-img src="https://wac-cdn.atlassian.com/misc-assets/webp-images/paid-lp-img-jsw/JSM_BG_Graphics.webp">
            </v-img>
          </v-col>
        </v-row>
      </v-card>
      <v-container class="verticle-center">
        <v-card color="transparent" elevation="0">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="5">
              <h1 class="mt-4 mb-4 pt-4 pb-4">{{ data.hero.headline }}</h1>
              <h5 class="mt-4 mb-4 pt-4 pb-4">{{ data.hero.subheadline }}</h5>
  
              <v-btn class="text-none mt-4 mb-4 pt-4 pb-4" block color="primary">
                <a href="https://migly.in" class="link white--text">
                  {{ data.hero.ctaButton }}
                </a>
              </v-btn>
  
              <v-card-actions>
                <v-divider/>
                <label class="ma-2">{{ $t('orCreateAccount') }}</label>
                <v-divider/>
              </v-card-actions>
  
              <v-btn class="text-none mt-4 mb-4 pt-4 pb-4" block outlined color="primary">
                <v-icon>mdi-google</v-icon>
                <a href="https://api.migly.in/oauth2/authorization/google" class="link ma-2">
                  {{ $t('withGoogle') }}
                </a>
              </v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="7">
              <v-card outlined elevation="0" class="ma-4 pa-2">
                <v-carousel :show-arrows="false" hide-delimiter-background cycle>
                  <v-carousel-item
                  v-for="(item,i) in [
                    {src: '/images/lp1/redirect.png'},
                    {src: '/images/lp1/painel.png'},
                    {src: '/images/lp1/config.png'},
                    {src: '/images/lp1/opengraph.png'},
                    {src: '/images/lp1/bio.png'},
                  ]"
                    :key="i"
                    :src="item.src"
                  ></v-carousel-item>
                </v-carousel>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
      <v-card-actions class="ma-4 pa-4">
        <v-spacer/>
        <h2>{{ data.benefits.title }}</h2>
        <v-spacer/>
      </v-card-actions>
      <v-card elevation="0">
        <v-container>
          <v-tabs 
            class="mt-5" 
            hide-slider 
            v-model="benefitTab" 
            style="border-radius: 25px"
            grow
            >
            <v-tab v-for="item in data.benefits.items" 
              :key="item.title" 
              :tab-value="item.title" 
              class="text-none"
              >
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </v-container>
      </v-card>
    </v-sheet>  
  </v-app>
</template>

<script>
  export default {
    name: 'LPMarketingAfiliados',
    props: [],
    components: {
    },
    data: () => ({
      data: null,
      benefitTab: null,
    }),
    mounted: function() {
      this.data = this.$t('lPMarketingAfiliadosLinks')
    },
    computed: {

    },
    methods: {
      login: function() {
        return `${process.env.VUE_APP_API_V1_URL}/oauth2/authorization/google`
      },
    },
  }
</script>
<style scoped>
.v-toolbar .v-toolbar--absolute{
  z-index: unset !important;
}
.hero-section {
  position: absolute; 
  z-index: 0; 
  width: 100%;
  background: rgb(255,255,255);
  background: linear-gradient(69deg, rgba(255,255,255,0) 0%, rgba(133,184,255,1) 50%, rgba(255,255,255,0) 100%);
}
</style>