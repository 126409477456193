import http from "../axios";

let apiV1SmsShootingHasOnlineAddresses = {
	baseUrl: '/api/v1/sms-shootings-has-online-addresses',
	page: async (filter) => {
		return await http.get(`${apiV1SmsShootingHasOnlineAddresses.baseUrl}
		?page=${filter.page}
		&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.smsShootingId ? `&smsShootingId=${filter.smsShootingId}` : ''}
		`);
	}
}

export  { apiV1SmsShootingHasOnlineAddresses }