<template>
  <div v-if="data.shooting">
    <v-dialog v-model="data.dialog" width="900" persistent :fullscreen="['xs'].includes($vuetify.breakpoint.name)">
      <v-card class="card-corner-radius" color="grey lighten-3" v-if="data.shooting">
        <v-card-title class="primary--text pr-4 pl-4">
          <v-btn icon color="primary" @click="data.dialog = false" :readonly="loadingSave">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <b  v-if="!data.shooting.id">{{ $t('sendNew') }}</b>
          <b  v-else>{{ $t('shootingEdit') }}</b>
          <v-spacer/>
          <v-btn text color="primary" outlined v-if="data.shooting.id" @click="deleteConfirm.dialog = true" :disabled="data.shooting && data.shooting.status == 'SENT'">
            {{ $t('delete') }}
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-title>
        <v-form v-model="smsNewSendForm" :lazy-validation="false" ref="formSMS" :disabled="loadingShotnerURL || loadingRecipients || loadingSave ||data.shooting.status == 'SENT'">
          <div v-if="data.shooting">
            <v-card-title class="primary--text">
              <b>{{ $t('campaign') }}</b>
            </v-card-title>
            <v-card-subtitle class="primary--text">
              {{ $t('campaignDesc') }}
            </v-card-subtitle>
            <v-card-text class="mt-0 pt-0">
              <v-card elevation="0" color="white">
                <v-card-text>
                  <v-text-field
                    v-model="data.shooting.title"
                    class="mt-1"
                    outlined
                    rounded
                    dense
                    flat
                    counter="30"
                    max="30"
                    :maxlength="30"
                    :placeholder="$t('title')"
                    :label="$t('title')"
                    :rules="[
                      v => !!v || $t('required'),
                      v => !!v && v.length < 31 || $t('invalidSize'),
                    ]"
                  />
                </v-card-text>
                <v-card-text>
                  <v-textarea
                    v-model="data.shooting.description"
                    class="mt-1"
                    outlined
                    rounded
                    dense
                    flat
                    counter="450"
                    max="450"
                    :maxlength="450"
                    :placeholder="$t('description')"
                    :label="$t('description')"
                    :rules="[
                    ]"
                    no-resize
                    rows="3"
                  />
                </v-card-text>
                <v-card-title class="primary--text">
                  {{ $t('tags') }}
                </v-card-title>
                <v-card-text>
                  <v-chip outlined close color="primary" class="mr-1 mb-1" v-for="(tag, index) in data.shooting.tags.split(`||`)" :key="index + '-dialog-shooting-tag'" v-show="tag" @click:close="data.shooting.tags = data.shooting.tags.trim().replace(`, ${tag}`, ``).replace(tag, ``)">
                    <v-icon class="mr-1">mdi-tag</v-icon>
                    {{tag}}
                  </v-chip>
                  <v-chip outlined close close-icon="mdi-check">
                    <v-text-field 
                      v-model="tagAux"
                      flat
                      solo
                      color="transparent"
                      hide-details
                      max="30"
                      :maxlength="30"
                      @blur="data.shooting.tags = `${data.shooting.tags.trim()}||${tagAux.trim()}`, tagAux =''"
                      />
                  </v-chip>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-title class="primary--text">
              <b>{{ $t('shooting') }}</b>
            </v-card-title>
            <v-card-subtitle class="primary--text">
              {{ $t('shootingDesc') }}
            </v-card-subtitle>
            <v-card-text class="mt-0 pt-0">
              <v-card elevation="0" color="white">
                <v-card-text>
                  <v-select
                    rounded
                    v-model="data.shooting.type"
                    :items="typesOfMessage"
                    :label="$t('typeOfMessage')"
                    outlined
                    :item-text="item => $t(item.name)"
                    :item-value="item => item.id"
                    required
                    persistent-hint
                    :hint="data.shooting.typeOfMessage ? $t(`typeOfMessage_${data.shooting.typeOfMessage.name}_desc`) : ''"
                    :rules="[
                            v => !!v || $t('required')
                          ]"
                  />
                  <v-autocomplete
                    v-if="data.shooting.type == 'DYNAMICS'"
                    v-model="data.shooting.variables"
                    flat
                    dense
                    :label="$t('properties')"
                    :placeholder="$t('properties')"
                    rounded
                    outlined
                    hide-details
                    :search-input.sync="findProperties"
                    :items="propertyPage.content"
                    :item-text="v => v"  
                    no-filter
                    multiple
                    small-chips
                    chips
                    clearable
                    @change="data.shooting.template = data.shooting.template.replace(/{[^}]*}/g,''), data.shooting.variables.forEach(v => data.shooting.template = `${data.shooting.template.trim()} {${v}}`)"
                    @blur="searchContactsProperties(null)"
                    >
                    <template v-slot:append>
                      <v-slide-x-transition leave-absolute hide-on-leave>
                        <v-progress-circular x-small  indeterminate width="1" size="22" v-if="loadingProperties" color="blue"/>
                      </v-slide-x-transition>
                    </template>
                    <template v-slot:selection="d">
                      <v-chip
                        v-bind="d.attrs"
                        :input-value="d.selected"
                        close
                        @click="d.select"
                        small
                        :color="data.shooting.template.includes(`{${d.item}}`) ? 'blue' : 'red'"
                        outlined
                        @click:close="data.shooting.variables = data.shooting.variables.filter(v => v != d.item),data.shooting.template = data.shooting.template.replace(`{${d.item}}`,'')"
                      >
                        <v-icon>mdi-variable-box</v-icon>
                        {{ d.item }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-card-text>
                <v-card-text>
                  <v-textarea
                    :counter="data.shooting.type == 'SIMPLE' ? 160 - smsConfig.business.length - $t('textOptOut').length - 2 : null"
                    rounded
                    :label="$t('templateSMS')"
                    :rules="[
                      v => !!v || $t('required'),
                      v => (countTotalCaracters() <= 160 || (data.shooting.type == 'DYNAMICS')) || '+160',
                      v => (data.shooting.type == 'SIMPLE' || (!data.shooting.variables || !data.shooting.variables.length || data.shooting.variables.filter(variable => v.includes(`{${variable}}`)).length == data.shooting.variables.length)) || $t('variableDefinedUnsed'),
                      ]"
                    v-model="data.shooting.template"
                    :max="data.shooting.type == 'SIMPLE' ? 160 - smsConfig.business.length - $t('textOptOut').length - 2 : null"
                    outlined
                    required
                    no-resize
                    rows="3"
                    @blur="data.shooting.shortenUrls ? shortenLinks() : ''"
                    @change="data.shooting.smsShootingHasContacts.concat(shootingHasContacsPage.content).forEach(recipient => replaceText(recipient));data.shooting.template = data.shooting.template.normalize('NFD').replace(/[\u0300-\u036f]/g, '')"
                  />
                  <v-fade-transition>
                    <v-card outlined elevation="0" v-if="data.shooting.template" class="mb-2">
                      <v-card-actions class="text-overline mb-0 pb-0">
                        {{ $t('SMS') }}
                      </v-card-actions>
                      <v-card-text>
                        {{getTemplate()}}
                      </v-card-text>
                      
                      <v-card-actions v-if="data.shooting.type == 'SIMPLE'">
                        <v-spacer/>
                        <v-icon color="warning" small class="mr-1" v-if="countTotalCaracters() > 160">mdi-alert-circle</v-icon>
                        {{ countTotalCaracters() }} / 160
                      </v-card-actions>
                      <v-card-text v-else>
                        <v-alert type="warning" >
                          {{ $t('dynamicMessageAlert') }}
                          <v-divider v-if="shootingHasContacsPage.content[0]"/>
                          <v-card-actions v-if="shootingHasContacsPage.content[0]">
                            <v-spacer/>
                            <v-icon v-if="replaceText(shootingHasContacsPage.content[0].contact).length > 160">mdi-alert</v-icon>
                            {{ replaceText(shootingHasContacsPage.content[0].contact).length }} / 160
                          </v-card-actions>
                        </v-alert>
                      </v-card-text>
                    </v-card>
                  </v-fade-transition>
                  <v-progress-linear
                      color="primary"
                      indeterminate
                      rounded
                      height="3"
                      v-if="loadingShotnerURL"
                    />
                  <v-switch v-model="data.shooting.shortenUrls" :label="$t('shortenLinks')" @change="data.shooting.shortenUrls ? shortenLinks() : '';data.shooting.smsShootingHasContacts.concat(shootingHasContacsPage.content).forEach(recipient => replaceText(recipient))"/>
                  <v-fade-transition>
                    <v-alert type="info" outlined v-if="data.shooting.shortenUrls">{{ $t('smsInfoLinks') }}</v-alert>
                  </v-fade-transition>
                </v-card-text>
                <div v-if="data.shooting.smsShootingHasOnlineAddresses.length || shootingHasOnlineAddressPage.content.length">
                  <v-divider/>
                  <v-card-subtitle class="primary--text">
                    {{ $t('links') }}
                  </v-card-subtitle>
                  <v-card-text>
                    <div v-if="loadingShootingHasOnlineAddresses">
                      <v-skeleton-loader type="chip"/>
                    </div>
                    <v-chip-group column v-else>
                      <v-card outlined v-for="(link, index) in data.shooting.smsShootingHasOnlineAddresses.concat(shootingHasOnlineAddressPage.content)" :key="index" class="card-corner-radius mb-2 mr-1" elevation="0" v-show="!link.deleted" :max-width="['xs', 'sm'].includes($vuetify.breakpoint.name) ? `100%` : `49%`">
                        <v-list-item three-line>
                          <v-list-item-avatar
                            tile
                            size="50"
                          >
                            <template v-if="link.onlineAddress.openGraph && link.onlineAddress.openGraph.openGraphFields && link.onlineAddress.openGraph.openGraphFields.filter(f => f.name == ('og:image'))[0]">
                              <v-img lazy-src="/images/logo/logo-02.png" v-for="(field) in link.onlineAddress.openGraph.openGraphFields.filter(f => f.name == ('og:image'))" :key="field.id" :src="field.value" position="center center" contain style="border-radius: 10px 0px 0px 10px;" aspect-ratio="1"/>
                            </template>
                            <v-img v-else src="/images/logo/logo-02.png" contain/>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-card-title class="text-overline pa-0">
                              <small><a :href="getShortLink(link)" target="_blank">{{ getShortLink(link) }}</a></small>
                            </v-card-title>
                            <v-list-item-title>
                              <a :href="`${getShortLink(link)}?countClick=false`" target="_blank" class="link">
                                {{ link.onlineAddress.url }}
                              </a>
                              <v-spacer/>
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="!verifyUsingLink(link)" class="red--text">
                              {{ $t('unusedLinkInMessage') }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-divider vertical class="mt-4 mb-4" v-if="!verifyUsingLink(link)"/>
                          <v-list-item-action v-if="!verifyUsingLink(link)" >
                            <v-btn icon color="red" @click="data.shooting.template = data.shooting.template.replace(getShortLink(link), ''); link.deleted = true; !data.shooting.smsShootingHasOnlineAddresses.includes(link) ? data.shooting.smsShootingHasOnlineAddresses.push(link) : null;data.shooting.smsShootingHasContacts.concat(shootingHasContacsPage.content).forEach(recipient => replaceText(recipient))">
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                            <v-btn icon color="blue" @click="data.shooting.template = `${data.shooting.template} ${getShortLink(link)}`;data.shooting.smsShootingHasContacts.concat(shootingHasContacsPage.content).forEach(recipient => replaceText(recipient))">
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-card>
                    </v-chip-group>
                  </v-card-text>
                  <v-divider/>
                </div>
                <v-card-title class="pt-0 pb-0">
                  <v-switch v-model="data.shooting.scheduled" :label="$t('scheduledShooting')"/>
                  <v-spacer/>
                  <!-- <v-select
                      v-if="data.shooting.scheduled"
                      class="mt-4"
                      flat
                      rounded
                      v-model="data.shooting.timeZone"
                      :items="Intl.supportedValuesOf('timeZone')"
                      :label="$t('timeZone')"
                      outlined
                      dense
                      required
                      persistent-hint
                      :rules="[
                        v => !!v || $t('required')
                      ]"
                    /> -->
                </v-card-title>
                <div v-if="data.shooting.scheduled">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-date-picker
                          dense
                          flat
                          v-model="data.shooting.date"
                          full-width
                          :show-current="true"
                          :min="`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`"
                          :locale="locale"
                          @change="data.shooting.dateTimeScheduled = new Date(`${data.shooting.date} ${data.shooting.time}`).toISOString(), $forceUpdate()"
                        />
                      </v-col>
                      <v-text-field
                        v-show="false"
                        v-model="data.shooting.date"
                        :rules="[
                          v => !!v || $t('required'),
                          ]"
                      />
                      <v-col cols="12" sm="6">
                        <v-time-picker
                          v-model="data.shooting.time"
                          ampm-in-title
                          full-width
                          :scrollable="false"
                          @change="data.shooting.dateTimeScheduled = new Date(`${data.shooting.date} ${data.shooting.time}`).toISOString(),$forceUpdate()"
                          format="24h"
                          :min="data.shooting.date == `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}` ? `${String(new Date().getHours()).padStart(2, '0')}:${String(new Date().getMinutes()).padStart(2, '0')}`: null"
                        />
                        <v-text-field
                          v-show="false"
                          v-model="data.shooting.time"
                          :rules="[
                            v => !!v || $t('required'),
                            ]"
                          />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions v-if="data.shooting.date && data.shooting.time">
                    {{ data.shooting.date }} {{ data.shooting.time }}
                    <v-spacer/>
                    {{ new Date(`${data.shooting.date} ${data.shooting.time}`).toLocaleString() }} {{ Intl.DateTimeFormat().resolvedOptions().timeZone }}
                  </v-card-actions>
                </div>
              </v-card>
            </v-card-text>
            <v-card-title class="primary--text">
              <b>{{ $t('recipients') }}</b>
              <v-spacer/>
              <v-text-field
                v-model="contactNumberStartsWith"
                class="mt-1"
                outlined
                rounded
                dense
                flat
                hide-details
                hide-actions
                :placeholder="$t('number')"
                :label="$t('findNumber')"
                clearable
                @keyup="searchHasContacts(0)"
                @change="searchHasContacts(0)"
              />
            </v-card-title>
            <v-card-text v-if="data.shooting.smsShootingHasContacts.filter(v => !v.deleted).length || (data.shooting.addFilterFindRecipients &&  data.shooting.addFilterFindRecipients.length)">
              <v-card elevation="0" outlined>
                <v-card-subtitle>
                  {{ $t('newContacts') }}
                </v-card-subtitle>
                <v-card-text max-height="1000" style="overflow: auto;" class="pt-0 pb-0">
                  <v-row class="mt-0 pt-0">
                    <v-col v-for="hasContacts, index in data.shooting.smsShootingHasContacts.filter(v => !v.deleted).filter(v => v.contact.number.startsWith(contactNumberStartsWith))" :key="index + '-a'" cols="12" sm="6" md="4">
                      <v-card elevation="0" outlined>
                        <v-card-title class="primary--text">
                          <b>+{{ hasContacts.contact.number }}</b>
                          <v-spacer/>
                          <v-btn color="red" icon @click="data.shooting.smsShootingHasContacts = data.shooting.smsShootingHasContacts.filter(v => v != hasContacts)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text v-if="data.shooting.type == 'DYNAMICS'">
                          <v-card outlined elevation="0">
                            <v-card-actions>SMS</v-card-actions>
                            <v-card-subtitle>
                              {{ replaceText(hasContacts.contact) }}
                            </v-card-subtitle>
                            <v-card-actions>
                              <v-spacer/>
                              <v-icon color="red" v-if="replaceText(hasContacts.contact).length > 160">mdi-alert</v-icon>
                              {{ replaceText(hasContacts.contact).length }} / 160
                            </v-card-actions>
                          </v-card>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col v-for="filter, index in data.shooting.addFilterFindRecipients" :key="index + '-b'" cols="12" sm="6" md="4">
                      <v-card elevation="0" outlined>
                        <v-card-title class="text-overline mb-0 pb-0">
                          {{ $t('everyoneThat') }}
                        </v-card-title>
                        <v-card-subtitle class="primary--text mt-0 pt-0 pb-0 mb-0">
                          <b v-if="!filter.segments.length && !filter.tags.length && !filter.properties.length">{{ $t(filter.status) }}</b>
                          <b v-else>{{ $t('allInFilter') }}</b>
                          <v-spacer/>
                        </v-card-subtitle>
                        <v-card-subtitle class="pb-0 mb-0 pt-0 mt-0" style="overflow: auto; max-height: 10vh;" v-if="filter.segments.length">
                          <v-chip-group column>
                            <v-tooltip v-if="filter.segmentsExclusive" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="green">mdi-set-center</v-icon>
                              </template>
                              <span>{{$t('exclusiveFilter')}}</span>
                            </v-tooltip>
                            <v-tooltip v-else bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="green">mdi-set-all</v-icon>
                              </template>
                              <span>{{$t('inclusiveFilter')}}</span>
                            </v-tooltip>
                            <v-chip v-for="segment, index in filter.segments" :key="index" x-small color="green" outlined>
                              <v-icon class="mr-1" x-small>mdi-segment</v-icon>
                              {{ segment.name }}
                            </v-chip>
                          </v-chip-group>
                        </v-card-subtitle>
                        <v-card-subtitle class="pb-0 mb-0 pt-0 mt-0" style="overflow: auto; max-height: 7vh;" v-if="filter.tags.length">
                          <v-chip-group row>
                            <v-tooltip v-if="filter.tagsExclusive" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="blue">mdi-set-center</v-icon>
                              </template>
                              <span>{{$t('exclusiveFilter')}}</span>
                            </v-tooltip>
                            <v-tooltip v-else bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="blue">mdi-set-all</v-icon>
                              </template>
                              <span>{{$t('inclusiveFilter')}}</span>
                            </v-tooltip>
                            <v-chip v-for="tag, index in filter.tags" :key="index" x-small color="blue" outlined>
                              <v-icon class="mr-1" x-small>mdi-tag</v-icon>
                              {{ tag }}
                            </v-chip>
                          </v-chip-group>
                        </v-card-subtitle>
                        <v-card-text class="pb-0 mb-0 pt-0 mt-0" style="overflow: auto; max-height: 10vh;" v-if="filter.properties.length">
                          <v-chip-group column>
                            <v-tooltip v-if="filter.propertiesExclusive" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="blue">mdi-set-center</v-icon>
                              </template>
                              <span>{{$t('exclusiveFilter')}}</span>
                            </v-tooltip>
                            <v-tooltip v-else bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="blue">mdi-set-all</v-icon>
                              </template>
                              <span>{{$t('inclusiveFilter')}}</span>
                            </v-tooltip>
                            <v-chip v-for="p, index in filter.properties" :key="index" color="blue" dark x-small>
                              <v-icon class="mr-1" x-small>mdi-variable</v-icon>
                              {{ p }}
                            </v-chip>
                          </v-chip-group>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                          <i>{{ filter.recipients }} {{ $t('recipients') }}</i>
                          <v-spacer/>
                          <v-btn color="red" icon @click="data.shooting.addFilterFindRecipients = data.shooting.addFilterFindRecipients.filter(v => v!=filter),$forceUpdate()">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-fade-transition>
              <v-card-text v-if="loadingShootingHasContacs">
                <v-row>
                  <v-col v-for="i in 3" :key="i" cols="12" sm="6" md="4">
                    <v-skeleton-loader type="list-item-three-line" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else-if="!shootingHasContacsPage.empty" class="mt-0" max-height="500" style="overflow: auto;">
                <v-card elevation="0">
                  <v-card-actions  class="pl-0 pr-0 ml-1 mr-1">
                    <v-btn class="ml-1 primary--text" x-small color="white" rounded light elevation="0" disabled>
                      {{shootingHasContacsPage.totalElements}}
                    </v-btn>
                    <v-spacer/>
                    <v-chip-group show-arrows>
                      <v-btn class="ml-1 primary--text" x-small :disabled="(page -1) == shootingHasContacsPage.number" color="white" rounded light v-for="page in shootingHasContacsPage.totalPages" :key="page" @click="searchHasContacts(page -1)">
                        <v-icon x-small v-if="(page -1) == shootingHasContacsPage.number">mdi-circle-medium</v-icon>
                        {{$t('page')}} {{page}}
                      </v-btn>
                    </v-chip-group>
                  </v-card-actions>
                </v-card>
                <v-row class="mt-1">
                  <v-col v-for="hasContacts, index in shootingHasContacsPage.content" :key="index" cols="12" sm="6" md="4">
                    <v-card elevation="0" outlined :disabled="data.shooting.status == 'SENT'">
                      <v-card-title class="primary--text">
                        <b>+{{ hasContacts.contact.number }}</b>
                        <v-spacer/>
                        <v-btn color="red" icon @click="hasContacts.deleted = true, data.shooting.smsShootingHasContacts.push(hasContacts), shootingHasContacsPage.content = shootingHasContacsPage.content.filter(v => v != hasContacts)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text v-if="data.shooting.type == 'DYNAMICS'">
                        <v-card outlined elevation="0">
                          <v-card-actions>SMS</v-card-actions>
                          <v-card-subtitle>
                            {{ replaceText(hasContacts.contact) }}
                          </v-card-subtitle>
                          <v-card-actions>
                            <v-spacer/>
                            <v-icon color="red" v-if="replaceText(hasContacts.contact).length > 160">mdi-alert</v-icon>
                            {{ replaceText(hasContacts.contact).length }} / 160
                          </v-card-actions>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-fade-transition>
            <v-card-actions>
              <v-spacer/>
              <v-btn rounded color="blue" @click="dialogFindRecipients = true" dark :disabled="data.shooting.status == 'SENT'">
                <v-icon class="mr-2">mdi-plus</v-icon>
                {{ $t('addRecipients') }}
              </v-btn>
            </v-card-actions>
            <v-card-text>
              <v-alert type="error" v-if="repeatNumbers()" outlined>
                {{ $t('exitsRepeatNumber') }}
                <v-card-actions class="pa-0">
                  <v-spacer/>
                  <v-btn outlined color="red" @click="excludeRepeatNumbers()">
                    {{ $t('excludeRepeatNumber') }}
                  </v-btn>
                </v-card-actions>
              </v-alert>
              <!-- <v-alert type="error" v-if="invalidNumbers()" outlined>
                {{ $t('exitsIvalidNumber') }}
              </v-alert> -->
              <!-- <v-alert type="error" v-if="invalidText()" outlined>
                {{ $t('textLimitSms') }}
              </v-alert> -->
            </v-card-text>
            <v-card-actions>
              <v-btn v-if="data.shooting.id && !data.shooting.scheduled && data.shooting.recipients" rounded color="primary" :disabled="enableSave() || data.shooting.status == 'SENT'" @click="saveShooting(true)" :loading="loadingShotnerURL || loadingRecipients || loadingSave || loadingSendShooting">
                <v-icon class="mr-1">mdi-email-fast-outline</v-icon>
                {{ $t('toSendFor') }} {{ data.shooting.recipients}} {{ $t('recipients_a') }}
              </v-btn>
              <v-spacer/>
              <v-btn rounded color="primary" @click="saveShooting(false)" :loading="loadingShotnerURL || loadingRecipients || loadingSave" :disabled="enableSave() || data.shooting.status == 'SENT'">
                <div v-if="data.shooting.scheduled && data.shooting.dateTimeScheduled">
                  <v-icon>mdi-clock-time-eight-outline</v-icon>
                  {{ $t('scheduleShootingFor') }} 
                  {{ new Date(`${data.shooting.dateTimeScheduled.split('Z')[0]}Z`).toLocaleString() }}
                </div>
                <div v-else>
                  {{ $t('save') }}
                </div>
              </v-btn>
            </v-card-actions>
          </div>
        </v-form>
        <v-overlay v-model="loadingSendShooting"/>
        <v-overlay v-model="loadingSave"/>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFindRecipients" width="800" :fullscreen="['xs'].includes($vuetify.breakpoint.name)">
      <v-card class="card-corner-radius" color="grey lighten-3">
        <v-form v-model="formDialogFindRecipients" ref="formDialogFindRecipients">
          <v-card-title class="primary--text pr-4 pl-4">
            <v-btn icon color="primary" @click="dialogFindRecipients = false">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <b>{{ $t('contacts') }}</b>
            <v-spacer/>
            <v-menu offset-y right :close-on-content-click="false" >
              <template v-slot:activator="{ on, attrs }">
                <v-badge overlap bordered left color="red" :value="Object.keys(filterFindRecipients).map((key) => filterFindRecipients[key]).filter(v => Array.isArray(v) ? v.length :  v).length" :content="Object.keys(filterFindRecipients).map((key) => filterFindRecipients[key]).filter(v => Array.isArray(v) ? v.length :  v).length">
                  <v-btn
                    color="blue"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                  >
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <v-card width="500" max-height="500" style="overflow: auto;">
                <v-card-title class="primary--text">
                  <b>{{ $t('filter') }}</b>
                </v-card-title>
                <v-card-subtitle>
                  <v-alert type="info">
                    {{$t('inclusiveAndExclusiveFilter')}}
                    <v-divider class="mb-2"/>
                    <p>
                      <v-icon>mdi-toggle-switch-off</v-icon>
                      {{$t('inclusiveFilter')}}
                    </p>
                    <p>
                      <v-icon>mdi-toggle-switch</v-icon>
                      {{$t('exclusiveFilter')}}
                    </p>
                  </v-alert>
                </v-card-subtitle>
                <v-card-text>
                  <v-select
                      disabled
                      rounded
                      v-model="filterFindRecipients.status"
                      :items="statusItens"
                      :label="$t('status')"
                      outlined
                      :item-text="item => item.id ? $t(item.id) : $t('all')"
                      :item-value="item => item.id"
                      required
                      persistent-hint
                      flat
                      dense
                      @change="searchContacts(0)"
                    />
                  <v-autocomplete
                    v-model="filterFindRecipients.segments"
                    flat
                    dense
                    :label="$t('segments')"
                    :placeholder="$t('segments')"
                    rounded
                    outlined
                    :search-input.sync="findSegments"
                    :items="segmentPage.content"
                    :item-text="v => v.name"  
                    return-object
                    hide-details
                    no-filter
                    @change="searchContacts(0), !filterFindRecipients.segments.length ? filterFindRecipients.segmentsExclusive = false : ''"
                    width="400"
                    multiple
                    small-chips
                    chips
                    clearable
                    >
                    <template v-slot:prepend-inner>
                      <v-switch inset v-model="filterFindRecipients.segmentsExclusive" :disabled="!filterFindRecipients.segments || !filterFindRecipients.segments.length" @change="searchContacts(0)"/>
                    </template>
                    <template v-slot:append>
                      <v-slide-x-transition leave-absolute hide-on-leave>
                        <v-progress-circular x-small  indeterminate width="1" size="22" v-if="loadingSegments" color="green"/>
                      </v-slide-x-transition>
                    </template>
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        small
                        color="green"
                        outlined
                        @click:close="filterFindRecipients.segments = filterFindRecipients.segments.filter(v => v != data.item || v.id != data.item.id),!filterFindRecipients.segments.length ? filterFindRecipients.segmentsExclusive = false : '',searchContacts(0)"
                      >
                        <v-icon>mdi-segment</v-icon>
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-card-text>
                <v-card-text>
                  <v-autocomplete
                    v-model="filterFindRecipients.tags"
                    flat
                    dense
                    :label="$t('tags')"
                    :placeholder="$t('tags')"
                    rounded
                    outlined
                    hide-details
                    :search-input.sync="findTags"
                    :items="tagPage.content"
                    no-filter
                    @change="searchContacts(0), !filterFindRecipients.tags.length ? filterFindRecipients.tagsExclusive = false : ''"
                    width="400"
                    multiple
                    small-chips
                    chips
                    clearable
                    >
                    <template v-slot:prepend-inner>
                      <v-switch inset v-model="filterFindRecipients.tagsExclusive" :disabled="!filterFindRecipients.tags || !filterFindRecipients.tags.length" @change="searchContacts(0)"/>
                    </template>
                    <template v-slot:append>
                      <v-slide-x-transition leave-absolute hide-on-leave>
                        <v-progress-circular x-small  indeterminate width="1" size="22" v-if="loadingTags" color="green"/>
                      </v-slide-x-transition>
                    </template>
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        small
                        color="primary"
                        outlined
                        @click:close="filterFindRecipients.tags = filterFindRecipients.tags.filter(v => v != data.item),!filterFindRecipients.tags.length ? filterFindRecipients.tagsExclusive = false : '', searchContacts(0)"
                      >
                        <v-icon>mdi-tag</v-icon>
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-card-text>
                <v-card-text>
                  <v-autocomplete
                    v-model="filterFindRecipients.properties"
                    flat
                    dense
                    :label="$t('properties')"
                    :placeholder="$t('properties')"
                    rounded
                    outlined
                    hide-details
                    :search-input.sync="findProperties"
                    :items="propertyPage.content"
                    :item-text="v => v"  
                    no-filter
                    @change="searchContacts(0),!filterFindRecipients.properties.length ? filterFindRecipients.propertiesExclusive = false : ''"
                    multiple
                    small-chips
                    chips
                    clearable
                    >
                    <template v-slot:prepend-inner>
                      <v-switch inset v-model="filterFindRecipients.propertiesExclusive" :disabled="!filterFindRecipients.properties || !filterFindRecipients.properties.length" @change="searchContacts(0)"/>
                    </template>
                    <template v-slot:append>
                      <v-slide-x-transition leave-absolute hide-on-leave>
                        <v-progress-circular x-small  indeterminate width="1" size="22" v-if="loadingProperties" color="blue"/>
                      </v-slide-x-transition>
                    </template>
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        small
                        color="blue"
                        outlined
                        @click:close="filterFindRecipients.properties = filterFindRecipients.properties.filter(v => v != data.item),!filterFindRecipients.properties.length ? filterFindRecipients.propertiesExclusive = false : ''"
                      >
                        <v-icon>mdi-variable-box</v-icon>
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-spacer/>
                  <v-btn text color="red" @click="filterFindRecipients = filterFindRecipientsAux,searchContacts(0)">{{ $t('clearFilter') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-card-title>
          <v-card-text style="overflow: auto; max-height: 60vh; min-height: 60vh;">
            <v-row v-if="!loadingContacts">
              <v-col cols="12">
                <v-card elevation="0">
                  <v-card-actions  class="pl-0 pr-0 ml-1 mr-1">
                    <v-btn class="ml-1 primary--text" x-small color="white" rounded light elevation="0" disabled>
                      {{contactPage.totalElements}}
                    </v-btn>
                    <v-spacer/>
                    <v-chip-group show-arrows>
                      <v-btn class="ml-1 primary--text" x-small :disabled="(page -1) == contactPage.number" color="white" rounded light v-for="page in contactPage.totalPages" :key="page" @click="searchContacts(page -1)">
                        <v-icon x-small v-if="(page -1) == contactPage.number">mdi-circle-medium</v-icon>
                        {{$t('page')}} {{page}}
                      </v-btn>
                    </v-chip-group>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card elevation="0">
                  <v-card-actions  class="pl-0 pr-0 ml-1 mr-1">
                    <v-checkbox v-model="addContactByFilter" hide-details class="shrink mr-2 mt-0" :value="true">
                      <template v-slot:label>
                        <b>{{ $t('addAll') }} {{contactPage.totalElements}} {{ $t('recipients').toLowerCase() }}</b>
                      </template>
                    </v-checkbox>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-for="contact, index in contactPage.content" :key="index">
                <v-card outlined :disabled="addContactByFilter">
                  <v-card-title class="primary--text">
                    <v-checkbox v-model="addContactByFilter" hide-details class="shrink mr-2 mt-0" v-if="addContactByFilter">
                      <template v-slot:label>
                        <b class="primary--text">+{{ contact.number }}</b>
                      </template>
                    </v-checkbox>
                    <v-checkbox v-model="selectContacts" hide-details class="shrink mr-2 mt-0" :value="contact" v-else>
                      <template v-slot:label>
                        <b class="primary--text">+{{ contact.number }}</b>
                      </template>
                    </v-checkbox>
                  </v-card-title>
                  <v-card-subtitle class="pb-0 mb-0" style="overflow: auto; max-height: 10vh;">
                    <v-chip-group column>
                      <v-chip v-for="segment, index in contact.segments" :key="index" x-small color="green" outlined>
                        <v-icon class="mr-1" x-small>mdi-segment</v-icon>
                        {{ segment.name }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-subtitle>
                  <v-card-subtitle class="pb-0 mb-0" style="overflow: auto; max-height: 7vh;">
                    <v-chip-group row>
                      <v-chip v-for="tag, index in contact.tags.split(',')" :key="index" x-small color="blue" outlined>
                        <v-icon class="mr-1" x-small>mdi-tag</v-icon>
                        {{ tag }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-subtitle>
                  <v-divider/>
                  <v-card-text class="pt-0 mt-0" style="overflow: auto; max-height: 10vh;">
                    <v-chip-group column>
                      <v-chip v-for="p, index in contact.properties" :key="index" color="blue" dark x-small>
                        <v-icon class="mr-1" x-small>mdi-variable</v-icon>
                        {{ p.name }}: {{ p.value }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col v-for="i in 12" :key="i" cols="12" sm="6" md="4">
                <v-skeleton-loader type="list-item-three-line" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" @click="addContactsInShooting" dark rounded :disabled="!selectContacts.length && !addContactByFilter || !contactPage.totalElements">
              {{ $t('addRecipients') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <ConfirmOperation :data="deleteConfirm" :confimAction="deleteShootingConfirm" />
    <ConfirmOperation :data="sendConfirm" :confimAction="sendSms" :text="$t('totalCostWillBe').replace('_', new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(0.3 * (data.shooting && data.shooting.recipients ? data.shooting.recipients : 0)))" :icon="`mdi-message-alert`" :title="`${$t('toSendFor')} ${data.shooting.recipients} ${$t('recipients_a')}`"/>
    
   
  </div>
</template>
<script>
import {apiV1OnlineAddresses} from "./../../../service/resources/api-v1-online-addresses";
import { apiV1SmsShooting } from './../../../service/resources/api-v1-sms-shootings';
import { apiV1SmsShootingHasContacts } from './../../../service/resources/api-v1-sms-shootings-has-contacts';
import { apiV1SmsShootingHasOnlineAddresses } from './../../../service/resources/api-v1-sms-shootings-has-online-addresses';
import { apiV1Credit } from './../../../service/resources/api-v1-credits';
import { apiV1SmsConfig } from './../../../service/resources/api-v1-sms-configs';
import ConfirmOperation from './ConfirmOperation.vue';
import { apiV1Contacts } from '../../../service/resources/api-v1-contacts';
import { apiV1Segments } from "../../../service/resources/api-v1-segments";
  export default {
    name: 'Shooting',
    props: ['data', 'actionPostSave', 'actionPostDelete'],
    components: {
        ConfirmOperation
    },
    data: () => ({
      locale: null,
      contactPage: {
        content: []
      },
      selectContacts: [],
      formSmsConfig: false,
      typesOfMessage: [{id: 'SIMPLE', name: 'simple'},{id: 'DYNAMICS', name: 'dynamics'}],
      deleteConfirm: {dialog: false},
      sendConfirm: {dialog: false},
      dialogFindRecipients: false,
      filterFindRecipients: {status: 'OPT_IN', text: null, segments: [], tags: [], properties: [], segmentsExclusive: false,tagsExclusive: false,propertiesExclusive: false, recipients: 0},
      filterFindRecipientsAux: null,
      formDialogFindRecipients: false,
      loadingDeleteShooting: false,
      dialogSmsConfig: false,
      dialogCredits: false,
      smsNewSendForm: false,
      loadingShotnerURL: false,
      loadingRecipients: false,
      loadingSave: false,
      loading: false,
      smsConfig: {
        buyAutomatically: false,
        business: '',
        creditsToBuy: 0
      },
      variableName: '',
      loadingShootingHasContacs: false,
      shootingHasContacsPage: {
        content: [],
        number: 1,
        totalElements: 0
      },
      tagAux: '',
      selected: null,
      loadingShootingHasOnlineAddresses: false,
      shootingHasOnlineAddressPage: {
        content: [],
        number: 1,
        totalElements: 0
      },
      shootingHasOnlineAddressEdit: [],
      batchActions: [],
      loadingSendShooting: false,
      loadingCredits: false,
      shootingCreditPage: {
        content: []
      },
      loadingGetSmsConfig: false,
      loadingSaveSmsConfig: false,
      businessName: null,
      dialogAlertChangeBusiness: false,
      loadingContacts: false,
      contactNumberStartsWith: '',
      addContactByFilter: false,


      segmentPage: {
        content: []
      },
      tagPage: {
        content: []
      },
      statusItens: [
        {id: null},
        {id: 'SAVED'},
        {id: 'OPT_IN_REQUIRED'},
        {id: 'OPT_IN_SENDED'},
        {id: 'OPT_IN_REQUIRED_ERROR'},
        {id: 'OPT_IN'},
        {id: 'OPT_OUT'},
      ],
      loadingSegments: false,
      findSegments: null,
      findTags: null,
      propertyPage: {
        content: []
      },
      loadingProperties: false,
      findProperties: null,
      loadingTags: false,
    }),
    watch: {
      'data.dialog' : function(value){
        if(value) {
          this.validateForm()
          this.getSmsConfig()
          this.searchContactsProperties(null)
          if(this.data.shooting && this.data.shooting.id) {
            if(this.data.shooting.variables)
              this.propertyPage.content = this.data.shooting.variables
            this.searchOnlineAddresses()
            this.searchHasContacts(0)
            if(this.data.shooting.scheduled && this.data.shooting.dateTimeScheduled) {
              let date = new Date(this.data.shooting.dateTimeScheduled.split('Z')[0]+ 'Z')
              this.data.shooting.date = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
              this.data.shooting.time = `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`
            }
          } else {
            this.shootingHasOnlineAddressPage = {content: [], number: 1, totalElements: 0}
            this.shootingHasContacsPage = {content: [], number: 1, totalElements: 0}
          }
          this.data.shooting.addFilterFindRecipients = []
        }
      },
      // 'shooting' : function(){
      //   this.validateForm()
      //   this.shootingHasOnlineAddressPage = {content: []}
      // },
      // 'data.shooting.template' : function(){
      //   this.data.shooting.smsShootingHasContacts.concat(this.shootingHasContacsPage.content).forEach(recipient => this.replaceText(recipient))
      // },
      // 'data.shooting.variables' : function(){
      //   this.data.shooting.smsShootingHasContacts.concat(this.shootingHasContacsPage.content).forEach(recipient => this.replaceText(recipient))
      // },
      // 'data.shooting.smsShootingHasContacts' : function(){
      //   this.data.shooting.smsShootingHasContacts.concat(this.shootingHasContacsPage.content).forEach(recipient => this.replaceText(recipient))
      // },
      'dialogFindRecipients': function(value) {
        if(value) {
          this.addContactByFilter = false
          this.selectContacts = []
          this.filterFindRecipients = JSON.parse(JSON.stringify(this.filterFindRecipientsAux))
          this.searchContacts(0)
          this.searchSegments(null)
          this.searchContactsTags(null)
          this.searchContactsProperties(null)
        }
      },
      'findTags': function(tag) {
        this.searchContactsTags(tag)
      },
      'findSegments': function (text) {
        this.searchSegments(text)
      },
      'findProperties': function (text) {
        this.searchContactsProperties(text)
      }
    },
    computed: {
    },
    mounted: function() {
      this.filterFindRecipientsAux = JSON.parse(JSON.stringify(this.filterFindRecipients))
      this.searchCredits()
      this.getSmsConfig()
      if(this.$route.query.smsConfig) {
        this.dialogSmsConfig = true
      }
      this.locale = navigator.languages[0]
    },
    methods: {
      countTotalCaracters: function() {
        return this.getTemplate().length
      },
      getTemplate: function() {
        return `${this.smsConfig.business}: ${this.data.shooting.template}${this.$t('textOptOut')}`
      },
      enableSave: function() {
        return !this.smsNewSendForm
      },
      hrefLinks(text) {
        if(text) {
          var urlRegex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi
          var result = text //.replace('http://', ``).replace('https://', ``)
          let links = text.match(urlRegex)
          if(links){
            links.forEach(link => {
              result = result.replace(link, `<a href="${link.includes('http') ? link : `http://${link}`}" target="_blank">${link}</a>`)
            })
          }
          return result
        }
        return ''
      },
      replaceText: function(recipient) {
        var message = this.getTemplate()
        if(this.data.shooting.variables && recipient.properties)
          this.data.shooting.variables.forEach(variable => {
            let property = recipient.properties.filter(p => p.name == variable)[0]
            if(property)
              message = message.replace(`{${variable}}`, property.value)
          })
        if(recipient.code) {
          message = message.replace('/{0}', `/${recipient.code}`)
        }
        return message
      },
      shortenLinks: function() {
        //this.data.shooting.template = this.data.shooting.template.replace('http://', ``).replace('https://', ``)
        var urlRegex =  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi
        // urlRegex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g
        let links = (this.data.shooting.template.match(urlRegex) ? this.data.shooting.template.match(urlRegex) : []).filter(l => !l.includes(`migly.in`))

        if(links.length) {
          this.loadingShotnerURL = true
          links.forEach((url,index) => {
            apiV1OnlineAddresses
            .post({url: url, type: 'SMS'})
            .then(response => {
              this.data.shooting.smsShootingHasOnlineAddresses.push({onlineAddress: response.data})
              this.data.shooting.template = this.data.shooting.template.replace(url, `migly.in/${response.data.code}/{0}`)
              if(index >= (links.length -1)) {
                this.loadingShotnerURL = false
              }
            }
            )
          })
        }
      },
      saveShooting: function(send) {
        this.loadingSave = true
        if(this.data.shooting.scheduled) {
          this.data.shooting.dateTimeScheduled = new Date(`${this.data.shooting.date} ${this.data.shooting.time}`).toISOString()
        }else {
          this.data.shooting.dateTimeScheduled = null
        }
        this.data.shooting.variables = !this.data.shooting.variables.indexOf(',') ? this.data.shooting.variables.substring(1) : this.data.shooting.variables
        this.shootingHasOnlineAddressEdit.forEach(item => {
          if(!this.data.shooting.smsShootingHasContacts.includes(item))
            this.data.shooting.smsShootingHasContacts.push(item)
        })
        this.shootingHasOnlineAddressEdit =  []
        //this.shootingHasContacsPage.content = this.shootingHasContacsPage.content.filter(v => !this.data.shooting.smsShootingHasContacts.includes(v))
        apiV1SmsShooting.save(this.data.shooting).then(result => {
          if(send || (!this.data.shooting.id && !this.data.shooting.scheduled && (this.data.shooting.smsShootingHasContacts.length || this.data.shooting.addFilterFindRecipients.length))) {
            this.sendConfirm.dialog = true
            this.data.shooting.id = result.data.id
            this.searchHasContacts(0)
            this.data.shooting.addFilterFindRecipients = []
            this.data.shooting.smsShootingHasContacts = []
          } else {
            this.data.dialog = false
          }
          this.data.shooting.id = result.data.id
          this.data.shooting.recipients = result.data.recipients
          if(this.actionPostSave) {
            this.actionPostSave(result.data)
          }
          this.loadingSave = false
          this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('saved')})
        }).catch(() => {
          // this.$commons.updateSnackbar({show: true, color: 'red', text: result})
          this.loadingSave = false
        })
      },
      sendSms: function() {
        this.loadingSendShooting = true
        apiV1SmsShooting.send({id: this.data.shooting.id}).then((result) => {
          this.sendConfirm.dialog = false
          this.data.dialog = false
          this.loadingSendShooting = false
          this.$commons.snackbar = {show: true, color: 'success', text: this.$t('enviado')}
          if(this.actionPostSave) {
            this.actionPostSave(result.data)
          }
        }).catch((result) => {
          this.$commons.snackbar = {show: true, color: 'red', text: result}
          this.loadingSendShooting = false
          this.searchCredits()
        })
      },
      searchContacts: function(page) {
        this.loadingContacts = true
        apiV1Contacts.page({page: page, size: 10, 
          shootingIdNotEquals: this.data.shooting.id ? this.data.shooting.id : null, 
          contactIdNotIn: this.data.shooting.smsShootingHasContacts.map(hc => hc.contact.id),
          
          text: this.filterFindRecipients.text, 
          tags: this.filterFindRecipients.tags, 
          status: this.filterFindRecipients.status, 
          segmentIds: this.filterFindRecipients.segments.map(v => v.id), 
          propertiesNames : this.filterFindRecipients.properties, 
          segmentsExclusive: this.filterFindRecipients.segmentsExclusive,
          tagsExclusive: this.filterFindRecipients.tagsExclusive, 
          propertiesExclusive: this.filterFindRecipients.propertiesExclusive,
          embedSegments: true, 
        }).then(result => {
          this.contactPage = result.data
          this.loadingContacts = false
        })
      },
      addContactsInShooting: function() {
        if(this.addContactByFilter) {
          if(!this.data.shooting.addFilterFindRecipients) {
            this.data.shooting.addFilterFindRecipients = []
          }
          this.filterFindRecipients.recipients = this.contactPage.totalElements
          if(!this.data.shooting.addFilterFindRecipients.filter(v => JSON.stringify(this.filterFindRecipients) == JSON.stringify(v)).length)
            this.data.shooting.addFilterFindRecipients.push(this.filterFindRecipients)
        } else {
          this.selectContacts.forEach(contact => {
            this.data.shooting.smsShootingHasContacts.push({contact: contact})
          })
        }
        this.dialogFindRecipients = false
      },
      searchHasContacts: function(page) {
        if(this.data.shooting.id) {
          this.loadingShootingHasContacs = true
          apiV1SmsShootingHasContacts.page({page: page, size: 12, orderBy: 'smsSize:DESC,dateCreate:DESC', smsShootingId: this.data.shooting.id, contactNumberStartsWith: this.contactNumberStartsWith, embedContactProperties: this.data.shooting.type == 'DYNAMICS'}).then(response => {
            this.shootingHasContacsPage = response.data
            this.loadingShootingHasContacs = false
          })
        }
      },
      searchOnlineAddresses: function() {
        this.loadingShootingHasOnlineAddresses = true
        apiV1SmsShootingHasOnlineAddresses.page({page: 0, size: 100, orderBy: 'dateCreate:DESC', smsShootingId: this.data.shooting.id}).then(response => {
          this.shootingHasOnlineAddressPage = response.data
          this.loadingShootingHasOnlineAddresses = false
        })
      },
      deleteShootingConfirm: function() {
        this.loadingDeleteShooting = true
        apiV1SmsShooting.delete(this.data.shooting.id).then(() => {
          this.data.dialog = false
          this.loadingDeleteShooting = false
          this.deleteConfirm.dialog = false
          if(this.actionPostDelete)
            this.actionPostDelete(this.data.shooting)
          this.$commons.snackbar = {show: true, color: 'success', text: this.$t('deleted')}
        })
      },
      verifyUsingLink: function(link) {
        return this.data.shooting.template.includes(`migly.in/${link.onlineAddress.code}/{0}`)
      },
      getShortLink: function(link) {
        return `migly.in/${link.onlineAddress.code}/{0}`
      },
      definePropertie: function(variable, recipient, value) {
        let property = recipient.contact.properties.filter(p => p.name == variable)[0]
        if(property) {
          property.value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        } else {
          recipient.contact.properties.push({name: variable, value: value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')})
        }
        this.replaceText(recipient)
      },
      addContatcToEdit: function(shooting, recipient) {
        if(!this.data.shooting.smsShootingHasContacts.includes(recipient)) {
          this.shootingHasOnlineAddressEdit.push(recipient)
        }
      },
      validateForm: function() {
        setTimeout(() => {this.$refs.formSMS ? this.$refs.formSMS.validate() : ''}, 500)
      },
      repeatNumbers: function() {
        var numbers = this.data.shooting.smsShootingHasContacts.concat(this.shootingHasContacsPage.content).filter(v => !v.deleted).map(v => v.contact.id)
        return numbers.some(x => numbers.indexOf(x) !== numbers.lastIndexOf(x))
      },
      invalidNumbers: function() {
        var numbers = this.data.shooting.smsShootingHasContacts.concat(this.shootingHasContacsPage.content).filter(v => !v.deleted).map(v => v.contact.number)
        return numbers.filter(v => !v || !new RegExp('^[1-9]{13,13}$').test(v))[0]
      },
      invalidText: function() {
        var text = this.data.shooting.smsShootingHasContacts.concat(this.shootingHasContacsPage.content).filter(v => !v.deleted).map(v => v.text)
        return text.filter(v => v.length > 160)[0]
      },
      excludeRepeatNumbers: function() {
        var numbers = this.data.shooting.smsShootingHasContacts.concat(this.shootingHasContacsPage.content).filter(v => !v.deleted).map(v => v.contact.number)
        this.data.shooting.smsShootingHasContacts.concat(this.shootingHasContacsPage.content).some(x => {
          if(numbers.indexOf(x.contact.number) !== numbers.lastIndexOf(x.contact.number)){
            if(!x.id) {
              this.data.shooting.smsShootingHasContacts = this.data.shooting.smsShootingHasContacts.filter(v => v != x)
            }
          }
        })
        this.validateForm()
        this.batchActions = []
      },
      searchCredits: function() {
        this.loadingCredits = true
        apiV1Credit.page({ statusIn: ['PAID', 'SUCCESS'], page: 0, size: 100, orderBy: 'dateCreate:DESC'}).then(result => {
          this.shootingCreditPage = result.data
          this.loadingCredits = false
        })
      },
      getSmsConfig: function(){
        this.loadingGetSmsConfig = true
        apiV1SmsConfig.get().then(result => {
          this.smsConfig = result.data
          this.loadingGetSmsConfig = false
          this.businessName = result.data.business
        }).catch(() => {
          // this.$commons.updateSnackbar({show: true, color: 'red', text: result})
          // this.loadingGetSmsConfig = false
        })
      },
      searchContactsTags: function(tagLike) {
        this.loadingTags = true
        apiV1Contacts.pageTags({page: 0, size: 100, tagLike: tagLike}).then(result => {
          this.tagPage = result.data
          this.loadingTags = false
        })
      },
      searchContactsProperties: function(nameStartWith) {
        this.loadingProperties = true
        apiV1Contacts.pageProperties({page: 0, size: 100, nameStartWith: nameStartWith}).then(result => {
          this.propertyPage = result.data
          this.loadingProperties = false
        })
      },
      searchSegments: function(text) {
        this.loadingSegments = true
        apiV1Segments.page({page: 0, size: 100, text: text}).then(result => {
          this.segmentPage = result.data
          this.loadingSegments = false
        })
      },
    },
  }
</script>
<style>
.number-complete .v-input__prepend-outer {
  margin-top: 0px !important;
}
</style>