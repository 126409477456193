<template>
  <div class="mt-2">
    <v-row no-gutters >
      <v-col cols="12" xs="12" sm="12" md="7" class="pl-0 ml-0 full-height">
        <v-row no-gutters >
          <v-col cols="12" xs="12" sm="12" md="12">
            <v-card-title class="primary--text mt-4">
              <b>{{$t('SMS')}}</b> 
              <v-spacer/>
              <v-menu offset-y right :close-on-content-click="false" >
                <template v-slot:activator="{ on, attrs }">
                  <v-badge overlap bordered left color="red" :value="Object.keys(filter).map((key) => filter[key]).filter(v => Array.isArray(v) ? v.length :  v).length" :content="Object.keys(filter).map((key) => filter[key]).filter(v => Array.isArray(v) ? v.length :  v).length">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      :disabled="!smsConfig"
                    >
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                  </v-badge>
                </template>
                <v-card width="500" max-height="500" style="overflow: auto;">
                  <v-card-title class="primary--text">
                    <b>{{ $t('filter') }}</b>
                  </v-card-title>
                  <v-card-text>
                    <v-select
                        rounded
                        v-model="filter.status"
                        :items="statusItens"
                        :label="$t('status')"
                        outlined
                        :item-text="item => item.id ? $t(item.id) : $t('all')"
                        :item-value="item => item.id"
                        required
                        persistent-hint
                        flat
                        dense
                        @change="searhShootings(0)"
                      />
                  </v-card-text>
                  <v-card-actions class="pt-0">
                    <v-spacer/>
                    <v-btn text color="red" @click="$router.push({query: {}}),updateFilter(),searhShootings(0)">{{ $t('clearFilter') }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
              <v-btn icon color="primary" @click="dialogSmsConfig = true" :loading="loadingGetSmsConfig">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </v-card-title>
          </v-col>
          <v-col cols="12">
            <v-card class="card-corner-radius mt-4" elevation="0">
              <v-card-actions>
                <v-btn color="green" outlined class="mr-2 ml-2" @click="dialogCredits = true" :loading="loadingCredits" rounded>
                  {{ $t('credit') }}:
                  <u>{{ new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(shootingCreditPage.content.map(item => item.remaining).reduce((partialSum, a) => partialSum + a, 0)) }}</u>
                  <v-icon small class="ml-2">mdi-information</v-icon>
                </v-btn>
                <v-spacer/>
                <v-btn rounded color="primary" @click="newShotting" :disabled="!smsConfig.id">
                  <v-icon>mdi-plus</v-icon>
                  {{ $t('sendNew') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12">
            <div v-if="!loading">
              <v-card-actions class="pl-0 pr-0 ml-0 mr-0">
                <v-spacer/>
                <v-chip-group show-arrows>
                  <v-btn class="ml-1 primary--text" x-small :disabled="(page -1) == shootingPage.number" color="white" rounded light v-for="page in shootingPage.totalPages" :key="page" @click="search(page -1)">
                    <v-icon x-small v-if="(page -1) == shootingPage.number">mdi-circle-medium</v-icon>
                    {{$t('page')}} {{page}}
                  </v-btn>
                </v-chip-group>
              </v-card-actions>
              <v-card-title class="primary--text mr-0 pr-0">
                <b>{{$t('shootings')}}</b>
                <v-spacer/>
                <v-btn rounded x-small color="red" dark class="mr-1" v-if="false">
                  {{ $t('clearFilter') }} 
                  <v-icon x-small>mdi-close</v-icon> 
                </v-btn>
                <v-btn rounded x-small color="primary" readonly>{{shootingPage.totalElements}} {{ $t('shootings') }}</v-btn>
              </v-card-title>
              <v-card-text v-if="!smsConfig.id">
                <v-alert type="info" >
                  {{ $t('businerRequiredInfo') }}
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn icon @click="dialogSmsConfig = true">
                      <v-icon>mdi-cog</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-alert>
              </v-card-text>
              <v-card-text v-else>
                <v-alert type="info" v-html="$t('smsHelp')" color="blue lighten-2"/>
              </v-card-text>
              <v-row>
                <v-col
                  v-for="(item) in shootingPage.content" :key="item.id + '-shotting'"
                  :cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                >
                  <v-card class="mt-4 card-corner-radius" elevation="0" @click="selected = item" :outlined="selected == item">
                    <v-list-item three-line >
                      <v-list-item-content>
                        <v-list-item three-line class="pa-0">
                          <v-list-item-avatar
                            tile
                            :size="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 50 : 80"
                          >
                            <v-img src="/images/logo/logo-02.png" contain/>
                          </v-list-item-avatar>
                          <v-list-item-content
                            class="pointer mb-0 pb-0 mt-0 pt-0">
                            <div class="text-overline mb-0 pb-0">
                              {{ item.title }}
                            </div>
                            <v-list-item-subtitle class="mb-0 pb-0" style="white-space: inherit;">
                              <template>
                                {{ item.description }}
                              </template>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-card-text class="pa-0">
                          <v-card elevation="0" color="grey lighten-4" outlined >
                            <v-card-text>
                              {{ item.template }}
                            </v-card-text>
                          </v-card>
                        </v-card-text>
                        <v-card-text class="pa-0 ma-0">
                          <v-chip color="transparent" class="primary--text pl-0">
                          </v-chip>
                          <div style="max-height: 80px; overflow-x: auto;" v-if="item.tags">
                            <v-chip-group class="mt-0 pt-0 mb-0 pb-0" column  content-class="mt-0 pt-0">
                              <template v-if="item.tags"> 
                                <v-chip class="mr-1 mb-1" v-for="(tag, index) in item.tags.split(`||`)" :key="index" v-show="tag" small>
                                  <v-icon x-small class="mr-1">mdi-tag</v-icon>
                                  {{tag}}
                                </v-chip>
                              </template>
                            </v-chip-group>
                          </div>
                        </v-card-text>
                        <v-card-actions class="pa-0 ma-0">
                          <v-chip small outlined>
                            <v-icon class="mr-1">mdi-account-group</v-icon>
                            {{ item.submissions }}/{{ item.recipients }} {{ $t('recipients') }}
                          </v-chip>
                          <v-spacer/>
                          <v-chip small outlined v-if="item.dateTimeSend" color="green">
                            <v-icon class="mr-1" small>mdi-send-check</v-icon>
                            {{ new Date(`${item.dateTimeSend.split('Z')[0]}Z`).toLocaleString() }}
                          </v-chip>
                        </v-card-actions>
                      </v-list-item-content>
                      <v-divider vertical class="mt-4 mb-4 ml-4"/>
                      <v-list-item-action class="pl-0 ml-1" style="margin: auto;">
                        <v-menu
                          v-if="item.processing"
                          transition="slide-x-transition"
                          offset-y
                          right
                          rounded
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              color="primary"
                            >
                              <v-progress-circular indeterminate color="primary" width="2" small/>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item>
                              {{ $t(item.status) }}   
                              <v-spacer/>
                              <v-progress-circular indeterminate color="blue" class="mr-2" width="2"/>
                            </v-list-item>
                            <v-list-item>
                              {{ $t('processingSmsSend') }}
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <v-menu
                          v-if="item.status == 'SCHEDULED'"
                          transition="slide-x-transition"
                          offset-y
                          right
                          rounded
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              color="primary"
                            >
                            <v-icon>mdi-calendar-clock-outline</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item class="primary--text">
                              <b>{{ $t(item.status) }}</b>
                              <v-spacer/>
                              <v-icon color="primary">mdi-calendar-clock-outline</v-icon>
                            </v-list-item>
                            <v-list-item v-if="item.dateTimeScheduled">
                              {{ $t('toScheduledShootingForSend') }}
                              {{ new Date(`${item.dateTimeScheduled.split('Z')[0]}Z`).toLocaleString() }}
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <v-menu
                          v-if="item.status == 'SENT' && !item.processing"
                          transition="slide-x-transition"
                          offset-y
                          right
                          rounded
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              color="green"
                            >
                            <v-icon>mdi-check-circle</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item>
                              {{ $t(item.status) }}   
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <v-menu
                          v-if="item.status == 'SENT'"
                          transition="slide-x-transition"
                          offset-y
                          right
                          rounded
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              color="green"
                            >
                              <v-icon>mdi-cash</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item class="green--text">
                              <b>{{ $t('totalCost') }}</b>
                              <v-spacer class="mr-4"/>
                              {{ new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(item.costEffective) }}
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <v-menu
                          v-if="item.status == 'FAILURE'"
                          transition="slide-x-transition"
                          offset-y
                          right
                          rounded
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              color="error"
                            >
                              <v-icon>mdi-alert-circle</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item class="error--text">
                              <b>{{ $t('FAILURE') }}</b>
                              <v-spacer/>
                              <v-icon color="error">mdi-alert-circle</v-icon>
                            </v-list-item>
                            <v-list-item>
                              - {{ $t('sendFailedShooting') }}
                            </v-list-item>
                            <v-list-item v-if="item.type = 'DYNAMICS'">
                              - {{ $t('dynamicMessageAlert') }}
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <v-btn :color="item.status == 'SENT' ? 'grey' : 'primary'" small icon @click="openEdit(item)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small icon color="primary" @click="selected = item">
                          <v-icon>mdi-finance</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-card-actions >
                <v-skeleton-loader type="chip"/>
                <v-spacer/>
                <v-skeleton-loader type="chip" class="mr-1"/>
                <v-skeleton-loader type="chip"/>
              </v-card-actions>
              <v-skeleton-loader type="article" class="mt-4" v-for="i in 4" :key="i"/>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="5" v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)" class="full-height pa-4 pr-0">
        <ShootingDashboard :data="{shooting: selected, smsConfig: smsConfig}"/>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogSmsConfig" width="400" persistent>
      <v-card class="card-corner-radius" color="grey lighten-3">
        <v-card-title class="primary--text pr-4 pl-4">
          <v-btn icon color="primary" @click="dialogSmsConfig = false" :readonly="loadingSave" :disabled="loadingSaveSmsConfig">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <b>{{ $t('smsConfig') }}</b>
          <v-spacer/>
        </v-card-title>
        
        <v-form v-model="formSmsConfig" ref="formSmsConfig">
          <v-card-text>
            <v-card elevation="0" class="card-corner-radius">
              <v-card-text>
                <v-text-field
                  v-model="smsConfig.business"
                  class="mt-1"
                  outlined
                  rounded
                  dense
                  flat
                  :counter="(160 - $t('exempleSmsOptInDesc').length + 1) / 1"
                  :max="(160 - $t('exempleSmsOptInDesc').length + 1) / 1"
                  :maxlength="(160 - $t('exempleSmsOptInDesc').length + 1) / 1"
                  :placeholder="$t('businessName')"
                  :label="$t('businessName')"
                  :rules="[
                    v => !!v || $t('required'),
                    v => !!v && v.length <= ((160 - $t('exempleSmsOptInDesc').length + 1) / 1) || $t('invalidSize'),
                  ]"
                  @change="v => v ? smsConfig.business = v.toUpperCase().trim() : ''"
                />
                
                <v-switch :label="$t('buyCreditsAutomatic')" v-model="smsConfig.autoBuy" v-if="false"/>
                <div v-if="smsConfig.autoBuy && false">
                  <b class="green--text">
                    <big>{{ new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(smsConfig.creditsToBuy) }}</big>
                  </b>
                  <v-slider
                    v-model="smsConfig.creditsToBuy"
                    max="1000"
                    min="20"
                    step="10"
                    color="green"
                    class="ml-0 pl-0"
                    :thumb-label="new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(smsConfig.creditsToBuy)"
                    height="10"/>
                </div>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-fade-transition>
            <v-card-text v-if="!!smsConfig.business">
              <v-card elevation="0" outlined class="card-corner-radius">
                <v-card-subtitle class="primary--text">
                  <i>{{ $t('exempleSmsOptIn') }}</i>
                </v-card-subtitle>
                <v-card-text class="black--text">
                  {{ $t('exempleSmsOptInDesc').replace(/_/gi, smsConfig.business.toUpperCase()) }}
                </v-card-text>
                <v-card-actions class="pt-0 mt-0">
                  <v-spacer/>
                  {{ $t('exempleSmsOptInDesc').replace(/_/gi, smsConfig.business).length }} / 160
                </v-card-actions>
                <v-divider/>
                <v-card-subtitle class="primary--text">
                  <i>{{ $t('exempleSmsMarketing') }}</i>
                </v-card-subtitle>
                <v-card-text class="black--text">
                  {{ $t('exempleSmsMarketingDesc').replace(/_/gi, smsConfig.business.toUpperCase()) }}
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-fade-transition>

          <v-card-text>
            <v-alert type="warning">
              {{ $t('alertChangeBusinessName') }}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn @click="!businessName || businessName == smsConfig.business? saveSmsConfig() : dialogAlertChangeBusiness = true" color="primary" large :loading="loadingSaveSmsConfig" :disabled="!formSmsConfig" rounded>
              {{$t('save')}}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCredits" width="800" persistent>
      <v-card class="card-corner-radius" color="grey lighten-3">
        <v-card-title class="primary--text pr-4 pl-4">
          <v-btn icon color="primary" @click="dialogCredits = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <b>{{ $t('credit') }}</b>
          <v-spacer/>
          <v-alert type="info" v-html="$t('smsInfo')" dense flat rounded icon="mdi-info">
          </v-alert>
        </v-card-title>
        <Credits :confirmAction="() => {dialogCredits = false, searchCredits()}"/>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAlertChangeBusiness" width="600" :persistent="true">
      <v-card class="card-corner-radius">
        <v-card-title>
          <v-icon color="primary" class="mr-2">mdi-alert</v-icon>
          {{$t('areYouSure')}}
        </v-card-title>
        <v-card-title class="pl-4 ml-4 mr-4 pr-4">
          {{ $t('operationCannotBeUndone') }} 
        </v-card-title>
        <v-card-actions>
          <v-btn @click="dialogAlertChangeBusiness = false" outlined color="primary" large>
            {{$t('cancel')}}
          </v-btn>
          <v-spacer/>
          <v-btn @click="saveSmsConfig(), dialogAlertChangeBusiness = false" color="primary" large>
            {{$t('toConfirm')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Shooting :data="shootingData" :actionPostSave="actionPostSave" :actionPostDelete="actionPostDelete"/>
  </div>
</template>
<script>
import { apiV1SmsShooting } from './../../service/resources/api-v1-sms-shootings';
import { apiV1Credit } from './../../service/resources/api-v1-credits';
import { apiV1SmsConfig } from './../../service/resources/api-v1-sms-configs';
import Credits from "./fragments/Credits.vue";
import Shooting from './fragments/Shooting';
import ShootingDashboard from './fragments/ShootingDashboard.vue';
  export default {
    name: 'SMSPage',
    props: ['screenData'],
    components: {
    Credits,
    Shooting,
    ShootingDashboard
},
    data: () => ({
      filter: {status: null},
      statusItens: [
        {id: null},
        {id: 'SAVED'},
        {id: 'SCHEDULED'},
        {id: 'SENT'},
        {id: 'FAILURE'}
      ],
      shootingData: {dialog: false, shooting: null},
      formSmsConfig: false,
      dialogDeleteShooting: false,
      loadingDeleteShooting: false,
      dialogSmsConfig: false,
      dialogCredits: false,
      dialogNewSend: false,
      smsNewSendForm: false,
      loadingShotnerURL: false,
      loadingRecipients: false,
      loadingSave: false,
      loading: false,
      planData: {
        dialogPlansAndPrices: false,
        dialogSubscriptionPlan: false
      },
      viewMessages: false,
      smsConfig: {
        buyAutomatically: false,
        business: '',
        creditsToBuy: 0
      },
      shootingPage: {
        content: [],
        number: 1
      },
      selected: null,
      loadingShootingHasOnlineAddresses: false,
      loadingCredits: false,
      shootingCreditPage: {
        content: []
      },
      loadingGetSmsConfig: false,
      loadingSaveSmsConfig: false,
      businessName: null,
      dialogAlertChangeBusiness: false
    }),
    watch: {
      'dialogNewSend' : function(){
        // this.validateForm()
      },
      'dialogSmsConfig' : function() {
        setTimeout(() => {this.$refs.formSmsConfig ? this.$refs.formSmsConfig.validate() : ''}, 500)
      },
      'shooting' : function(){
      },
      'shooting.template' : function(){
        this.shooting.smsShootingHasContacts.concat(this.shootingHasContacsPage.content).forEach(recipient => this.replaceText(this.shooting, recipient))
      },
      'shooting.variables' : function(){
        this.shooting.smsShootingHasContacts.concat(this.shootingHasContacsPage.content).forEach(recipient => this.replaceText(this.shooting, recipient))
      },
      'shooting.smsShootingHasContacts' : function(){
        this.shooting.smsShootingHasContacts.concat(this.shootingHasContacsPage.content).forEach(recipient => this.replaceText(this.shooting, recipient))
      },
      'selected' : function(){
      },
    },
    computed: {
    },
    mounted: function() {
      this.updateFilter()
      this.searhShootings(0)
      this.searchCredits()
      if(this.$route.query.smsConfig) {
        this.dialogSmsConfig = true
      }
      this.getSmsConfig()
    },
    methods: {
      newShotting: function() {
        this.shootingData.shooting = {tags: '', smsShootingHasOnlineAddresses: [], variables: [],template: '',smsShootingHasContacts: [], timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}
        this.shootingData.dialog = true
      },
      openEdit: function(item) {
        item.smsShootingHasContacts = []
        this.shootingHasOnlineAddressEdit = []
        this.recipient = []
        item.variables = item.variables ? item.variables : []
        this.selected = item
        this.shootingData.shooting = JSON.parse(JSON.stringify(item))
        this.shootingData.dialog = true
      },
      updateFilter: function(){
        this.filter.status = this.$route.query.status
      },
      searhShootings: function(page) {
        var query = JSON.parse(JSON.stringify(this.$route.query))
        query.status = this.filter.status
        this.$router.push({query : query}).catch(()=>{})
        this.loading = true
        apiV1SmsShooting.page({page: page, size: 10, orderBy: 'dateCreate:DESC', status: this.filter.status}).then(response => {
          this.shootingPage = response.data
          if(!this.selected) {
            this.selected = response.data.content[0]
          }
          this.loading = false
        })
        setInterval(() => {
          this.shootingPage.content.filter(shooting => shooting.processing).forEach(shooting => {
            apiV1SmsShooting.get(shooting.id).then(response => {
              shooting.processing = response.data.processing
              shooting.submissions = response.data.submissions
              shooting.status = response.data.status
            })
          })
        }, 1000);
      },
      actionPostSave: function(result) {
        var update = false
        this.shootingPage.content.forEach((item, index) => {
          if(item.id == result.id){
            this.shootingPage.content.splice(index, 1)
            this.shootingPage.content.splice(index, 0, result)
            update = true
          }
        })
        if(!update) {
          this.shootingPage.content.splice(0, 0, result)
        }
        this.searchCredits()
      },
      actionPostDelete: function(result) {
        this.shootingPage.content.forEach((item, index) => {
          if(item.id == result.id){
            this.shootingPage.content.splice(index, 1);
          }
        })
      },
      searchCredits: function() {
        this.loadingCredits = true
        apiV1Credit.page({ statusIn: ['PAID', 'SUCCESS'], page: 0, size: 100, orderBy: 'dateCreate:DESC'}).then(result => {
          this.shootingCreditPage = result.data
          this.loadingCredits = false
        })
      },
      getSmsConfig: function(){
        this.loadingGetSmsConfig = true
        apiV1SmsConfig.get().then(result => {
          if(result.data)
            this.smsConfig = result.data
          this.loadingGetSmsConfig = false
          this.businessName = result.data.business
        }).catch(() => {
          // this.$commons.updateSnackbar({show: true, color: 'red', text: result})
          // this.loadingGetSmsConfig = false
        })
      },
      saveSmsConfig: function() {
        this.loadingSaveSmsConfig = true
        apiV1SmsConfig.save(this.smsConfig).then(result => {
          this.smsConfig = result.data
          this.loadingSaveSmsConfig = false
          this.dialogSmsConfig = false
          this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('saved')})
          this.$router.push({query: {}})
        }).catch(() => {
          this.loadingSaveSmsConfig = false
        })
      }
    },
  }
</script>
<style>
.number-complete .v-input__prepend-outer {
  margin-top: 0px !important;
}
</style>