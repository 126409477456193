import http from "../axios";

let apiV1Segments = {
	baseUrl: '/api/v1/segments',
	page: async (filter) => {
		return await http.get(`${apiV1Segments.baseUrl}
		?page=${filter.page}
		&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.number ? `&number=${filter.number}` : ''}
		${filter.smsOptIn ? `&smsOptIn=${filter.smsOptIn}` : ''}
		${filter.text ? `&text=${filter.text}` : ''}
		${filter.ids ? `&ids=${filter.ids}` : ''}
		`);
	},
}

export  { apiV1Segments }