<template>
  <div>
    <v-card-title class="green--text">
      {{ new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(shootingCreditPage.content.map(item => item.remaining).reduce((partialSum, a) => partialSum + a, 0)) }}
      <v-spacer/>
      <v-btn rounded color="green" dark @click="isAddCredit = !isAddCredit">
        <v-icon class="mr-2">mdi-cash</v-icon>
        <b>{{ $t('cashRefund') }}</b>
      </v-btn>
    </v-card-title>
    <v-fade-transition>
      <v-card-text v-if="isAddCredit">
        <template v-if="loadingCards">
          <v-skeleton-loader type="list-item-three-line"/>
        </template>
        <v-form v-model="paymentForm" ref="paymentForm" v-else>
          <v-expansion-panels class="mb-6" elevation="0" v-model="step" :disabled="paymentForm">
            <template v-if="!cards.length">
              <v-expansion-panel>
                <v-expansion-panel-header expand-icon="mdi-menu-down" class="primary--text">
                  <b>{{ $t('document') }}</b>
                  <template v-slot:actions>
                    <v-icon color="error" v-if="!paymentFormDocument">mdi-alert-circle</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form v-model="paymentFormDocument" ref="paymentFormDocument">
                    <span class="primary--text">{{ $t('cpfCnpj') }}*</span>
                    <v-text-field
                      class="mb-2"
                      required
                      clearable
                      dense
                      flat
                      rounded
                      label="000.000.000-00"
                      solo
                      focus
                      v-model="credit.customer.document"
                      prepend-inner-icon="mdi-card-account-details"
                      @keyup="credit.customer.document = credit.customer.document.replace(/[^0-9]+/g,'')"
                      maxlength="16"
                      counter
                      outlined
                      :hide-details="isCpf(credit.customer.document) || isCnpj(credit.customer.document)"
                      :rules="[
                        v => !!v || 'Documento obrigatório no formato somente númerico',
                        v => (isCpf(v) || isCnpj(v)) || 'Documento inválido',
                      ]"
                    />
                    <span class="primary--text">{{ $t('name') }}*</span>
                    <v-text-field
                      required
                      clearable
                      dense
                      flat
                      rounded
                      :label="$t('name')"
                      solo
                      focus
                      v-model="credit.customer.name"
                      prepend-inner-icon="mdi-clipboard-account"
                      maxlength="40"
                      counter
                      outlined
                      :rules="[
                        v => !!v || $t('required'),
                      ]"
                    />
                    <v-card-actions class="pa-0 ma-0 pt-2">
                      <v-spacer/>
                      <v-btn @click="step++" color="primary" rounded :disabled="!paymentFormDocument">
                        {{ $t('avançar') }}
                        <v-icon class="ml-2">mdi-arrow-down</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header expand-icon="mdi-menu-down" class="primary--text">
                  <b>{{ $t('contact') }}</b>
                  <template v-slot:actions>
                    <v-icon color="error" v-if="!paymentFormContact">mdi-alert-circle</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form v-model="paymentFormContact">
                    <v-row>
                      <v-col cols="3" xs="3" sm="3">
                        <span class="primary--text">{{ $t('ddi') }}*</span>
                        <v-text-field
                          outlined
                          required
                          flat
                          dense
                          rounded
                          label="+55"
                          solo
                          focus
                          v-model="credit.customer.phone.countryCode"
                          @keyup="credit.customer.phone.countryCode = credit.customer.phone.countryCode.replace(/[^0-9]+/g,'')"
                          maxlength="5"
                          counter
                          :hide-details="credit.customer.phone.countryCode"
                          :rules="[
                            v => !!v || 'DDI obrigatório',
                          ]"
                        />
                    </v-col>
                      <v-col cols="3" xs="3" sm="3">
                        <span class="primary--text">{{ $t('ddd') }}*</span>
                        <v-text-field
                          outlined
                          required
                          rounded
                          flat
                          dense
                          label="11"
                          solo
                          focus
                          v-model="credit.customer.phone.areaCode"
                          @keyup="credit.customer.phone.areaCode = credit.customer.phone.areaCode.replace(/[^0-9]+/g,'')"
                          maxlength="5"
                          counter
                          :hide-details="credit.customer.phone.areaCode"
                          :rules="[
                            v => !!v || 'DDD obrigatório',
                          ]"
                        />
                      </v-col>
                      <v-col cols="6" xs="6" sm="6">
                        <span class="primary--text">{{ $t('number') }}*</span>
                        <v-text-field
                          outlined
                          flat
                          rounded
                          dense
                          required
                          clearable
                          label="000000000"
                          solo
                          focus
                          v-model="credit.customer.phone.number"
                          prepend-inner-icon="mdi-cellphone"
                          @keyup="credit.customer.phone.number = credit.customer.phone.number.replace(/[^0-9]+/g,'')"
                          maxlength="15"
                          counter
                          :hide-details="credit.customer.phone.number >= 11"
                          :rules="[
                            v => !!v || 'Número obrigatório',
                            credit.customer.phone.number >= 13 || 'Número inválido',
                          ]"
                        />
                      </v-col>
                    </v-row>
                    <v-card-actions class="pa-0 ma-0 pt-2">
                      <v-btn @click="step--" color="primary" rounded small>
                        <v-icon class="ml-2">mdi-arrow-up</v-icon>
                        {{ $t('toGoBack') }}
                      </v-btn>
                      <v-spacer/>
                      <v-btn @click="step++" color="primary" rounded :disabled="!paymentFormContact">
                        {{ $t('avançar') }}
                        <v-icon class="ml-2">mdi-arrow-down</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header expand-icon="mdi-menu-down" class="primary--text">
                  <b>{{ $t('creditCard') }}</b>
                  <template v-slot:actions>
                    <v-icon color="error" v-if="!paymentFormCreditCard">mdi-alert-circle</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form v-model="paymentFormCreditCard">
                    <v-row>
                      <v-col cols="7" xs="7" sm="7" md="7">
                        <span class="primary--text">{{ $t('creditCardNumber') }}*</span>
                        <v-text-field
                          outlined
                          required
                          clearable
                          label="0000 0000 0000 0000"
                          solo
                          dense
                          flat
                          rounded
                          focus
                          v-model="credit.card.number"
                          prepend-inner-icon="mdi-credit-card"
                          @keyup="credit.card.number = credit.card.number.replace(/[^0-9]+/g,'')"
                          maxlength="19"
                          counter
                          :hide-details="cardValidadion.card_number"
                          @blur="verifyCard"
                          :rules="[
                            v => !!v || 'Número do cartão obrigatório',
                            cardValidadion.card_number || 'Número do cartão inválido',
                          ]"
                        />
                      </v-col>
                      <v-col cols="5" xs="5" sm="5" md="5">
                        <span class="primary--text">{{ $t('cvv') }}*</span>
                        <v-text-field
                          outlined
                          required
                          clearable
                          label="000"
                          solo
                          dense
                          flat
                          rounded
                          focus
                          v-model.trim="credit.card.cvv"
                          prepend-inner-icon="mdi-security"
                          maxlength="3"
                          @keyup="credit.card.cvv = credit.card.cvv.replace(/[^0-9]+/g,'')"
                          @blur="verifyCard"
                          counter
                          :hide-details="cardValidadion.card_cvv"
                          :rules="[
                            v => !!v || 'CVV obrigatório',
                            cardValidadion.card_cvv || 'CVV inválido',
                          ]"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col ols="12" xs="12" sm="7" md="7">
                        <span class="primary--text">{{ $t('creditCardNamePrinted') }}*</span>
                        <v-text-field
                          outlined
                          required
                          label="Nome e Sobrenome"
                          solo
                          focus
                          dense
                          flat
                          rounded
                          v-model.trim="credit.card.holderName"
                          prepend-inner-icon="mdi-account"
                          maxlength="40"
                          clearable
                          :hide-details="cardValidadion.card_holder_name"
                          @keyup="credit.card.holderName = credit.card.holderName.toUpperCase()"
                          @blur="verifyCard"
                          :rules="[
                            v => !!v || 'Nome obrigatório',
                            cardValidadion.card_holder_name || 'Nome inválido',
                          ]"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" sm="5" md="5">
                        <span class="primary--text">{{ $t('expiration') }}*</span>
                        <v-text-field
                          outlined
                          required
                          label="00/00"
                          solo
                          focus
                          dense
                          flat
                          rounded
                          v-model="credit.card.expiration"
                          prepend-inner-icon="mdi-calendar"
                          mask="##/##"
                          clearable
                          @keyup="credit.card.expiration = credit.card.expiration.replace(/[^0-9//]+/g,''); credit.card.expiration.length == 2 && !credit.card.expiration.includes('/') ? credit.card.expiration = `${credit.card.expiration}/` : ''"
                          maxlength="5"
                          counter
                          @blur="verifyCard()"
                          :hide-details="cardValidadion.card_expiration_date"
                          :rules="[
                            v => !!v || 'Expiração obrigatória',
                            cardValidadion.card_expiration_date || 'Expiração inválida',
                          ]"
                        />
                      </v-col>
                    </v-row>
                    <v-card-actions class="pa-0 ma-0 pt-2">
                      <v-btn @click="step--" color="primary" rounded small>
                        <v-icon class="ml-2">mdi-arrow-up</v-icon>
                        {{ $t('toGoBack') }}
                      </v-btn>
                      <v-spacer/>
                      <v-btn @click="isCardValid() && card_hash ? step++ : ''" :color="isCardValid() && card_hash ? 'primary' : 'error'" rounded :disabled="!paymentFormCreditCard" :loading="loadVerifyCard">
                        <div v-if="isCardValid() && card_hash">
                          {{ $t('avançar') }}
                          <v-icon class="ml-2">mdi-arrow-down</v-icon>
                        </div>
                        <div dark v-else color="red">
                          {{ $t('invalidCard') }}
                          <v-icon class="ml-2">mdi-alert-circle-outline</v-icon>
                        </div>
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header expand-icon="mdi-menu-down" class="primary--text">
                  <b>{{ $t('billingAddress') }}</b>
                  <template v-slot:actions>
                    <v-icon color="error" v-if="!paymentFormContact">mdi-alert-circle</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form v-model="paymentFormBillingAddress">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12">
                        <span class="primary--text">{{ $t('publicStreet') }}*</span>
                        <v-text-field
                          outlined
                          required
                          clearable
                          flat
                          dense
                          rounded
                          :label="$t('publicStreet')"
                          solo
                          focus
                          v-model="credit.card.billingAddress.line1"
                          maxlength="55"
                          :hide-details="!!credit.card.billingAddress.line1"
                          :rules="[
                            v => !!v || 'Rua/Logradouro obrigatória',
                          ]"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span class="primary--text">{{ $t('complement') }}*</span>
                        <v-text-field
                          outlined
                          clearable
                          flat
                          dense
                          rounded
                          label="Complemento"
                          solo
                          focus
                          v-model.trim="credit.card.billingAddress.line2"
                          hide-details
                          maxlength="30"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" sm="12">
                        <span class="primary--text">{{ $t('cep') }}*</span>
                        <v-text-field
                          outlined
                          required
                          label="CEP"
                          solo
                          focus
                          flat
                          dense
                          rounded
                          v-model.trim="credit.card.billingAddress.zipCode"
                          clearable
                          maxlength="9"
                          mask="#####-###"
                          @keyup="credit.card.billingAddress.zipCode = credit.card.billingAddress.zipCode.replace(/[^0-9-]+/g,''); credit.card.billingAddress.zipCode.length == 5 && !credit.card.billingAddress.zipCode.includes('-') ? credit.card.billingAddress.zipCode = `${credit.card.billingAddress.zipCode}-` : ''"
                          counter
                          :hide-details="credit.card.billingAddress.zipCode.length >= 9"
                          :rules="[
                            v => !!v || 'CEP obrigatório',
                            v => v.length >= 9 || 'CEP inválido',
                          ]"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" sm="8">
                        <span class="primary--text">{{ $t('city') }}*</span>
                        <v-text-field
                          outlined
                          required
                          label="Cidade"
                          solo
                          flat
                          dense
                          rounded
                          focus
                          v-model="credit.card.billingAddress.city"
                          maxlength="50"
                          clearable
                          :hide-details="!!credit.card.billingAddress.city"
                          :rules="[
                            v => !!v || 'Cidade obrigatória',
                          ]"
                        />
                      </v-col>
                      <v-col>
                        <span class="primary--text">{{ $t('state') }}*</span>
                        <v-select
                          :items="['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO']"
                          outlined
                          required
                          label="Estado"
                          solo
                          focus
                          flat
                          dense
                          rounded
                          v-model="credit.card.billingAddress.state"
                          maxlength="50"
                          :hide-details="!!credit.card.billingAddress.state"
                          :rules="[
                            v => !!v || 'Estado obrigatório',
                          ]"
                        />
                      </v-col>
                    </v-row>
                    <v-card-actions class="pa-0 ma-0 pt-2">
                      <v-btn @click="step--" color="primary" rounded small>
                        <v-icon class="ml-2">mdi-arrow-up</v-icon>
                        {{ $t('toGoBack') }}
                      </v-btn>
                      <v-spacer/>
                      <v-btn @click="step++" color="primary" rounded :disabled="!paymentFormBillingAddress">
                        {{ $t('avançar') }}
                        <v-icon class="ml-2">mdi-arrow-down</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
            <template v-else>
              <v-expansion-panel>
                <v-expansion-panel-header expand-icon="mdi-menu-down" class="primary--text">
                  <b>{{ $t('cards') }}</b>
                  <template v-slot:actions>
                    <v-icon color="error" v-if="!paymentFormDocument">mdi-alert-circle</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form v-model="paymentFormDocument" ref="paymentFormDocument" mandatory>
                    <span class="primary--text">{{ $t('cardSelection') }}*</span>
                    <v-radio-group v-model="credit.card" mandatory>
                      <v-card outlined elevation="1" v-for="card, index in cards" :key="index" :disabled="card.status != 'active'" class="mb-2">
                        <v-card-title class="primary--text">
                          <v-radio :value="card"/>
                          <b>******** **{{ card.lastFourDigits }}</b>
                          <v-spacer/>
                          {{ card.brand }}
                        </v-card-title>
                      </v-card>
                    </v-radio-group>
                    <v-btn color="primary" rounded @click="credit = JSON.parse(JSON.stringify(creditAuxiliar)), cards = []">
                      {{ $t('addNewCard') }}
                    </v-btn>
                    <v-card-actions class="pa-0 ma-0 pt-2">
                      <v-spacer/>
                      <v-btn @click="step++" color="primary" rounded :disabled="!paymentFormDocument || !credit.card">
                        {{ $t('avançar') }}
                        <v-icon class="ml-2">mdi-arrow-down</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="mdi-menu-down" class="primary--text">
                <b>{{ $t('credit') }}</b>
                <template v-slot:actions>
                  <v-icon color="error" v-if="!paymentFormCredit">mdi-alert-circle</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form v-model="paymentFormCredit">
                  <v-card-text>
                    <div>
                      <b class="green--text">
                        <big>{{ new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(credit.total) }}</big>
                      </b>
                      <v-slider
                        v-model="credit.total"
                        max="1000"
                        min="20"
                        step="10"
                        color="green"
                        class="ml-0 pl-0"
                        :thumb-label="true"
                        height="10"/>
                    </div>
                  </v-card-text>
                  <v-card-actions class="pa-0 ma-0 pt-2">
                    <v-btn @click="step--" color="primary" rounded small>
                      <v-icon class="ml-2">mdi-arrow-up</v-icon>
                      {{ $t('toGoBack') }}
                    </v-btn>
                    <v-spacer/>
                    <v-btn color="primary" rounded :disabled="!paymentFormCredit || !paymentForm" @click="saveCredit" :loading="loadingSaveCredits">
                      {{ $t('purchase') }}
                    </v-btn>
                  </v-card-actions>
                  <v-img class="mt-4" src="/images/Selo-Pagar.me-Aplicação-horizontal-Fundo-branco.png"/>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-card-text>
    </v-fade-transition>
  </div>
</template>
<script>
import pagarme from 'pagarme'
import { apiV1Credit } from '../../../service/resources/api-v1-credits'
import { apiV1Card } from '../../../service/resources/api-v1-card'

  export default {
    name: 'Credits',
    props: ['confirmAction'],
    components: {
    },
    data: () => ({
      paymentForm: false,
      paymentFormDocument: true,
      paymentFormContact: true,
      paymentFormCreditCard: true,
      paymentFormBillingAddress: true,
      paymentFormCredit: false,
      step: 0,
      isAddCredit: false,
      loadingCredits: false,
      shootingCreditPage: {
        content: []
      },
      credit: {
        total: 0,
        customer: {
          phone: {countryCode: '', number: '', areaCode: ''}, document: ''
        }, 
        card: {
          number: '', cvv: '', expiration: '', holderName: '', 
            billingAddress: {
              line1: '', line2:'', zipCode:'', city: '', state: ''
            }
        }
      },
      creditAuxiliar: null,
      cardValidadion: {
        brand: null,
        card_cvv: false,
        card_expiration_date: false,
        card_holder_name: false,
        card_number: false,
      },
      card_hash: null,
      loadVerifyCard: false,
      loadingSaveCredits: false,
      cards: [],
      loadingCards: false
    }),
    watch: {
      '$route': function() {
      },
      'step' : function() {
        this.validForm()
      },
      'isAddCredit' : function(value) {
        if(value) {
          this.getCards()
        }
      }

    },
    mounted: function() {
      if(process.env.VUE_APP_ENVIROMENT == 'DEV') {
        this.credit.customer.name = process.env.VUE_APP_NAME
        this.credit.customer.document = process.env.VUE_APP_DOCUMENT
        this.credit.customer.phone.countryCode = process.env.VUE_APP_DDI
        this.credit.customer.phone.areaCode = process.env.VUE_APP_DDD
        this.credit.customer.phone.number = process.env.VUE_APP_TEL
        
        this.credit.card.holderName = process.env.VUE_APP_CARD_HOLDER_NAME
        this.credit.card.number = process.env.VUE_APP_CARD_NUMBER
        this.credit.card.expiration = process.env.VUE_APP_CARD_EXPIRATION
        this.credit.card.cvv = process.env.VUE_APP_CARD_CVV
        this.credit.card.billingAddress.line1 = process.env.VUE_APP_ADDRESS
        this.credit.card.billingAddress.line2 = process.env.VUE_APP_COMPLEMENT
        this.credit.card.billingAddress.zipCode = process.env.VUE_APP_CEP
        this.credit.card.billingAddress.city = process.env.VUE_APP_CITY
        this.credit.card.billingAddress.state = process.env.VUE_APP_UF
        this.verifyCard()
      }
      this.creditAuxiliar = JSON.parse(JSON.stringify(this.credit))
      this.validForm()
      this.searchCredits()
    },
    computed: {
    },
    methods: {
      validForm: function(){
        setTimeout(() => {this.$refs.paymentForm ? this.$refs.paymentForm.validate() : ''}, 500)
      },
      isCardValid: function() {
        let status = this.cardValidadion.card_number 
          && this.cardValidadion.card_holder_name 
          && this.cardValidadion.card_expiration_date 
          && this.cardValidadion.card_cvv
          && this.cardValidadion.brand
        return status
      },
      isCpf: function(strCPF) {
        var Soma
        var Resto
        Soma = 0
        if (!strCPF || strCPF == "00000000000" || strCPF.length != 11) return false
        var i = 1
        for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i)
          Resto = (Soma * 10) % 11
        if ((Resto == 10) || (Resto == 11))  Resto = 0
        if (Resto != parseInt(strCPF.substring(9, 10)) ) return false
        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i)
        Resto = (Soma * 10) % 11
        if ((Resto == 10) || (Resto == 11))  Resto = 0
        if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false
        return true
      },
      isCnpj: function(cnpj) {
        cnpj = !cnpj ? cnpj.replace(/[^\d]+/g,'') : cnpj;
    
        if(!cnpj || cnpj == '' || cnpj.length != 14) return false;
    
        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" || 
            cnpj == "11111111111111" || 
            cnpj == "22222222222222" || 
            cnpj == "33333333333333" || 
            cnpj == "44444444444444" || 
            cnpj == "55555555555555" || 
            cnpj == "66666666666666" || 
            cnpj == "77777777777777" || 
            cnpj == "88888888888888" || 
            cnpj == "99999999999999")
            return false;
            
        // Valida DVs
        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0,tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        var i = 0
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return false;
            
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
              return false;
              
        return true;
      },
      verifyCard: function() {
        this.loadVerifyCard = true
        const card = {
          card_number: this.credit.card.number,
          card_holder_name: this.credit.card.holderName,
          card_expiration_date: this.credit.card.expiration.replace('/', ''),
          card_cvv: this.credit.card.cvv,
        }
        this.cardValidadion = pagarme.validate({card: card}).card
        setTimeout(() => {
          this.loadVerifyCard = false
        }, 1000);
        if(this.isCardValid()) {
          pagarme.client.connect({ encryption_key: process.env.VUE_APP_PAGARME_APP_ID })
          .then(client => client.security.encrypt(card))
          .then(card_hash => {
            this.card_hash = card_hash
            console.log(card_hash)
            this.loadVerifyCard = false
          })
        }
      },
      searchCredits: function() {
        this.loadingCredits = true
        apiV1Credit.page({ statusIn: ['PAID', 'SUCCESS'], page: 0, size: 100, orderBy: 'dateCreate:DESC'}).then(result => {
          this.shootingCreditPage = result.data
          this.loadingCredits = false
        })
      },
      saveCredit: function() {
        this.loadingSaveCredits = true
        var method = this.cards.length ? apiV1Credit.save(this.credit) : apiV1Credit.update(this.credit)
        method.then(() => {
          this.loadingSaveCredits = false
          this.confirmAction()
          this.getCards()
          this.searchCredits()
          this.step = 0
        }).catch(() => {
          this.loadingSaveCredits = false
        })
      },
      getCards: function() {
        this.loadingCards = true
        apiV1Card.list(this.credit).then(result => {
          this.loadingCards = false
          this.cards = result.data
          if(result.data[0]){
            this.step = 1
            this.credit.card = result.data[0]
          }
        }).catch(() => {
          this.loadingCards = false
        })
      }
    },
  }
</script>
<style scoped>
</style>