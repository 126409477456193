<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-card-text class="ml-0 pl-0 mr-0 pr-0 append-outer-no-margin-top">
          <v-text-field
            v-if="!dto"
            v-model="url"
            flat
            rounded
            color="white"
            :placeholder="$t('shortenToURLExample')"
            solo-inverted
            :rules="[rules.url]"
            class="lowercase"
            hide-details
            :readonly="loading"
          >
            <template v-slot:append>
              <v-slide-x-transition>
                <v-chip color="primary" v-if="loading">
                  <v-progress-circular x-small  indeterminate width="1" size="22"/>
                </v-chip>
              </v-slide-x-transition>
              <v-fade-transition>
                <v-tooltip left v-if="url && !isUrl(url) && !loading" disabled>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" @click="url = ''"><v-icon color="red">mdi-close</v-icon></v-btn>
                  </template>
                  <span>{{$t(`shortenAddress`)}}</span>
                </v-tooltip>
              </v-fade-transition>
            </template>
            <template v-slot:append-outer style="margin-top: auto;">
              <v-tooltip left v-if="url && isUrl(url) && !loading" disabled>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn rounded color="primary" @click="save" v-bind="attrs" v-on="on" large>{{$t(`shorten`)}}</v-btn>
                </template>
                <span>{{$t(`shortenAddress`)}}</span>
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field
            id="copy"
            color="primary"
            text-color="primary"
            v-else
            rounded
            :value="`${currentUrl}/${dto.code}`"
            :label="$t('shortenedUrl')"
            readonly
            outlined
            hide-details
            hide-actions
            dense
            @click="copy"
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="red" v-bind="attrs" v-on="on" @click="clear" small dark>
                    <v-icon small>mdi-restore</v-icon>
                  </v-chip>
                </template>
                <span>{{$t(`toClean`)}}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-card-text>
        <v-slide-y-transition>
          <v-card-actions v-if="dto" class="pr-0 pl-0">
            <v-fade-transition>
              <v-chip dark color="green" v-if="showCopy" small>
                {{$t('urlCopiedToClipboard')}}
              </v-chip>
            </v-fade-transition>
            <v-spacer/>
            <v-chip-group>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="primary" @click="copy" v-bind="attrs" v-on="on" class="mr-2"><v-icon>mdi-content-copy</v-icon></v-btn>
                </template>
                <span>{{$t(`copyUrl`)}}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <a :href="`${currentUrl}/${dto.code}`" target="_blank" v-bind="attrs" v-on="on" style="text-decoration: none;" class="mr-2">
                    <v-icon color="primary">mdi-navigation-variant</v-icon>
                  </a>
                </template>
                <span>{{$t(`viewUrl`)}}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="primary" v-bind="attrs" v-on="on" :disabled="!dto.user" @click="$router.push(`/painel/links/shortened/?onlineAddressId=${dto.id}&shortenedRecent=${dto.id}`), screenData.dashboard = true"><v-icon>mdi-chart-arc</v-icon></v-btn>
                </template>
                <span>{{$t(`toChart`)}}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" small icon @click="dialogQrcode = true" v-bind="attrs" v-on="on">
                    <v-icon>mdi-qrcode</v-icon>
                  </v-btn>
                </template>
                <span>QRCode</span>
              </v-tooltip>
            </v-chip-group>
          </v-card-actions>
        </v-slide-y-transition>
        <v-slide-y-reverse-transition>
          <v-card elevation="0" v-if="dto && (!dto.user || !dto.user.userHasSubscriptionPlans.filter(v => !v.subscriptionPlan.free)[0])">
            <!-- TODO show publi -->
          </v-card>
        </v-slide-y-reverse-transition>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogQrcode" width="300">
      <QRCodeMigly v-if="dto" :value="`${currentUrl}/${dto.code}`" :enableControlls="true"/>
    </v-dialog>
  </div>
</template>
<script>
import {apiV1OnlineAddresses} from "../../../service/resources/api-v1-online-addresses";
import QRCodeMigly from "./QRCodeMigly.vue";
  export default {
    name: 'UrlShortener',
    props: ['screenData'],
    components: {
    QRCodeMigly,
},
    data: () => ({
      showCopy: false,
      showInfo: true,
      dialogQrcode: false,
      url: null,
      dto: null,
      loading: false,
      currentUrl: null,
      rules: {url:false},
      option: 0
    }),
    watch: {

    },
    mounted: function() {
      this.currentUrl = `${window.location.protocol}//${window.location.host.replace('www.', '')}`;

      this.rules = {
        url: value => {
          return (this.isUrl(value)) || this.$t('invalidUrl')
        }
      }
    },
    computed: {

    },
    methods: {
      isUrl: function(value) {
        const pattern = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi
        // const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim
        return pattern.test(value)
      },
      save: function () {
        this.loading = true
        apiV1OnlineAddresses
        .post({url: this.url})
        .then(response => {
            this.dto = response.data
            this.loading = false
            setTimeout(() => {
              this.copy()
              //this.screenData.page.content.splice(0, 0, response.data)
              let query = JSON.parse(JSON.stringify(this.$route.query))

              if(this.screenData.auth && this.screenData.auth.user) {
                query.shortenedRecent = this.dto.id
                this.$router.push({path: `/painel/links/shortened`, query: query})
              }

              if((!response.data.openGraph || !response.data.openGraph.openGraphFields) && response.data.user && response.data.user.shorterProUserOrBetter) {
                this.$commons.updateSnackbar({show: true, color: 'info', text: this.$t('linkAparencePreview')})
              }else if (this.screenData.auth && this.screenData.auth.user && this.screenData.auth.user.shorterProUserOrBetter && !this.screenData.auth.user.config) {
                this.$commons.updateSnackbar({show: true, color: 'info', text: this.$t('configPageLoadInfo')})
              }
            }, 200);
          }
        ).catch(() => {
          this.loading = false
        })
      },
      copy: function () {
        let element = document.querySelector('#copy');
        element.setAttribute('type', 'text') 
        element.select()
        document.execCommand('copy');
        setTimeout(() => {
          this.showCopy = true
          setTimeout(() => {
            this.showCopy = false
          }, 2000);
        }, 500);
      },
      clear: function () {
        this.url = null
        this.dto = null
        this.$ga.event('btn', 'click', 'clear-url-shotner', 1)
      },
    },
  }
</script>
<style scoped>

.v-input__append-outer {
  margin-top: auto !important;
}
</style>