import http from "../axios";

let apiV1SmsShooting = {
	baseUrl: '/api/v1/sms-shootings',
	save: async (dto) => {
		return await http.post(`${apiV1SmsShooting.baseUrl}`, dto);
	},
	send: async (dto) => {
		return await http.post(`${apiV1SmsShooting.baseUrl}/send`, dto);
	},
	get: async (id) => {
		return await http.get(`${apiV1SmsShooting.baseUrl}/${id}`);
	},
	page: async (filter) => {
		return await http.get(`${apiV1SmsShooting.baseUrl}?page=${filter.page}&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.status ? `&status=${filter.status}` : ''}
		`);
	},
	delete: async (id) => {
		return await http.delete(`${apiV1SmsShooting.baseUrl}/${id}`);
	},
}

export  { apiV1SmsShooting }