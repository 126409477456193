import axios from "axios";

/* create axios factory */
let http = axios.create({
  baseURL: process.env.VUE_APP_API_V1_URL,
  timeout: 500000000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; multipart/form-data",
    "Accept-Language" : "pt-BR",
    "Access-Control-Allow-Origin" : "*"
  },
  handleError: true
});

/* handle default request  */
http.interceptors.request.use(
  function(config) {
    const expiration = localStorage.getItem("expiration");
    if(expiration) {
      if(new Date(expiration) > new Date()) {
        const authorization = localStorage.getItem("authorization");
        if (authorization) 
          config.headers.Authorization = `Bearer ${authorization}`;
      } else {
        localStorage.clear()
      }
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

/* handle default response  */
// http.interceptors.response.use(
//   response => response,
//   error => {
//     // Vue.prototype.$snackbar = {show: true, color: 'error', text: 'ERRRO'}
//     // if (error.response.status === 403) {
//     //   localStorage.clear()
//     // }
//     return Promise.reject(error);
//   }
// );

export default http;
