<template>
  <div>
    <v-card color="#128C7E" dark rounded="0" class="pt-5 pb-5">
      <v-container>
        <v-row>
          <v-col>
            <v-card-text>
              <v-text-field
                color="white"
                :label="$t('generateLinkWhatsapp')"
                outlined="false"
                type="tel"
                :readonly="loading"
                prepend-inner-icon="mdi-cellphone"
              >
              </v-text-field>
              <v-textarea
              rows="3"
              outlined
              :label="$t('generateLinkWhatsapp')"
              prepend-inner-icon="mdi-message"
              :readonly="loading"/>
            </v-card-text>
            <v-card-actions class="mt-0 pt-0 pr-4">
              <v-spacer/>
              <v-btn  dark outlined :loading="loading" @click="loading = true">{{$t('generateLinkWhatsapp')}}</v-btn>
            </v-card-actions>
          </v-col>
          <v-col>
            <v-slide-x-transition>
            <v-skeleton-loader v-if="loading" type="article, actions" light class="mr-2"/>
            </v-slide-x-transition>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
  import axios from "axios";
  export default {
    name: 'UrlShortener',
    props: ['screenData'],
    components: {
    },
    data: () => ({
      url: null,
      dto: null,
      loading: false,
      currentUrl: null,
      rules: null,
    }),
    mounted: function() {
      this.currentUrl = window.location.host;

      this.rules = {
        url: value => {
          return this.isUrl(value) || this.$t('invalidUrl')
        }
      }
    },
    computed: {

    },
    methods: {
      isUrl: function(value) {
        const pattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\\+\\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\\+\\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\\+~%\\/.\w-_]*)?\??(?:[-\\+=&;%@.\w_]*)#?(?:[\w]*))?)/
        return pattern.test(value)
      },
      save: function () {
        this.loading = true
        axios
        .post(`${process.env.VUE_APP_API_V1_URL}/api/v1/online-addresses`, {url: this.url})
        .then(response => {
            this.dto = response.data
            this.loading = false
            setTimeout(() => {
              this.copy()
            }, 200);
          }
        )
      },
      copy: function () {
        let element = document.querySelector('#copy');
        element.setAttribute('type', 'text') 
        element.select()
        document.execCommand('copy');
      },
      clear: function () {
        this.url = null
        this.dto = null
      }
    },
  }
</script>
<style scoped>
</style>