<template>
  <v-main>
    <v-row>
      <v-col cols="12">
        <TabBarOptions :tabs="tabs" />
      </v-col>
    </v-row>
    <router-view :screenData="screenData" ></router-view>
  </v-main>
</template>

<script>
import { apiV1Contacts } from '../../../service/resources/api-v1-contacts';
import TabBarOptions from '../fragments/TabBarOptions.vue';

export default {
    name: 'PainelSMSPage',
    components: {
    TabBarOptions
},
    props: ['screenData'],
    data: () => ({
      tabs: [
      {name: 'sms', path: '/painel/sms', alert: false},
      {name: 'contacts', path: '/painel/sms/contacts', alert: false}
      ]
    }),
    watch: {
      
    },
    computed: {
    },
    mounted: function() {
      this.searchContactsSaved()
      this.tabs[1].onChange =  this.searchContactsSaved
    },
    methods: {
      searchContactsSaved: function() {
        apiV1Contacts.page({page: 0, size: 1, status: 'SAVED'}).then(result => {
          this.tabs[1].alert = result.data.totalElements > 0
        })
      },
    },
  }
</script>
<style>
.number-complete .v-input__prepend-outer {
  margin-top: 0px !important;
}
</style>