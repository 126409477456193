import http from "../axios";

let apiV1UserSignPrivacyPolicy = {
	baseUrl: '/api/v1/user-sign-privacy-policy',
	save: async (dto) => {
		return await http.post(`${apiV1UserSignPrivacyPolicy.baseUrl}`, dto);
	},
	page: async (filter) => {
		return await http.get(`${apiV1UserSignPrivacyPolicy.baseUrl}
		?page=${filter.page}
		&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.privacyPolicyId ? `&privacyPolicyId=${filter.privacyPolicyId}` : ''}
		`);
	},
}

export  { apiV1UserSignPrivacyPolicy }