<template>
  <div>
    <v-dialog persistent v-model="dialog" width="900">
      <v-card class="card-corner-radius" color="grey lighten-3">
        <v-card-title class="primary--text pr-4 pl-4">
          <b>{{ $t('userSignTermsAndPrivacyPolicy') }}</b>
          <v-spacer/>
        </v-card-title>
        <v-card-text>
          <v-card outlined elevation="0" max-height="50vh" style="overflow: auto;">
            <v-card-text id="terms-of-use" v-if="termsOfUse" v-html="$t('termsOfUseHtml')[termsOfUse.name].replace('DATEUPDATE', new Date(`${termsOfUse.dateUpdate.replace('[UTC]', '')}`).toLocaleString())"></v-card-text>
            <v-card-text id="privacy-policy" v-if="privacyPolicy" v-html="$t('privacyPolicyHtml')[privacyPolicy.name].replace('DATEUPDATE', new Date(`${privacyPolicy.dateUpdate.replace('[UTC]', '')}`).toLocaleString())"></v-card-text>
          </v-card>
        </v-card-text>
        <v-card-text>
          <v-form v-model="formUserSignTermsAndPrivacyPolicy" :disabled="loading">
            <v-checkbox v-model="termsOfUseAccept" :true-value="termsOfUse" :false-value="null" class="pb-0 mb-0" v-if="termsOfUse" :rules="[v => !!v || $t('required')]">
              <template v-slot:label >
                {{ $t('accept') }} <a class="ml-1" href="#terms-of-use" @click.stop>{{ $t('termsOfUse') }}</a>.
              </template>
            </v-checkbox>
            <v-checkbox v-model="privacyPolicyAccept" :true-value="privacyPolicy" :false-value="null" v-if="privacyPolicy" :rules="[v => !!v || $t('required')]">
              <template v-slot:label>
                {{ $t('accept') }} <a class="ml-1" href="#privacy-policy"  @click.stop>{{ $t('privacyPolicy') }}</a>.
              </template>
            </v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          Exatamente Soluções - CNPJ - 41.228.429/0001-18.
          <v-btn rounded color="grey" v-if="false">
            {{ $t('refused') }}
          </v-btn>
          <v-spacer/>
          <v-btn rounded color="primary" :disabled="!formUserSignTermsAndPrivacyPolicy" @click="save" :loading="loading">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { apiV1TermsOfUse } from '../../../service/resources/api-v1-terms-of-use'
import { apiV1PrivacyPolicy } from '../../../service/resources/api-v1-privacy-policy'
import { apiV1UserSignTermsOfUse } from '../../../service/resources/api-v1-user-sign-terms-of-use'
import { apiV1UserSignPrivacyPolicy } from '../../../service/resources/api-v1-user-sign-privacy-policy'

  export default {
    name: 'UserSignTermsAndPrivacyPolicy',
    props: [],
    components: {
    },
    data: () => ({
      formUserSignTermsAndPrivacyPolicy: false,
      dialog: false,
      termsOfUseAccept: null,
      privacyPolicyAccept: null,
      termsOfUse: null,
      privacyPolicy: null,
      loading: false
    }),
    watch: {
    },
    mounted: function() {
      this.getTerms()
      this.getPolicy()
    },
    computed: {
    },
    methods: {
      getTerms: function() {
        apiV1TermsOfUse.page({page: 0, size: 1, orderBy: 'id:DESC'}).then(result => {
          if(result.data.content[0])
            apiV1UserSignTermsOfUse.page({termsOfUseId: result.data.content[0].id, page: 0, size: 1, orderBy: 'id:DESC'}).then(sign => {
              if(!sign.data.content[0]) {
                this.termsOfUse = result.data.content[0]
                this.dialog = true
              }
            })
        })
      },
      getPolicy: function() {
        apiV1PrivacyPolicy.page({page: 0, size: 1, orderBy: 'id:DESC'}).then(result => {
          if(result.data.content[0])
            apiV1UserSignPrivacyPolicy.page({privacyPolicyId: result.data.content[0].id, page: 0, size: 1, orderBy: 'id:DESC'}).then(sign => {
              if(!sign.data.content[0]) {
                this.privacyPolicy = result.data.content[0]
                this.dialog = true
              }
            })
        })
      },
      save: function() {
        this.loading = true
        if(this.termsOfUseAccept)
          apiV1UserSignTermsOfUse.save({termsOfUse: this.termsOfUseAccept})
        if(this.privacyPolicyAccept)
          apiV1UserSignPrivacyPolicy.save({privacyPolicy: this.privacyPolicyAccept})
        this.loading = false
        this.dialog = false
      }
    },
  }
</script>
<style scoped>
</style>