import http from "../axios";

let apiV1Contacts = {
	baseUrl: '/api/v1/contacts',
	get: async (id) => {
		return await http.get(`${apiV1Contacts.baseUrl}/${id}`);
	},
	optInRequest: async (id) => {
		return await http.patch(`${apiV1Contacts.baseUrl}/${id}/opt-in-request`);
	},
	optInRequestAll: async () => {
		return await http.patch(`${apiV1Contacts.baseUrl}/opt-in-request`);
	},
	optIn: async (dto) => {
		return await http.patch(`${apiV1Contacts.baseUrl}/opt-in`, dto);
	},
	optOut: async (dto) => {
		return await http.patch(`${apiV1Contacts.baseUrl}/opt-out`, dto);
	},
	save: async (dto) => {
		return await http.post(`${apiV1Contacts.baseUrl}`, dto);
	},
	saveAll: async (dtos) => {
		return await http.post(`${apiV1Contacts.baseUrl}/all`, dtos);
	},
	page: async (filter) => {
		return await http.get(`${apiV1Contacts.baseUrl}
		?page=${filter.page}
		&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.number ? `&number=${filter.number}` : ''}
		${filter.smsOptIn ? `&smsOptIn=${filter.smsOptIn}` : ''}
		${filter.code ? `&code=${filter.code}` : ''}
		${filter.tags ? `&tags=${filter.tags}` : ''}
		${filter.status ? `&status=${filter.status}` : ''}
		${filter.text ? `&text=${filter.text}` : ''}
		${filter.embedSegments ? `&embedSegments=${filter.embedSegments}` : ''}
		${filter.segmentIds ? `&segmentIds=${filter.segmentIds}` : ''}
		${filter.propertiesNames ? `&propertiesNames=${filter.propertiesNames}` : ''}
		${filter.shootingIdNotEquals ? `&shootingIdNotEquals=${filter.shootingIdNotEquals}` : ''}
		${filter.contactIdNotIn ? `&contactIdNotIn=${filter.contactIdNotIn}` : ''}
		${filter.segmentsExclusive ? `&segmentsExclusive=${filter.segmentsExclusive}` : ''}
		${filter.tagsExclusive ? `&tagsExclusive=${filter.tagsExclusive}` : ''}
		${filter.propertiesExclusive ? `&propertiesExclusive=${filter.propertiesExclusive}` : ''}
		`);
	},
	pageTags: async (filter) => {
		return await http.get(`${apiV1Contacts.baseUrl}/tags
		?page=${filter.page}
		&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.number ? `&number=${filter.number}` : ''}
		${filter.tagLike ? `&tagLike=${filter.tagLike}` : ''}
		`);
	},
	pageProperties: async (filter) => {
		return await http.get(`${apiV1Contacts.baseUrl}/properties
		?page=${filter.page}
		&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.number ? `&number=${filter.number}` : ''}
		${filter.nameStartWith ? `&nameStartWith=${filter.nameStartWith}` : ''}
		`);
	}
}

export  { apiV1Contacts }