<template>
  <v-menu 
    offset-y 
    left
    v-model="menu"
    :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        icon
        class="mr-4"
        small

      >
        <v-icon v-if="menu">mdi-help-circle</v-icon>
        <v-icon v-else>mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>
    <v-card elevation="0" class="rounded-lg" width="300">
      <v-card-actions>
        <v-btn icon color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer/>
        <b>{{ $t('help') }}</b>
        <v-spacer/>
        <v-btn icon color="#7E8EA6" @click="menu = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider class="mr-4 ml-4"/>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title style="white-space: unset;" class="primary--text">
              <b>{{ $t('helpTitle').title }}</b>
            </v-list-item-title>
            <v-list-item-subtitle style="white-space: unset;" class="description--text" v-html="$t('helpDescription')"></v-list-item-subtitle>
            <v-list-item-subtitle style="white-space: unset;">
              <v-card-actions class="ma-0 pa-0">
                <v-spacer/>
                <a class="link" href="emailto:contato@exatamente-solucoes.com">
                  {{$t('helpAction')}}
                </a>
              </v-card-actions>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>

</template>
<script>
  export default {
    name: 'Help',
    props: ['screenData'],
    components: {
    },
    data: () => ({
      menu: false,
    }),
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
  }
</script>
<style scoped>
</style>