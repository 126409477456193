<template>
  <div v-if="data.address">
    <v-card elevation="0" rounded>
      <v-card-actions>
        <v-btn icon color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer/>
        <b>{{ $t('previewLink') }}</b>
        <v-spacer/>
        <v-btn icon color="#7E8EA6" @click="JSON.stringify(data.address.openGraph) == JSON.stringify(aux) ? data.dialog = false : confimCancel.dialog = true">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider class="ma-4 mt-0"/>
      <v-card elevation="0"  max-height="90vh" class="overflow-auto">
        <v-card-subtitle><b>{{ $t('opengraphEditTitle') }}</b></v-card-subtitle>
        <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('opengraphEditDescription') }}</v-card-subtitle>
  
        <v-card-text>
          <v-card outlined elevation="0" class="rounded-lg">
            <v-tabs full-width fixed-tabs v-model="tabSelect">
              <v-tabs-slider></v-tabs-slider>
              <v-tab :value="tab" style="text-transform:capitalize" v-for="tab, index in tabs" :key="index">
                {{ $t(tab) }}
              </v-tab>
            </v-tabs>
          </v-card>
        </v-card-text>
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="8">
            <v-card-text v-if="data.address.openGraph">
              <v-window v-model="tabSelect">
                <v-window-item :value="index" v-for="tab, index in tabs" :key="index">
                  <v-card-subtitle class="description--text mt-0 pt-0">{{ $t(`${tab}Desc`) }}</v-card-subtitle>
                  <v-form v-model="formOG" :disabled="screenData.auth && !screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO'].includes(v.subscriptionPlan.name))[0]">
                    <div v-for="(field, index) in openGraphs[0][tab] ? openGraphs[0][tab] : data.address.openGraph.openGraphFields.map(f => f.name)" :key="`open-graph-field-${index}`">
                      <div v-if="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0]">
                        <div v-if="['og:image', 'og:image:url', 'og:image:secure_url'].includes(field)">
                          <div>
                            <v-card-subtitle class="ml-0 pl-0"><b>{{ $t(field) }}</b></v-card-subtitle>
                            <v-card-actions >
                              <v-card elevation="0" outlined class="pa-1"  width="100" min-height="50">
                                <v-img v-if="!data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].deleted" :src="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value" contain>
                                </v-img> 
                              </v-card>
                              <v-spacer/>
                              <v-btn rounded color="primary" @click="$refs[`imageOgfileInput_${tab}_${field}_${index}`][0].$refs.input.click()" :disabled="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
                                <v-icon color="white">mdi-upload-circle</v-icon>
                              </v-btn>
                              <v-btn color="primary" outlined rounded class="ml-2" @click="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].deleted = true" v-if="!data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].deleted" :disabled="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <v-file-input
                                x-small
                                class="ml-3"
                                :ref="`imageOgfileInput_${tab}_${field}_${index}`"
                                accept="image/*"
                                dark
                                color="primary"
                                hide-input
                                hide-details
                                hide-actions
                                prepend-icon="mdi-file-upload"
                                icon
                                density="density"
                                rounded
                                flat
                                dense
                                @change="handleCrop($event, cropImageData[field], data.address.openGraph.openGraphFields.filter(f => f.name == field)[0])"
                                v-show="false"
                              />
                              <CropImage :data="cropImageData[field]" :object="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0]" field="value"  :stencilSize="{width: 1200, height: 630}" :maxSizeInMB="5"/>
                            </v-card-actions>
                          </div>
                        </div>
                        <v-select
                          v-else-if="['og:locale'].includes(field)"
                          v-model="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value"
                          :items="locales"
                          filled
                          rounded
                          :label="$t(field)"
                          color="primary"
                          :item-text="(locale) => `${locale.tag} (${locale.name} ${locale.location ? locale.location : ''})`"
                          :item-value="(locale) => locale.tag"
                          @change="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].update = true, data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].deleted = false"
                        >
                          <template v-slot:prepend-inner>
                          </template>
                        </v-select>
                        <v-select
                          v-else-if="['og:type'].includes(field)"
                          v-model="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value"
                          :items="[
                            {value: 'application', text: $t('application')},
                            {value: 'audio', text: $t('audio')},
                            {value: 'image', text: $t('image')},
                            {value: 'message', text: $t('message')},
                            {value: 'multipart', text: $t('multipart')},
                            {value: 'text', text: $t('text')},
                            {value: 'video.other', text: $t('video')},
                            {value: 'website', text: $t('website')},
        
                            {value: 'article', text: $t('article')},
                            {value: 'video.other', text: $t('video')},
                            {value: 'product', text: $t('product')},
                            {value: 'profile', text: $t('profile')},
                            {value: 'music', text: $t('music')},
                            {value: 'game', text: $t('game')},
                            ]"
                          filled
                          rounded
                          :label="$t(field)"
                          item-text="text"
                          item-value="value"
                          color="primary"
                          @change="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].update = true, data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].deleted = false"
                        >
                          <template v-slot:prepend-inner>
                          </template>
                        </v-select>
                        <v-select
                          v-else-if="['og:image:type'].includes(field)"
                          v-model="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value"
                          :items="[
                            'image/jpeg', // Para imagens no formato JPEG
                            'image/png',  // Para imagens no formato PNG
                            'image/gif',  // Para imagens no formato GIF
                            'image/webp'  // Para imagens no formato WebP
                          ]"
                          filled
                          rounded
                          :label="$t(field)"
                          color="primary"
                          @change="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].update = true, data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].deleted = false"
                        >
                          <template v-slot:prepend-inner>
                          </template>
                        </v-select>
                        <v-select
                          v-else-if="['og:video:type'].includes(field)"
                          v-model="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value"
                          :items="[
                            'video/mp4',   // Para vídeos no formato MP4
                            'video/webm',  // Para vídeos no formato WebM
                            'video/ogg'    // Para vídeos no formato Ogg
                          ]"
                          filled
                          rounded
                          :label="$t(field)"
                          color="primary"
                          @change="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].update = true, data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].deleted = false"
                        >
                          <template v-slot:prepend-inner>
                          </template>
                        </v-select>
                        <v-select
                          v-else-if="['og:audio:type'].includes(field)"
                          v-model="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value"
                          :items="[
                            'audio/mp3',
                            'audio/mpeg',  // Para arquivos de áudio no formato MP3
                            'audio/ogg',   // Para arquivos de áudio no formato Ogg
                            'audio/wav'    // Para arquivos de áudio no formato WAV
                          ]"
                          filled
                          rounded
                          :label="$t(field)"
                          color="primary"
                          @change="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].update = true, data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].deleted = false"
                        >
                          <template v-slot:prepend-inner>
                          </template>
                        </v-select>
                        <v-textarea
                          v-else-if="field.includes('description')"
                          v-model="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value"
                          filled
                          rounded
                          dense
                          :label="$t(field)"
                          :rules="field.includes('url') ? [rules.url] : []"
                          color="primary"
                          counter
                          maxlength="200"
                          rows="3"
                          no-resize
                          @change="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].update = true, data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].deleted = false"
                          >
                          <template v-slot:prepend-inner>
    
                          </template>
                          <template v-slot:append>
                          </template>
                        </v-textarea>
                        <v-text-field 
                          v-else-if="field.includes('width') || field.includes('height')"
                          v-model="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value"
                          filled
                          rounded
                          dense
                          :label="$t(field)"
                          color="primary"
                          type="number"
                          @change="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].update = true, data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].deleted = false"
                          >
                          <template v-slot:prepend-inner>
                          </template>
                          <template v-slot:append v-if="field.includes('video') && field.includes('url')">
                            <v-tooltip bottom max-width="500">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>
                                {{ $t('copyYoutubeUrl') }}
                              </span>
                            </v-tooltip>
                          </template>
                        </v-text-field>
                        <div v-else-if="['og:video', 'og:video:secure_url', 'og:audio', 'og:audio:secure_url'].includes(field)">
                          <div>
                            <v-card-subtitle class="ml-0 pl-0"><b>{{ $t(field) }}</b></v-card-subtitle>
                            <v-card-actions >
                              <v-card elevation="0" outlined class="pa-1" width="100" min-height="50">
                                <div v-if="!data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].deleted && data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value">
                                  <iframe
                                    v-if="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value.includes('youtube')"
                                    :src="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value"
                                    width="100%"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                  ></iframe>
                                  <video
                                    v-else-if="['og:video', 'og:video:secure_url'].includes(field)"
                                    controls
                                    :src="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value"
                                    width="100%"
                                  >
                                    Seu navegador não suporta a tag de vídeo.
                                  </video>
                                  <audio
                                    v-else
                                    controls
                                    :src="audioSrc"
                                    width="100%"
                                  >
                                    Seu navegador não suporta a tag de áudio.
                                  </audio>
                                </div>
                              </v-card>
                              <v-spacer/>
                              <v-btn rounded color="primary" @click="$refs[`ogfileInput_${tab}_${field}_${index}`][0].$refs.input.click()" :disabled="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
                                <v-icon color="white">mdi-upload-circle</v-icon>
                              </v-btn>
                              <v-btn color="primary" outlined rounded class="ml-2" @click="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].deleted = true" v-if="!data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].deleted" :disabled="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <v-file-input
                                :ref="`ogfileInput_${tab}_${field}_${index}`"
                                filled
                                rounded
                                dense
                                :label="$t(field)"
                                @change="setFile($event, data.address.openGraph.openGraphFields.filter(f => f.name == field)[0])"
                                prepend-icon=""
                                :accept="field.includes('video') ? 'video/*' : 'audio/*'"
                                v-show="false"
                              >
                              </v-file-input>
                            </v-card-actions>
                          </div>
                        </div>
                        <v-text-field 
                          v-else
                          v-model="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value"
                          filled
                          rounded
                          dense
                          :label="$t(field)"
                          :rules="field.includes('url') ? [rules.url] : []"
                          color="primary"
                          counter
                          maxlength="200"
                          @change="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].update = true, data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].deleted = false"
                          >
                          <template v-slot:prepend-inner>
                          </template>
                          <template v-slot:append v-if="field.includes('video') && field.includes('url')">
                            <v-tooltip bottom max-width="500">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>
                                {{ $t('copyYoutubeUrl') }}
                              </span>
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </div>
                    </div>
                  </v-form>
                </v-window-item>
              </v-window>
            </v-card-text>
            <v-card-actions v-if="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO'].includes(v.subscriptionPlan.name))[0]">
              <v-spacer/>
              <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData" :top="true"/>
            </v-card-actions>
            <v-card-actions v-else>
              <v-spacer/>
              <v-btn rounded :disabled="(screenData.auth && screenData.auth.user.bellowShorterProUser) || !formOG" color="primary" @click="saveOG" :loading="loadingSave">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4">
            <v-card-text>
              <v-card elevation="0" height="100%" min-height="400" color="#252F3F">
                <WhattsAppView :address="data.address"/>
              </v-card>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
    <v-dialog v-model="planData.dialogPlansAndPrices" width="800">
      <v-card>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="planData.dialogPlansAndPrices = false" icon><v-icon color="red">mdi-close</v-icon></v-btn>
        </v-card-actions>
        <v-card-text>
          <SubscriptionAndPlans :screenData="screenData"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmOperation :data="confimCancel" :confimAction="() => {data.dialog = false, data.address.openGraph = aux}" />
  </div>
</template>
<script>
import { apiV1OpenGraph } from '../../../service/resources/api-v1-open-graph';
import { apiV1OpenGraphFields } from '../../../service/resources/api-v1-open-graph-fields';
  import ConfirmOperation from './ConfirmOperation.vue';
  import IconInfoSubscriblePlan from './IconInfoSubscriblePlan.vue'
  import SubscriptionAndPlans from './SubscriptionAndPlans.vue';
  import WhattsAppView from './WhattsAppView.vue';
  import CropImage from './CropImage.vue';
  import * as locale from 'locale-codes'
  export default {
    name: 'OpenGraphEdit',
    props: ['data', 'screenData'],
    components: {
        IconInfoSubscriblePlan,
        SubscriptionAndPlans,
        ConfirmOperation,
        CropImage,
        WhattsAppView
    },
    data: () => ({
      confimCancel: {dialog: false},
      planData: {
        dialogPlansAndPrices: false
      },
      rules: {url:false},
      tabs: ['basics', 'optionals' ,'images', 'videos', 'audios', 'articles', 'books', 'products', 'all'],
      openGraphs: [
        {
          basics: ['og:type', 'og:title', 'og:url', 'og:image'], 
          optionals: ['og:description', 'og:site_name', 'og:locale', 'og:determiner'], 
          images: ['og:image:url', 'og:image:secure_url', 'og:image:type', 'og:image:width', 'og:image:height'], 
          videos: ['og:video:type', 'og:video', 'og:video:secure_url', 'og:video:width', 'og:video:height'], 
          audios: ['og:audio:type', 'og:audio', 'og:audio:secure_url'], 
          articles: ['article:modified_time', 'article:expiration_time', 'article:author', 'article:section', 'article:tag'], 
          books: ['book:author', 'book:isbn', 'book:release_date', 'book:tag'], 
          products: ['product:availability', 'product:price:amount', 'product:price:currency', 'product:retailer_item_id'] 
        },
      ],
      tabSelect: 0,
      loadingSave: false,
      formOG: false,
      aux: null,
      cropImageData: {
        'og:image' : {dialog: false, img: null},
        'og:image:url' : {dialog: false, img: null},
        'og:image:secure_url' : {dialog: false, img: null}
      },
      locales: []
    }),
    watch: {
      'data.dialog': function(value) {
        if(value) {
          this.tabSelect = this.tabs[0]
          this.prepare()
          this.aux = JSON.parse(JSON.stringify(this.data.address.openGraph))
          this.$forceUpdate()
        }
      },
    },
    mounted: function() {
      this.rules = {
        url: value => {
          return (this.isUrl(value)) || this.$t('invalidUrl')
        }
      }
      this.locales = locale.all
    },
    computed: {
    },
    methods: {
      setFile: function(event, data) {
        var reader = new FileReader()
        reader.readAsDataURL(event)
        reader.onload = function () {
          data.deleted = false
          data.update = true
          data.value = reader.result
        }
      },
      handleCrop: function(event, data, field){
        var reader = new FileReader()
        reader.readAsDataURL(event)
        if(reader.size > 2000000) {
          this.$commons.updateSnackbar({show: true, color: 'error', text: this.$t('invalid')})
          return
        }
        reader.onload = function () {
          data.img = {src: reader.result}
          data.dialog = true
          field.deleted = false
          field.update = true
        }
      },
      isUrl: function(value) {
        const pattern = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi
        // const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim
        return pattern.test(value)
      },
      prepare: function() {
        if(!this.data.address.openGraph)
          this.data.address.openGraph = {openGraphFields: []}
        if(!this.data.address.openGraph.openGraphFields)
          this.data.address.openGraph.openGraphFields = []
        this.data.address.openGraph.onlineAddress = {id: this.data.address.id}
        this.tabs.forEach(tab => {
          (this.openGraphs[0][tab] ? this.openGraphs[0][tab] : []).forEach(og => {
            if(!this.data.address.openGraph.openGraphFields.filter(f => f.name == og)[0])
              this.data.address.openGraph.openGraphFields.push({name: og, value: '', deleted: false, update: false})
          })
        })
      },
      saveOG : async function() {
        this.loadingSave = true
        apiV1OpenGraph.post(this.data.address.openGraph).then(result => {
          this.data.address.openGraph.id = result.data.id
          //this.prepare()
          this.data.address.openGraph.openGraphFields.filter(f => f.update).forEach((f, index) => {
            f.openGraph = {id: result.data.id}
            f.update = false
            apiV1OpenGraphFields.post(f).then(result => {
              f.id = result.data.id
              if(index >= this.data.address.openGraph.openGraphFields.filter(f => f.update).length -1) {
                this.loadingSave = false
                this.data.dialog = false
                this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('saved')})
              }
            })
          }).catch(() => {
            this.loadingSave = false
          })
        }).catch(() => {
          this.loadingSave = false
        })
      },
    },
  }
</script>
<style scoped>
</style>