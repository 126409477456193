import http from "../axios";

let apiV1SubscriptionPlanInclude = {
	baseUrl: '/api/v1/subscriptions-plans-include',
	get: async (id) => {
		return await http.get(`${apiV1SubscriptionPlanInclude.baseUrl}/${id}`);
	},
	page: async (filter) => {
		return await http.get(`${apiV1SubscriptionPlanInclude.baseUrl}?page=${filter.page}&size=${filter.size}${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}${filter.active ? `&active=${filter.active}` : ''}`);
	}
}

export  { apiV1SubscriptionPlanInclude }