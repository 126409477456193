<template>
  <div v-if="data.address">
    <v-dialog v-model="data.dialog" width="600" persistent :fullscreen="['xs', 'sm'].includes($vuetify.breakpoint.name)">
      <v-card v-if="data.address" class="card-corner-radius" color="grey lighten-3">
        <v-row no-gutters>
          <v-col cols="12" sm="12">
            <v-card-title class="primary--text">
              <v-btn icon color="primary" @click="JSON.stringify(data.address) == JSON.stringify(aux) ? data.dialog = false : confimCancel.dialog = true">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <b>{{$t(`editLink`)}}</b>
              <v-spacer/>
              <v-btn text color="primary" @click="confimDelete.dialog = true">
                <v-icon>mdi-delete</v-icon>
                <span>{{$t(`delete`)}}</span>
              </v-btn>
            </v-card-title>
            <v-card-subtitle class="primary--text">
              {{$t(`updateAddressInfo`)}}
            </v-card-subtitle>
            <v-card-text>
              <v-card :color="`${screenData.auth && screenData.auth.user.bellowShorterProUser ? 'grey lighten-2' : 'white'}`" elevation="1">
                <v-card-subtitle v-if="screenData.auth && screenData.auth.user.bellowShorterProUser">
                  <v-icon>mdi-crown-outline</v-icon>
                  {{$t('resourceOnlyPlan')}} <b>{{$t('plan_PRO')}}</b>            
                </v-card-subtitle>
                <v-form v-model="formAddress">
                  <v-card-text>
                    <v-text-field 
                      v-model="data.address.code"
                      :disabled="screenData.auth && screenData.auth.user.bellowShorterProUser"
                      outlined
                      rounded
                      hide-details
                      hide-actions
                      dense
                      color="primary"
                      @keyup="data.address.code ? verifyCode() : null"
                      >
                      <template v-slot:prepend-inner>
                        <v-icon class="mr-1" v-if="screenData.auth && screenData.auth.user.bellowShorterProUser">
                          mdi-lock
                        </v-icon>
                        <v-icon class="mr-1" v-else>
                          mdi-link
                        </v-icon>
                        <span class="mt-1">
                          {{host}}/
                        </span>
                      </template>
                      <template v-slot:append>
                        <v-slide-x-transition hide-on-leave leave-absolute>
                          <v-progress-circular indeterminate size="25" width="1" color="primary" v-if="loadingVerifyCode"/>
                          <v-icon v-else-if="canUseCode" color="green">mdi-check</v-icon>
                          <v-icon v-else-if="!canUseCode" color="red">mdi-cancel</v-icon>
                        </v-slide-x-transition>
                      </template>
                    </v-text-field>
                  </v-card-text>
                  <v-card-text>
                    <v-textarea
                      class="mt-2" 
                      :label="$t('originalAddress')"
                      v-model="data.address.url"
                      :disabled="screenData.auth && screenData.auth.user.bellowShorterProUser"
                      outlined
                      hide-actions
                      dense
                      color="primary"
                      rows="3"
                      :rules="[rules.url]"
                      @keyup="data.address.error = false"
                    >
                      <template v-slot:prepend-inner>
                        <v-icon class="mr-1 ml-3" v-if="screenData.auth && screenData.auth.user.bellowShorterProUser">
                          mdi-lock
                        </v-icon>
                        <a :href="data.address.url" class="link" target="_blank" v-else>
                          <v-icon color="primary" class="mr-1 ml-3">mdi-open-in-new</v-icon>
                        </a>
                      </template>
                      <template v-slot:append>
                      </template>
                    </v-textarea>
                    <v-card color="warning" style="padding: 1px;" v-if="data.address.error">
                      <v-card>
                        <v-card-subtitle>
                          <v-icon color="warning">mdi-alert</v-icon>
                          {{$t('thisLinkError')}} {{new Date(data.address.lastCheck.replace('[UTC]' , '')).toLocaleString()}}
                        </v-card-subtitle>
                        <v-card-subtitle>{{ $t('possibleCauses') }}</v-card-subtitle>
                        <v-card-text>
                          <p v-for="item in $t('linkErrorCauses')" :key="item">
                            - {{ item }}
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-card>
                  </v-card-text>
  
                </v-form>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn color="primary" @click="save" :loading="loadingSave" rounded :disabled="(screenData.auth && screenData.auth.user.bellowShorterProUser) || loadingVerifyCode || !canUseCode || !formAddress">
                <span>{{$t(`save`)}}</span>
                <v-icon>mdi-save</v-icon>
              </v-btn>
            </v-card-actions>

            <v-card class="ma-4" v-if="false">
              <v-card-title>
                <b>{{ $t('tags') }}</b>
              </v-card-title>
              <v-card-text>
                <v-chip :readonly="loadingSave" close class="mr-1 mb-1" v-for="(tag, index) in data.address.tags.split(`||`)" :key="index" v-show="tag" @click:close="data.address.tags = data.address.tags.trim().replace(`, ${tag}`, ``).replace(tag, ``)">{{tag}}</v-chip>
                <v-chip :readonly="loadingSave" outlined close close-icon="mdi-check">
                  <v-text-field 
                    v-model="tagAux"
                    flat
                    solo
                    color="transparent"
                    hide-details
                    :readonly="loadingSave"
                    @blur="data.address.tags = `${data.address.tags.trim()}||${tagAux.trim()}`, tagAux =''"
                    />
                </v-chip>
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" @click="save" :loading="loadingSave" rounded>
                  <span>{{$t(`save`)}}</span>
                  <v-icon>mdi-save</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>

          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <ConfirmOperation :data="confimCancel" :confimAction="() => {data.dialog = false, Object.assign(data.address, aux)}" />
    <ConfirmOperation :data="confimDelete" :confimAction="deleteConfirm" />
  </div>
</template>
<script>
import { apiV1OnlineAddresses } from '../../../service/resources/api-v1-online-addresses';
import ConfirmOperation from './ConfirmOperation.vue';

  export default {
    name: 'LinkEdit',
    props: ['data', 'screenData'],
    components: {
    ConfirmOperation
},
    data: () => ({
      confimCancel: {dialog: false},
      confimDelete: {dialog: false},
      loadingSave: false,
      host: null,
      formAddress: false,
      aux: null,
      loadingVerifyCode: false,
      canUseCode: true,
    }),
    watch: {
      'data.dialog': function(value) {
        if(value)
          this.aux = JSON.parse(JSON.stringify(this.data.address))
        this.canUseCode = true
      },
    },
    mounted: function() {
      this.host = `${window.location.protocol}//${window.location.host.replace('www.', '')}`;
      this.rules = {
        url: value => {
          return (this.isUrl(value)) || this.$t('invalidUrl')
        }
      }
    },
    computed: {
    },
    methods: {
      isUrl: function(value) {
        const pattern = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi
        // const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim
        return pattern.test(value)
      },
      verifyCode: function() {
        this.loadingVerifyCode = true
        this.canUseCode = true
        apiV1OnlineAddresses.get(this.data.address.code).then(response => {
          if(response.data) {
            this.canUseCode = response.data.id == this.data.address.id
          } else {
            this.canUseCode = true
          }
        }).finally(()=>{
          this.loadingVerifyCode = false
        })
      },
      save: function() {
        this.loadingSave = true
        apiV1OnlineAddresses.put(this.data.address).then(response => {
          if(response.data) {
            Object.assign(this.data.address, response.data) 
            this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('saved')})
            this.data.dialog = false
          }
        }).finally(() => {
          this.loadingSave = false
        })
      },
      deleteConfirm: function() {
        this.loadingDelete = true
        apiV1OnlineAddresses.delete(this.data.address.id).then(response => {
          if(response) {
            this.data.dialog = false
            this.confimDelete.dialog = false
            this.screenData.page.content.forEach((item, index) => {
              if(item.id == this.data.address.id)
                this.screenData.page.content.splice(index, 1);
            })
            this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('deleted')})
          }
        })
      }
    },
  }
</script>
<style scoped>
</style>