<template>
  <v-main class="full-height full-width">
    <center>
      <v-progress-circular indeterminate class="verticle-center horizontaly-center" width="2"/>
    </center>
  </v-main>
</template>

<script>
  export default {
    name: 'LoadingAuth',
    components: {
    },
    data: () => ({

    }),
    computed: {

    },
    mounted: function() {
      if(this.$route.query.authorization) {
        localStorage.authorization = this.$route.query.authorization
        localStorage.expiration = new Date(new Date().getTime() + + (5 * 60 * 60) * 3000).toISOString()
        this.$router.replace({'query': null});

        if(localStorage.route) {
          this.$router.push(localStorage.route)
          .setItem('route' , null)
        }else {
          this.$router.push('/painel/links/shortened')
        }
      } else if(localStorage.authorization) {
        this.$router.push('/painel/links/shortened')
      } else {
        this.$router.push('/')
      }
    },
    methods: {
    },
  }
</script>
<style>

</style>