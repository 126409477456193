<template>
  <v-card elevation="0" color="transparent" height="100%">
    <v-fade-transition leave-absolute>
      <v-skeleton-loader v-if="!bio" type="card-avatar, article, list-item-three-line, actions"/>
      <v-card :id="`section-0`" elevation="0" :color="bio.backgroundColor" v-else min-height="100%" :class="`${getFontClass()} ${['xs' , 'sm'].includes($vuetify.breakpoint.name) ? 'rounded-0' : getShape().borderRadius}`">
        <v-app-bar color="transparent" elevation="0" v-if="bio.onlineAddress.code" class="pa-1 pl-4 app-bar-share" :id="`section-share`">
          <v-btn rounded color="white" small>
            <ShareOptions :onlineAddress="bio.onlineAddress"/>
          </v-btn>
        </v-app-bar>
        <div :id="`section-2`" />
        <div v-if="1 == bio.layout">
          <v-img :id="`section-cover`" :src="bio.cover && !bio.cover.deleted ? bio.cover.src : ''" min-height="100%" min-width="100%" style="position: absolute;" :class="`${['xs' , 'sm'].includes($vuetify.breakpoint.name) ? 'rounded-0' : getShape().borderRadius}`"></v-img>
          <v-app-bar color="transparent" elevation="0">
            <v-btn rounded color="transparent" small elevation="0">
            </v-btn>
          </v-app-bar>
          <div absolute class="gradient" width="100%" height="100%" :class="`${['xs' , 'sm'].includes($vuetify.breakpoint.name) ? 'rounded-0' : getShape().borderRadius}`"/>
          <v-row no-gutters>
            <v-col cols="12">
              <v-card-actions class="pt-5 pb-0 mt-5 mb-0">
                <v-spacer/>
                <v-card class="rounded-card" :color="bio.headerColor" elevation="0">
                  <v-card-text class="pa-1">
                    <v-avatar size="140">
                      <v-img :id="`section-picture`" :src="bio.picture && !bio.picture.deleted ? bio.picture.src : null"/>
                    </v-avatar>
                  </v-card-text>
                </v-card>
                <v-spacer/>
              </v-card-actions>
            </v-col>
            <v-col cols="12">
              <v-row no-gutters>
                <v-col>
                  <v-card color="transparent" elevation="0">
                    <v-card-title class="bio-name" :id="`section-name`" :style="`color: ${bio.titleColor}`">
                      <v-spacer/>
                      {{bio.name}}
                      <v-spacer/>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions/>
          <v-card-text>
            <v-card elevation="0" class="mt-2 mb-2 rounded-lg" color="transparent">
              <v-card class="box-description rounded-lg"/>
              <v-card color="transparent" elevation="0" class="rounded-lg">
                <v-card-subtitle class="bio-description" :id="`section-description`" :style="`color: ${bio.descriptionColor}`">
                  {{bio.description}}
                </v-card-subtitle>
              </v-card>
            </v-card>
          </v-card-text>
        </div>
        <div v-else-if="2 == bio.layout">
          <v-card elevation="0" class="rounded-0" min-height="250" :color="bio.headerColor" :class="`${['xs' , 'sm'].includes($vuetify.breakpoint.name) ? 'rounded-0' : getShape().borderRadius}`">
            <v-img :id="`section-cover`" :src="bio.cover && !bio.cover.deleted ? bio.cover.src : '/images/transparent.png'" min-height="250" min-width="100%" max-height="350">
              <div class="inverted-border-radius" ref="borderRadiusElement"></div>
            </v-img>
            <v-footer absolute color="transparent">
              <v-spacer/>
              <v-card class="rounded-card" :color="bio.headerColor" elevation="0">
                <v-card-text class="pa-1">
                  <v-avatar size="140">
                    <v-img :id="`section-picture`" :src="bio.picture && !bio.picture.deleted ? bio.picture.src : null"/>
                  </v-avatar>
                </v-card-text>
              </v-card>
              <v-spacer/>
            </v-footer>
          </v-card>
          <h1 :class="`text-center ma-4 ${getFontClass()}`" :style="`color: ${bio.titleColor}`" :id="`section-name`">
            {{ bio.name }}
          </h1>
          <h3 :class="`ml-4 mr-4 text-center ${getFontClass()}`" :style="`color: ${bio.descriptionColor}`" :id="`section-description`">
            {{ bio.description }}
          </h3>
        </div>
        <div v-else-if="3 == bio.layout">
          <v-card elevation="0" :color="bio.headerColor" :class="`${['xs' , 'sm'].includes($vuetify.breakpoint.name) ? 'rounded-0' : getShape().borderRadius}`" min-height="250">
            <v-img :id="`section-cover`" :src="bio.cover && !bio.cover.deleted ? bio.cover.src : ''" min-height="250" min-width="100%" max-height="600">
            </v-img>
            <v-footer absolute color="transparent">
              <v-card class="rounded-card" :color="bio.headerColor" elevation="0">
                <v-card-text class="pa-1">
                  <v-avatar size="150">
                    <v-img :id="`section-picture`" :src="bio.picture && !bio.picture.deleted ? bio.picture.src : null"/>
                  </v-avatar>
                </v-card-text>
              </v-card>
              <v-spacer/>
            </v-footer>
          </v-card>
          <h1 :class="`text-left ma-4 ${getFontClass()}`" :style="`color: ${bio.titleColor}`" :id="`section-name`">
            {{ bio.name }}
          </h1>
          <h3 :class="`ml-4 mr-4 text-left ${getFontClass()}`" :style="`color: ${bio.descriptionColor}`" :id="`section-description`">
            {{ bio.description }}
          </h3>
        </div>
        <div v-else-if="4 == bio.layout">
          <v-card elevation="0" :color="bio.headerColor" :class="`${['xs' , 'sm'].includes($vuetify.breakpoint.name) ? 'rounded-0' : getShape().borderRadius}`" min-height="350">
            <v-img :id="`section-cover`" :src="bio.cover && !bio.cover.deleted ? bio.cover.src : ''" min-height="350" min-width="100%" max-height="600">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-app-bar color="transparent" elevation="0">
                    <v-btn rounded color="transparent" small elevation="0">
                    </v-btn>
                  </v-app-bar>
                </v-col>
                <v-col cols="12">
                  <h1 :class="`text-center ma-4 ${getFontClass()} bio-name-layout-4`" :style="`color: ${bio.titleColor}`" :id="`section-name`">
                    {{ bio.name }}
                  </h1>
                </v-col>
              </v-row>
            </v-img>
            <v-footer absolute color="transparent">
              <v-spacer/>
              <v-card class="rounded-card" :color="bio.headerColor" elevation="0">
                <v-card-text class="pa-1">
                  <v-avatar size="150">
                    <v-img :id="`section-picture`" :src="bio.picture && !bio.picture.deleted ? bio.picture.src : null"/>
                  </v-avatar>
                </v-card-text>
              </v-card>
              <v-spacer/>
            </v-footer>
          </v-card>
          <h2 :class="`ml-4 mr-4 text-center ${getFontClass()}`" :style="`color: ${bio.descriptionColor}`" :id="`section-description`">
            {{ bio.description }}
          </h2>
        </div>


        <v-footer elevation="0" color="transparent" :absolute="!bio.placementSocialLinksOnTop" :id="`section-link-social`">
          <v-spacer/>
          <v-chip-group column>
            <v-spacer/>
            <v-chip large v-for="link in bio.socialLinks.filter(v => !v.deleted)" :key="link.id" color="transparent"  @click="goTo(link.onlineAddress)">
              <v-icon :color="bio.socialIconsColor" large>{{ $t('socialOptions').filter(v => v.name == link.title)[0].icon }}</v-icon>
            </v-chip>
            <v-spacer/>
          </v-chip-group>
          <v-spacer/>
        </v-footer>
        <div :id="`section-1`" />
        <v-card-text v-for="(biographyHasOnlineAddress, index) in bio.biographyHasOnlineAddresses.filter(i => !i.deleted)" :key="`link-icon-only-${index}`" :id="`section-link-${index}`">
          <v-card :class="`${getShape().borderRadius} ${getShadow()}`" @click="goTo(biographyHasOnlineAddress.onlineAddress)" :style="getShape().outlined ? `border: 1px solid ${bio.sectionColor}` : ''" :color="getShape().outlined ? bio.backgroundColor : bio.sectionColor" elevation="0">
            <v-list-item v-if="!biographyHasOnlineAddress.type || biographyHasOnlineAddress.type == 'SAMPLE_LINK'" :style="{color: bio.sectionTextColor}" :class="getShadow()">
              <v-list-item-avatar v-if="biographyHasOnlineAddress.icon">
                <v-avatar :color="bio.sectionTextColor" >
                  <v-icon :color="bio.sectionColor">{{ biographyHasOnlineAddress.icon }}</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content :class="biographyHasOnlineAddress.icon ? '' : 'text-center'">
                <v-list-item-title v-if="biographyHasOnlineAddress.title" class="all-text" :style="{color: bio.sectionTextColor}"><b>{{ biographyHasOnlineAddress.title }}</b></v-list-item-title>
                <v-list-item-subtitle v-if="biographyHasOnlineAddress.description" class="all-text" :style="{color: bio.sectionTextColor}">{{biographyHasOnlineAddress.description}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else-if="biographyHasOnlineAddress.type == 'IMAGE'" :style="{color: bio.sectionTextColor}" :class="`${getShadow()} ma-0 pa-0`">
              <v-list-item-content :class="`ma-0 pa-0 ${getShape().borderRadius}`">                
                <v-img :src="biographyHasOnlineAddress.image.src" :aspect-ratio="biographyHasOnlineAddress.aspectRatio ? biographyHasOnlineAddress.aspectRatio : 1" :class="`${getShape().borderRadius}`"></v-img>
                <v-card-title v-if="biographyHasOnlineAddress.title"><b>{{ biographyHasOnlineAddress.title }}</b></v-card-title>
                <v-card-subtitle v-if="biographyHasOnlineAddress.description">{{ biographyHasOnlineAddress.description }}</v-card-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else-if="biographyHasOnlineAddress.type == 'YOUTUBE'" :style="{color: bio.sectionTextColor}" :class="`${getShadow()} ma-0 pa-0`">
              <v-list-item-content :class="`ma-0 pa-0 ${getShape().borderRadius}`">                
                <iframe
                  :src="convertToEmbedUrl(biographyHasOnlineAddress.onlineAddress.url)"
                  width="100%"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  height="250"
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else-if="biographyHasOnlineAddress.type == 'WHATSAPP'" :style="{color: bio.sectionTextColor}" :class="`${getShadow()}`">
              <v-list-item-avatar>
                <v-avatar :color="bio.sectionTextColor" >
                  <v-icon :color="bio.sectionColor">mdi-whatsapp</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle v-if="biographyHasOnlineAddress.onlineAddress.tags" class="all-text" :style="{color: bio.sectionTextColor}"><b>{{biographyHasOnlineAddress.onlineAddress.tags}}</b></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div v-else-if="biographyHasOnlineAddress.type == 'INSTAGRAM'" :style="{color: bio.sectionTextColor}" :class="`${getShadow()}`">
              <v-card-actions>
                <v-spacer/>
                <v-avatar :color="bio.sectionTextColor" size="100">
                  <v-img :src="biographyHasOnlineAddress.image && !biographyHasOnlineAddress.image.deleted ? biographyHasOnlineAddress.image.src : null"/>
                </v-avatar>
                <v-spacer/>
              </v-card-actions>
              <v-card-subtitle class="text-center mt-0 pt-0 mb-0 pb-0" :style="{color: bio.sectionTextColor}">
                {{ biographyHasOnlineAddress.description }}
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer/>
                <v-chip :color="bio.sectionColor" @click="biographyHasOnlineAddress.onlineAddress.url" class="all-text" :style="{color: bio.sectionTextColor}">
                  <v-icon small class="mr-1">mdi-instagram</v-icon>
                  <label cla>{{ $t('follow') }} <b>@{{ biographyHasOnlineAddress.title }}</b></label>
                </v-chip>
                <v-spacer/>
              </v-card-actions>
            </div>

          </v-card>
        </v-card-text>
        <v-footer elevation="0" color="transparent" v-if="!bio.placementSocialLinksOnTop">
          <v-spacer/>
          <v-chip-group column>
            <v-spacer/>
            <v-chip v-for="link in bio.socialLinks.filter(v => !v.deleted)" :key="link.id" color="transparent" >
              <v-icon color="transparent">mdi-instagram</v-icon>
            </v-chip>
            <v-spacer/>
          </v-chip-group>
          <v-spacer/>
        </v-footer>
        <v-footer color="transparent" class="pa-0" absolute v-if="bio.onlineAddress.user && !bio.onlineAddress.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
          <v-spacer/>
          <v-card elevation="0" flat color="transparent">
            <v-card-actions class="white--text">
              <v-spacer/>
              <a href="/" target="_blank">
                <v-btn rounded class="text-none" elevation="5">
                  <img src="/images/logo/logo-02.png" width="25" height="25" class="mr-2"/> 
                  {{ $t('createYoutMiglyPage') }}
                </v-btn>
              </a>
              <v-spacer/>
            </v-card-actions>
          </v-card>
          <v-spacer/>
        </v-footer>
        <v-footer color="transparent">
          <v-btn color="transparent" elevation="0" readonly/>
        </v-footer>
      </v-card>
    </v-fade-transition>
  </v-card>
</template>
<script>
import { apiV1OpenGraph } from "../../../service/resources/api-v1-open-graph"
import ShareOptions from './ShareOptions.vue';
  export default {
    name: 'BiographySmartphoneView',
    props: ['bio'],
    components: {
    ShareOptions
},
    data: () => ({
      loading: true,
      host: null

    }),
    watch: {
      'bio.backgroundColor': function(color) {
        this.updateBgColor(color)
      },
      'bio': function(bio) {
        setTimeout(() => {
          this.updateBgColor(bio.backgroundColor)
        }, 1)
      },
      'bio.layout': function() {
        setTimeout(() => {
          this.updateBgColor(this.bio.backgroundColor)
        }, 1);
      },
    },
    mounted: function() {
      if(this.bio) {
        this.updateBgColor(this.bio.backgroundColor)

      }
      setTimeout(() => {
        this.loading = false
        // this.bio.biographyHasOnlineAddresses.filter(v => ['INSTAGRAM'].includes(v.type)).forEach(v => {
        //   this.loadOpenGraph(v)
        // })
        this.updateBgColor(this.bio.backgroundColor)
      }, 1000);
      this.host = `${window.location.protocol}//${window.location.host.replace('www.', '')}`;
    },
    computed: {
      
      
    },
    methods: {
      getFontClass: function () {
        return [
                      {id: 1, font: 'roboto'},
                      {id: 2, font: 'open-sans'},
                      {id: 3, font: 'lora'},
                      {id: 4, font: 'montserrat'},
                      {id: 5, font: 'merriweather'},
                      {id: 6, font: 'raleway'},
                      {id: 7, font: 'noto-serif-oriya'},
                      {id: 8, font: 'architects-daughter'},
                      {id: 9, font: 'permanent-marker-regular'},
                      ].filter(v => v.id == this.bio.font)[0].font
      },
      getShape: function() {
        return [
          {id: 1, borderRadius: 'rounded-0', outlined: true},
          {id: 2, borderRadius: 'rounded-sm', outlined: true},
          {id: 3, borderRadius: 'rounded', outlined: true},
          {id: 5, borderRadius: 'rounded-xl', outlined: true},
          {id: 6, borderRadius: 'rounded-0', outlined: false},
          {id: 7, borderRadius: 'rounded-sm', outlined: false},
          {id: 8, borderRadius: 'rounded', outlined: false},
          {id: 9, borderRadius: 'rounded-xl', outlined: false},
        ].filter(v => v.id == this.bio.shape)[0]
      },
      getShadow: function() {
        return [
          {id: 1, shadow: 'sombra-leve'},
          {id: 2, shadow: 'sombra-interna'},
          {id: 3, shadow: 'sombra-difusa'},
          {id: 4, shadow: 'sombra-intensa'},
        ].filter(v => v.id == this.bio.shadow)[0].shadow
      },
      updateBgColor: function(color) {
        if(this.$refs.borderRadiusElement && this.$refs.borderRadiusElement.style)
          this.$refs.borderRadiusElement.style.setProperty('--bg-color', color);
      },
      loadOpenGraph: async function(item) {
        item.loading = true
        this.prepareOG(item)
        this.$forceUpdate()
        try {

          const response = (await apiV1OpenGraph.tags({source: item.onlineAddress.url})).data;

          item.onlineAddress.openGraph.openGraphFields.forEach(v => {v.deleted = true, v.update = true})
          for (const key in response) {
            if(item.onlineAddress.openGraph.openGraphFields.filter(v => v.name == key)[0]) {
              item.onlineAddress.openGraph.openGraphFields.filter(v => v.name == key)[0].value = response[key]
              item.onlineAddress.openGraph.openGraphFields.filter(v => v.name == key)[0].deleted = false
              item.onlineAddress.openGraph.openGraphFields.filter(v => v.name == key)[0].update = true
            } else {
              item.onlineAddress.openGraph.openGraphFields.push({name: key, value: response[key], deleted: false, update: true})
            }
          }
          item.loading = false
          this.$forceUpdate()
        } catch (error) {
          this.$commons.updateSnackbar({show: true, color: 'error', text: this.$t('errorLinkLoad')})
          item.loading = false
          this.$forceUpdate()
        }
      },
      prepareOG: function(item) {
        if(!item.onlineAddress.openGraph)
          item.onlineAddress.openGraph = {openGraphFields: []}
        if(!item.onlineAddress.openGraph.openGraphFields)
          item.onlineAddress.openGraph.openGraphFields = []
        item.onlineAddress.openGraph.onlineAddress = {id: item.onlineAddress.id}
      },
      isFacebook: function(address) {
        return address.url.includes('facebook')
      },
      isYoutube: function(address) {
        return address.url.includes('youtube') || address.url.includes('youtu.be')
      },
      isInstagram: function(address) {
        return address.url.includes('instagram')
      },
      isLinkedin: function(address) {
        return address.url.includes('linkedin')
      },
      isWhatsapp: function(address) {
        return address.url.includes('whatsapp')
      },
      isEmail: function(address) {
        return address.url.includes('@')
      },
      isEducation: function(address) {
        return address.url.includes('lattes') || address.url.includes('buscatextual.cnpq')
      },
      isTwitter: function(address) {
        return address.url.includes('twitter')
      },
      isYoutubePreview: function(address) {
        return address.url.includes('watch')
      },
      goTo(address) {
        if(address.code) {
          let route = this.$router.resolve({ path: `/${address.code}` });
          window.open(route.href);
        }else if(address.url) {
          window.open(address.url); 
        }
      },
      convertToEmbedUrl: function(youtubeUrl) {
        // Verifica se a URL é válida
        const urlPattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
        if (!youtubeUrl.match(urlPattern)) {
            return null;
        }

        // Extrai o ID do vídeo da URL
        const videoIdPattern = /(?:youtube\.com\/(?:[^\\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
        const match = youtubeUrl.match(videoIdPattern);

        if (match && match[1]) {
            return `https://www.youtube.com/embed/${match[1]}`;
        }

        return null;
      },
    },
  }
</script>
<style>
</style>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
.cover-content {
  /* background-image: linear-gradient(red, yellow); */
  background-image: linear-gradient(rgba(255,0,0,0), rgba(0,0,0,1));
}
.gradient{
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0,0,0,1));
}

.gradient-content{
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(rgba(255, 0, 0, 0), rgb(43, 43, 43));
}

.box-description{
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 25px;
}
.bio-name {
  font-size: 2em;
  font-style: normal;
  /*color: white !important;*/
  /* -webkit-text-stroke: 0.2px #000; */
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
}

.bio-name-layout-4 {
  /* -webkit-text-stroke: 0.2px #000; */
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
}

.bio-description{
  font-style: normal;
  font-size: 1.3em;
   /*color: white !important;*/
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
  /* text-align: center; */
}

.card-rouded{
  border-radius: 25px 25px 25px 25px!important;
}

.card-link{
  font-size: 1.2em;
  padding: 0.2em;
}

.bio-header {
  position: absolute;
  z-index: auto;
}


.inverted-border-radius {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.inverted-border-radius::after {
    content: '';
    position: absolute;
    bottom: -10%; /* ajuste a altura conforme necessário */
    left: 0;
    width: 100%;
    height: 60%; /* ajuste a altura conforme necessário */
    background-color: var(--bg-color); /* a mesma cor do fundo do contêiner pai */
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
}

.v-card__title {
  display: block;
}

.sombra-leve {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
.sombra-intensa {
    box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.5);
}
.sombra-difusa {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}
.sombra-interna {
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.2);
}
.app-bar-share {
  position: absolute;  
  z-index: 999;
}
</style>