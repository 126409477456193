<template>
  <div>
    <v-bottom-sheet v-model="requerideCookies" hide-overlay persistent>
      <v-card elevation="0" outlined>
        <v-card-text>
          <v-row>
            <v-col cols="12" xs="12" sm="8" md="6">
              <v-card-text>
                <label v-html="$t('cookiesRequiredInfo')"></label>
              </v-card-text>
            </v-col>
            <v-col cols="12" xs="12" sm="8" md="2">
              <v-btn text color="primary" class="text-none center" block @click="managePreferences">
                {{ $t('manageCookiesPreferencies') }}
              </v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="8" md="2">
              <v-btn color="primary" outlined class="text-none center" block @click="rejectAllCookies">
                {{ $t('rejectCookiesPreferencies') }}
              </v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="8" md="2">
              <v-btn color="primary" class="text-none center" block @click="acceptAllCookies">
                {{ $t('acceptAllCookiesPreferencies') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>   
    <v-dialog width="800" v-model="dialog" persistent>
      <v-card elevation="0">
        <v-card-actions>
          <img src="/images/logo/logo-04.png" alt="migly" width="80"/> 
          <v-spacer/>
          <v-btn icon color="#7E8EA6" @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider class="mr-4 ml-4 mb-4"/>
        <v-card-subtitle><b>{{ $t('manageCookiesTitle') }}</b></v-card-subtitle>
        <v-card-subtitle class="description--text mt-0 pt-0" v-html="$t('manageCookiesDescription')"></v-card-subtitle>

        <v-card-text v-for="field, index in ['necessary', 'analytics', 'advertising', 'research']" :key="field">
          <v-switch
            v-model="preferences[field]"
            inset
            :label="$t(`cookie_${field}`)"
            :disabled="index == 0"
          />
          <label class="description--text">
            {{ $t(`cookie_${field}_desc`) }}
          </label>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="confirm()" width="55%" class="rounded-lg text-none">
            {{ $t('toConfirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
  export default {
    name: 'Cookies',
    props: [],
    components: {
    },
    data: () => ({
      dialog: false,
      requerideCookies: true,
      cookiesAccepted: false,
      preferences: {
        necessary: true,
        analytics: true,
        advertising: false,
        research: true
      }
    }),
    mounted: function() {
      this.setId()
      this.cookiesAccepted = !!this.$cookies.get('preferences');
      if(this.cookiesAccepted)
        this.requerideCookies = false
    },
    computed: {

    },
    methods: {
      acceptAllCookies() {
        this.$cookies.set('preferences', JSON.stringify({
          necessary: true,
          analytics: true,
          advertising: true,
          research: true
        }), { expires: 365 });
        this.cookiesAccepted = true;
        this.requerideCookies = false;
        this.dialog = false
      },
      rejectAllCookies() {
        this.$cookies.set('preferences', JSON.stringify({
          necessary: true,
          analytics: false,
          advertising: false,
          research: false
        }), { expires: 365 });
        this.cookiesAccepted = true;
        this.requerideCookies = false;
        this.dialog = false
      },
      managePreferences() {
        this.dialog = true
      },
      confirm: function() {
        this.$cookies.set('preferences', JSON.stringify(this.preferences), { expires: 365 });
        this.cookiesAccepted = true;
        this.requerideCookies = false;
        this.dialog = false
      },
      setId: function() {
        if(!this.$cookies.get('_migly'))
          this.$cookies.set('_migly', uuidv4());
      }
    },
  }
</script>
<style scoped>
</style>