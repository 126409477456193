import http from "../axios";

let apiV1TermsOfUse = {
	baseUrl: '/api/v1/terms-of-use',
	page: async (filter) => {
		return await http.get(`${apiV1TermsOfUse.baseUrl}
		?page=${filter.page}
		&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.status ? `&status=${filter.status}` : ''}
		`);
	},
}

export  { apiV1TermsOfUse }