<template>
  <v-container >
    <div class="verticle-center" v-if="!success">
      <v-card v-if="loadingGet" width="600" elevation="0">
        <v-card-actions>
          <v-spacer/>
          <v-progress-circular indeterminate color="primary" width="2"/> 
          <v-spacer/>
        </v-card-actions>
      </v-card>
      <v-card elevation="0" outlined v-else width="600">
        <v-card-title class="primary--text">
          <b>{{ $t('optInAcceptTitle') }} {{ smsConfig.business }}</b>
        </v-card-title>
        <v-card-subtitle class="primary--text">
          {{ $t('last4NumberDigits') }}
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer/>
          <v-otp-input
            v-model="last4NumberDigits"
            :length="4"
            :disabled="loading"
            @finish="onFinish"
            type="number"
          />
          <v-spacer/>
        </v-card-actions>
        <v-fade-transition>
          <v-card-actions v-if="loading">
            <v-spacer/>
            <v-progress-circular indeterminate color="primary" width="2"/>
            <v-spacer/>
          </v-card-actions>
        </v-fade-transition>
      </v-card>
    </div>
    <v-overlay v-model="success" v-if="smsConfig">
      <v-alert type="success" color="primary">
        <div v-html="$t('confirmOptInMessage').replace(/_/gi, smsConfig.business)"/>
      </v-alert>
    </v-overlay>
  </v-container>
</template>


<script>
import { apiV1Contacts } from '../service/resources/api-v1-contacts'
import { apiV1SmsConfig } from '../service/resources/api-v1-sms-configs'
  export default {
    name: 'SmsOptIn',
    components: {
    },
    data: () => ({  
      loading: false,
      loadingGet: true,
      last4NumberDigits: '',
      smsConfig: null,
      success: false
    }),
    mounted: function() {
      this.getAccept()
    },
    methods: {
      onFinish () {
        this.loading = true
        apiV1Contacts.optIn({smsOptInCode: this.$route.params.code, last4NumberDigits: this.last4NumberDigits}).then(() => {
          this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('success')})
          this.success = true
        }).catch(() => { 
          this.loading = false
        })
      },
      getAccept: function() {
        this.loadingGet = true
        apiV1SmsConfig.getOptIn(this.$route.params.code).then((result) => {
          if(result.data) {
            this.smsConfig = result.data
            this.loadingGet = false
          } else {
            this.$router.push('/')
          }
        }).catch(() => { 
          this.loading = false
        })
      }
    },
  }
</script>
<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 600px;
  }
}

@media (min-width: 960px) {
  .container {
      max-width: 600px;
  }
}
</style>