<template>
  <v-card elevation="0">
    <v-tabs full-width fixed-tabs v-model="tab" @change="changeTab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab :value="tab.path" style="text-transform:capitalize" v-for="tab, index in tabs" :key="index">
        <div>{{ $t(tab.name) }}</div>
        <v-menu offset-y centered v-if="tab.alert">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              dark
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-alert-circle</v-icon>
            </v-btn>
          </template>
          <v-card color="warning" style="padding: 1px" v-if="false">
            <v-card>
              <v-card-title>
                <b>{{ $t('linksErrorTitle') }}</b>
              </v-card-title>
              <v-card-subtitle>
                {{$t('linksErrorVerify')}}
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer/>
                <v-btn rounded color="primary" dark @click="verifyLinks()">
                  {{$t('toCheck')}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card>
        </v-menu>
      </v-tab>
    </v-tabs>
  </v-card>
</template>
<script>

  export default {
    name: 'TabBarOptions',
    props: ['change', 'tabs'],
    components: {
    },
    data: () => ({
      tab: 0,
    }),
    watch: {
      '$route': function() {
        this.tab = this.tabs.map(t => t.path).indexOf(this.$route.path)
      },
    },
    mounted: function() {
      this.tab = this.tabs.map(t => t.path).indexOf(this.$route.path)
    },
    computed: {
    },
    methods: {
      changeTab: function() {
        this.$router.push(this.tabs[this.tab].path)
        if(this.tabs[this.tab].onChange)
          this.tabs[this.tab].onChange()
      }
    },
  }
</script>
<style scoped>
</style>