import http from "../axios";

let apiV1UserSignTermsOfUse = {
	baseUrl: '/api/v1/user-sign-terms-of-use',
	save: async (dto) => {
		return await http.post(`${apiV1UserSignTermsOfUse.baseUrl}`, dto);
	},
	page: async (filter) => {
		return await http.get(`${apiV1UserSignTermsOfUse.baseUrl}
		?page=${filter.page}
		&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.termsOfUseId ? `&termsOfUseId=${filter.termsOfUseId}` : ''}
		`);
	},
}

export  { apiV1UserSignTermsOfUse }