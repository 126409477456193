<template>
  <v-sheet>
    <v-card-actions>
      <v-btn icon color="white">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer/>
      <b>{{ $t('filters') }}</b>
      <v-spacer/>
      <v-btn icon color="#7E8EA6" @click="data.dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-actions>
    <v-divider class="mr-4 ml-4"/>
    <v-card elevation="0" max-height="85vh" class="overflow-auto">
      <v-card-subtitle><b>{{ $t('search') }}</b></v-card-subtitle>
      <v-card-text>
        <v-text-field
          v-model="data.filter.text"
          placeholder="exatamente-se.com"
          filled
          rounded
          dense
          hide-details
          hide-actions
          @change="filterUpdate()"
          @blur="filterUpdate()"
        />
      </v-card-text>
      <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('searchLinksDesc') }}</v-card-subtitle>
      <v-card-subtitle><b>{{ $t('domains') }}</b></v-card-subtitle>
      <v-card-text>
        <v-chip-group show-arrows column>
          <v-btn class="ml-1 mb-1 text-lowercase" :color="d.domain != data.filter.domain ? '#7E8EA6' : 'primary'" rounded :outlined="d.domain != data.filter.domain" dark v-for="d in domainsPage.content" :key="d.domain" @click="d.domain != data.filter.domain ? data.filter.domain = d.domain : data.filter.domain = null, filterUpdate()" small>
            {{d.domain.replace('https://', '').replace('http://', '')}} ({{ d.total }})
          </v-btn>
          <v-btn v-if="!domainsPage.last" color="#7E8EA6" icon rounded small class="ml-1 mb-1" @click="findDomains(domainsPage.number + 1, true)" :loading="loadingDomains">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-chip-group>
      </v-card-text>
      <v-card-subtitle><b>{{ $t('situation') }}</b></v-card-subtitle>
      <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('situationDesc') }}</v-card-subtitle>
      <v-card-text>
        <v-checkbox
          v-for="item in [{id : '1', text: 'irregular'}, {id : '0', text: 'regular'}, {id : '3', text: 'notVerified'}]" :key="item.id"
          v-model="data.filter.errorIn"
          :label="$t(item.text)"
          :value="item.id"
          flat
          dense
          @change="filterUpdate()"
          :disabled="(!!data.filter.errorIn.filter(v => ['3'].includes(v))[0] && ['1', '0'].includes(item.id)) || (!!data.filter.errorIn.filter(v => ['0', '1'].includes(v)).length && ['3'].includes(item.id))"
        ></v-checkbox>
      </v-card-text>
      <v-card-subtitle><b>{{ $t('addressType') }}</b></v-card-subtitle>
      <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('addressTypeDesc') }}</v-card-subtitle>
      <v-card-text>
        <div v-for="item in [
              {id : 'API', text: 'API'}, 
              {id : 'CHROME_EXTENSION', text: 'CHROME_EXTENSION'}, 
              {id : 'STANDARD', text: 'STANDARD'},
              {id : 'BIO', text: 'BIO'},
              {id : 'SMS', text: 'SMS'},
              {id : 'INFOPRODUCT', text: 'INFOPRODUCT'},
          ]" :key="item.id">
            <v-checkbox
              v-model="data.filter.typeIn"
              :label="$t('addressType_'+item.text).toUpperCase()"
              :value="item.id"
              flat
              dense
              hide-details
              @change="filterUpdate()"
            ></v-checkbox>
          <label class="description--text">
            {{ $t('addressTypeDesc_'+item.text) }}
          </label>
        </div>
      </v-card-text>
      <v-card-subtitle><b>{{ $t('creationDate') }}</b></v-card-subtitle>
      <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('creationDateDesc') }}</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-menu
              ref="startAt"
              v-model="menuStartAt"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              left
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="data.filter.dateCreateIni ? new Date(`${data.filter.dateCreateIni}T00:00:00`).toLocaleDateString() : null"
                  :label="$t('startAt')"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  rounded
                  flat
                  dense
                  clearable
                  @click:clear="data.filter.dateCreateIni = null, filterUpdate()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.filter.dateCreateIni"
                :active-picker.sync="activePickerStart"
                :max="data.filter.dateCreateEnd ? data.filter.dateCreateEnd : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                min="1950-01-01"
                @change="menuStartAt = false,filterUpdate()"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu
              ref="endAt"
              v-model="menuEndAt"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              left
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="data.filter.dateCreateEnd ? new Date(`${data.filter.dateCreateEnd}T23:59:59`).toLocaleDateString() : null"
                  :label="$t('endAt')"
                  append-icon="mdi-calendar-end"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  rounded
                  flat
                  dense
                  clearable
                  @click:clear="data.filter.dateCreateEnd = null, filterUpdate()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.filter.dateCreateEnd"
                :active-picker.sync="activePickerEnd"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                :min="data.filter.dateCreateIni"
                @change="menuEndAt = false,filterUpdate()"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-subtitle><b>{{ $t('views') }}</b></v-card-subtitle>
      <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('viewsDesc') }}</v-card-subtitle>
      <v-card-text>
        <v-switch
          v-model="data.filter.withClicks"
          inset
          :label="$t('withClicks')"
          @change="v => {v ? data.filter.withoutClicks = null : '', filterUpdate()}"
        />
        <label class="description--text">
          {{ $t('withClicksDesc') }}
        </label>
        <v-fade-transition leave-absolute>
          <v-date-picker
            full-width
            v-if="data.filter.withClicks"
            v-model="data.filter.clickDateRange"
            range
            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
            @change="filterUpdate()"
          />
        </v-fade-transition>
        <v-switch
          v-model="data.filter.withoutClicks"
          inset
          :label="$t('withoutClicks')"
          @change="v => {v ? data.filter.withClicks = null : '', filterUpdate()}"
        />
        <label class="description--text">
          {{ $t('withoutClicksDesc') }}
        </label>
        <v-fade-transition leave-absolute>
          <v-date-picker
            full-width
            v-if="data.filter.withoutClicks"
            v-model="data.filter.clickDateRange"
            range
            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
            @change="filterUpdate()"
          />
        </v-fade-transition>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="white">
        </v-btn>
        <v-spacer/>
      </v-card-actions>
      <v-footer color="transparent" fixed>
        <v-container>
          <v-card color="white" elevation="0">
            <v-card-actions>
              <v-btn outlined color="primary" @click="clearFilter()" width="40%" class="rounded-lg text-none">
                {{ $t('clear') }}
              </v-btn>
              <v-spacer/>
              <v-btn color="primary" @click="applyFilter()" width="55%" class="rounded-lg text-none" :loading="loadingFilterPreviewResults">
                {{ $t('apply') }}
                <label class="ml-4">
                  <small>({{ pageFilterPreviewResults.totalElements }} {{ !['xs', 'sm'].includes($vuetify.breakpoint.name) ? $t('results') : '' }})</small>
                </label>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-footer>
    </v-card>
  </v-sheet>
</template>
<script>
import {apiV1OnlineAddresses} from "../../../service/resources/api-v1-online-addresses";

  export default {
    name: 'LinksFilter',
    props: ['screenData', 'data', 'search'],
    components: {

    },
    data: () => ({
      domainsPage: {
        content: [],
      },
      loadingDomains: false,
      menuStartAt: false,
      menuEndAt: false,
      activePickerStart: null,
      activePickerEnd: null,
      loadingFilterPreviewResults: true,
      pageFilterPreviewResults: {
        totalElements: 0
      },
    }),
    mounted: function() {
      this.findDomains(0)
      this.filterUpdate()
    },
    computed: {

    },
    methods: {
      findDomains: function(page, add){
        this.loadingDomains = true
        apiV1OnlineAddresses.pageDomain({page: page, size: 5, orderBy: 'total:desc'}).then(response => {
          if(add) {
             let merged = [...this.domainsPage.content, ...response.data.content.filter(item => this.domainsPage.content.map(v => v.domain).indexOf(item.domain) < 0)]
             this.domainsPage = response.data
             this.domainsPage.content = merged
          }else {
            this.domainsPage = response.data
          }
          this.loadingDomains = false
        })
      },
      clearFilter: function(){
        this.data.filter = {
          domain: null,
          errorIn: [],
          typeIn: [],
          dateCreateIni: null,
          dateCreateEnd: null,
          withClicks: null,
          withoutClicks: null,
          clickDateRange: []
        }
        this.filterUpdate()
      },
      applyFilter: function(){
        if(this.data.filter.clickDateRange && this.data.filter.clickDateRange.length == 1) {
          this.data.filter.clickDateRange.push(this.data.filter.clickDateRange[0])
        }
        this.data.dialog = false
        this.data.filterApply = JSON.parse(JSON.stringify(this.data.filter))
        this.$router.push({ query: { 
          page: 0, 
          text: this.data.filter.text,
          domain: this.data.filter.domain, 
          errorIn: this.data.filter.errorIn, 
          typeIn: this.data.filter.typeIn,
          dateCreateIni: this.data.filter.dateCreateIni, 
          dateCreateEnd: this.data.filter.dateCreateEnd,
          withClicks: this.data.filter.withClicks,
          withoutClicks: this.data.filter.withoutClicks,
          clickDateRange: this.data.filter.clickDateRange,
          orderBy: JSON.stringify(this.data.filter.orderBy)
        }})
        this.search(0)
      },
      filterUpdate : function() {
        this.loadingFilterPreviewResults = true
        if(this.data.filter.clickDateRange && this.data.filter.clickDateRange.length == 1) {
          this.data.filter.clickDateRange.push(this.data.filter.clickDateRange[0])
        }
        apiV1OnlineAddresses.page({
          page: 0,
          size: 1,
          text: this.data.filter.text,
          domain: this.data.filter.domain,
          errorIn:this.data.filter.errorIn,  
          typeIn: this.data.filter.typeIn, 
          dateCreateIni: this.data.filter.dateCreateIni? `${new Date(`${this.data.filter.dateCreateIni}T00:00:00`).toISOString()}`  : null, 
          dateCreateEnd: this.data.filter.dateCreateEnd ? `${new Date(`${this.data.filter.dateCreateEnd}T23:59:59`).toISOString()}`  : null, 
          withClicks: this.data.filter.withClicks,
          withoutClicks: this.data.filter.withoutClicks, 
          clickDateRange: this.data.filter.clickDateRange.map((v, index) => index ? `${new Date(`${v}T23:59:59`).toISOString()}` : `${new Date(`${v}T00:00:00`).toISOString()}`),
          embedBiography: false, 
        }).then(response => {
          this.loadingFilterPreviewResults = false
          this.pageFilterPreviewResults = response.data
        })
      }
    }
  }
</script>
<style scoped>
</style>