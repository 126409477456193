<template>
  <div>
    <v-dialog v-model="data.dialog" width="600" persistent max-height="100vh" class="overflow-auto">
      <v-card elevation="0" v-if="data.img">
        <v-card-actions>
          <v-btn icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer/>
          <b>{{ $t('cropImageTitle') }}</b>
          <v-spacer/>
          <v-btn icon color="#7E8EA6" @click="close" :disabled="loading">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider class="ma-4 mt-0"/>
        <div>
          <v-card-text>
            <v-card elevation="0" outlined class="card-corner-radius" >
              <cropper
                :disabled="loading"
                :src="data.img.src"
                @change="change"
                ref="cropper"
                :stencil-props="{
                  aspectRatio: 1, // Define a razão de aspecto do retângulo de corte
                  maxWidth: stencilSize.width, // Define a largura máxima do retângulo de corte
                  maxHeight: stencilSize.height, // Define a altura máxima do retângulo de corte
                }"
                >
              </cropper>
            </v-card>
          </v-card-text>
        </div>
        <v-footer absolute color="transparent">
          <v-spacer/>
          <v-btn color="primary" @click="confirm(result)" rounded :loading="loading"> 
            {{ $t('confirm') }}
          </v-btn>
        </v-footer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { Cropper } from 'vue-advanced-cropper'
  import 'vue-advanced-cropper/dist/style.css';
  import Pica from 'pica';
  export default {
    name: 'CropImage',
    props: ['data', 'actionSave', 'stencilSize', 'object', 'field', 'maxSizeInMB'],
    components: {
      Cropper
    },
    watch:{
      'data.dialog' : function(dialog) {
        if(dialog){
          this.result = {src: this.data.img}
          setTimeout(() => {
            this.zoom(false)
          }, 1500)
        }
      }
    },
    data: () => ({
      imageRestriction: 'none',
      result: null,
      loading: false
    }),
    mounted: function() {
    },
    computed: {
    },
    methods: {
      zoom(value) {
        this.$refs.cropper.zoom(value);
      },
      change({ coordinates, canvas, image }) {
        console.log(coordinates, canvas, image)
        if(!this.result)
          this.result = {src: canvas.toDataURL()}
        else
          this.result.src = canvas.toDataURL()
      },
      close: function(){
        this.data.dialog = false
      },
      async confirm(img) {
        console.log('original: ' + this.base64SizeInMB(img.src))
        this.result = img
        if(this.maxSizeInMB) {
          this.loading = true
          do{
            this.result.src = await this.reduceImageQuality(this.result.src, 0.5)
            console.log('atual: ' + this.base64SizeInMB(this.result.src))
          } while(this.base64SizeInMB(this.result.src) > this.maxSizeInMB)
          this.loading = false
        }
        if(this.actionSave)
          this.actionSave(this.result)
        this.data.dialog = false
        if(this.object && this.field)
          this.object[this.field] = this.result.src
      },
      async reduceImageQuality(base64Image, quality) {
        const pica = Pica();
        const img = new Image();
        img.src = base64Image;

        const mimeType = base64Image.substring(base64Image.indexOf(":") + 1, base64Image.indexOf(";"));
        console.log('tipo: ' + mimeType)
  
        return new Promise((resolve, reject) => {
          img.onload = async () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
  
            const offScreenCanvas = document.createElement('canvas');
            offScreenCanvas.width = canvas.width;
            offScreenCanvas.height = canvas.height;
  
            const result = await pica.resize(canvas, offScreenCanvas);
            pica.toBlob(result, 'image/jpeg', quality).then((blob) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve(reader.result);
              };
              reader.onerror = (error) => {
                reject(error);
              };
              reader.readAsDataURL(blob);
            });
          };
          img.onerror = (error) => {
            reject(error);
          };
        });
      },
      base64SizeInMB: function (base64String) {
        // Calcular o comprimento da string base64 excluindo o prefixo 'data:image/...;base64,'
        const base64Length = base64String.length - (base64String.indexOf(',') + 1);
        
        // Calcular o tamanho em bytes
        const sizeInBytes = (base64Length * 3) / 4;
        
        // Converter bytes em megabytes
        const sizeInMB = sizeInBytes / (1024 * 1024);
        
        return sizeInMB;
      }
    },
  }
</script>
<style scoped>
.cropper {
	background: #FFF;
}
.cropper-foreground-class{
  background: #FFF !important;
}

.vue-advanced-cropper__background, .vue-advanced-cropper__foreground{
  background: #FFF !important;
}
</style>