<template>
  <v-main>
    <v-row>
      <v-col cols="12">
        <TabOptions :block="true"/>
      </v-col>
    </v-row>
    <router-view :screenData="screenData" ></router-view>
  </v-main>
</template>

<script>
import { apiV1Subscription } from "../../../service/resources/api-v1-subscription";
import TabOptions from "../fragments/TabOptions.vue";
  export default {
    name: 'PainelLinks',
    components: {
    TabOptions
  },
  props: ['screenData'],
    data: () => ({
      dialogConfig: false,
      dialogCancel: false,
      loadSubscriptionPlan: false,
      loadCancelSubscriptionPlan: false,
      planData: {
        dialogPlansAndPrices: false,
        dialogSubscriptionPlan: false
      },
      drawer: false,
      miniVariante: true,
      expandOnHover: true,
      assign: null,
    }),
    computed: {

    },
    mounted: function() {

    },
    methods: {
      closeDrawer: function() {
        let self = this
        setTimeout(() => {
          self.miniVariante = true
          self.expandOnHover = false
        }, 2000);
      },
      logout: function() {
        localStorage.clear()
        this.screenData.auth = null
        this.$router.push('/loading')
      },
      getSubscriptionPlan: function() {
        this.planData.dialogSubscriptionPlan = true
        this.drawer = false
        this.loadSubscriptionPlan = true
        this.assign = null
        apiV1Subscription.get().then(result => {
          this.drawer = false
          this.loadSubscriptionPlan = false
          this.assign = result.data
        }).catch(() => {
          this.loadSubscriptionPlan = false
        })
      },
      cancelSubscrible: function() {
        this.loadCancelSubscriptionPlan = true
        apiV1Subscription.delete().then(() => {
          this.loadSubscriptionPlan = true
          this.dialogCancel = false
          apiV1Subscription.get().then(result => {
            this.assign = result.data
            this.loadCancelSubscriptionPlan = false
            this.loadSubscriptionPlan = false
          })
        }).catch(() => {
          this.loadCancelSubscriptionPlan = false
        })
      }
    },
  }
</script>
<style>
.card-circle{
  border-radius: 50% !important;
}
</style>