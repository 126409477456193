import http from "../axios";

let apiV1InfoproductFormAnswer = {
	baseUrl: '/api/v1/infoproducts-forms-answers',
	save: async (dto) => {
		return await http.post(`${apiV1InfoproductFormAnswer.baseUrl}`, dto);
	},
	delete: async (id) => {
		return await http.delete(`${apiV1InfoproductFormAnswer.baseUrl}/${id}`);
	},
	page: async (filter) => {
		return await http.get(`${apiV1InfoproductFormAnswer.baseUrl}
		?page=${filter.page}
		&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.infoproductId ? `&infoproductId=${filter.infoproductId}` : ''}
		`);
	},
}

export  { apiV1InfoproductFormAnswer }