<template>
  <div class="mt-2">
    <v-row no-gutters >
      <v-col cols="12" xs="12" sm="12" md="7" class="pl-0 ml-0">
        <v-row no-gutters >
          <v-col cols="12">
            <v-card-title class="primary--text mr-0 pr-0">
                <b>{{$t('infoProducts')}}</b>
              <v-spacer/>
              <v-badge overlap right bordered color="blue" :value="!screenData.auth.user.config" content="!">
                <v-btn icon color="primary" @click="dialogConfig = true" v-bind="attrs" v-on="on">
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </v-badge>
            </v-card-title>
            <v-card elevation="0" class="mt-4">
              <v-card-actions>
                <v-spacer/>
                <v-btn rounded color="primary" @click="infoProductEditData.dialog = true, newProduct()">
                  <v-icon>mdi-plus</v-icon>
                  {{ $t('createNewInfoproduct') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12">
            <div v-if="!loading">
              <v-card-actions class="pl-0 pr-0 ml-0 mr-0">
                <v-spacer/>
                <v-chip-group show-arrows>
                  <v-btn class="ml-1 primary--text" x-small :disabled="(page -1) == infoproductPage.number" color="white" rounded light v-for="page in infoproductPage.totalPages" :key="page" @click="search(page -1)">
                    <v-icon x-small v-if="(page -1) == infoproductPage.number">mdi-circle-medium</v-icon>
                    {{$t('page')}} {{page}}
                  </v-btn>
                </v-chip-group>
              </v-card-actions>
              <v-card-title class="primary--text mr-0 pr-0 mt-4">
                <v-spacer/>
                <v-btn rounded x-small color="primary" readonly>{{infoproductPage.totalElements}} {{ $t('infoProducts') }}</v-btn>
              </v-card-title>
              <v-row>
                <v-col
                  v-for="(item) in infoproductPage.content" :key="item.id + '-infoproduct'"
                  :cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                >
                <v-card :color="selected == item || (selected && selected.id == item.id) ? 'primary' : ''" class="card-corner-radius" style="padding: 2px; margin-left: 1px; margin-right: 1px;" elevation="1">
                  <v-card class="card-corner-radius" light elevation="0" @click="select(item)" :outlined="selected == item">
                    <v-list-item three-line >
                      <v-list-item-content>
                        <v-list-item three-line :class="`pa-0`">
                          <v-list-item-avatar
                            tile
                            :size="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 50 : 80"
                          >
                            <v-card elevation="0">
                              <v-img :src="item.brandImage && item.brandImage.src ?  item.brandImage.src : '/images/logo/logo-02.png'" contain/>
                            </v-card>
                          </v-list-item-avatar>
                          <v-list-item-content
                            class="pointer mb-0 pb-0 mt-0 pt-0">
                            <div class="text-overline mb-0 pb-0">
                              {{ item.title }}
                            </div>
                            <v-list-item-subtitle class="mb-0 pb-0" style="white-space: inherit;">
                              <template>
                                {{ item.subtitle }}
                              </template>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              {{ item.shortDescription }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-card-text class="pa-0" v-if="false">
                          <v-card elevation="0" color="grey lighten-4" outlined >
                            <v-card-text>
                            </v-card-text>
                          </v-card>
                        </v-card-text>
                        <v-card-text class="pa-0 ma-0" v-if="false">
                          <v-chip color="transparent" class="primary--text pl-0">
                          </v-chip>
                          <div style="max-height: 80px; overflow-x: auto;" v-if="item.itens">
                            <v-chip-group class="mt-0 pt-0 mb-0 pb-0" column  content-class="mt-0 pt-0">
                              <template v-if="item.tags"> 
                                <v-chip class="mr-1 mb-1" v-for="(tag, index) in item.itens" :key="index" v-show="tag" small>
                                  <v-icon x-small class="mr-1">mdi-tag</v-icon>
                                  {{tag}}
                                </v-chip>
                              </template>
                            </v-chip-group>
                          </div>
                        </v-card-text>
                      </v-list-item-content>
                      <v-divider vertical class="mt-4 mb-4 ml-4"/>
                      <v-list-item-action class="pl-0 ml-1" style="margin: auto;">
                        <v-btn color="primary" small icon @click="edit(item)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn color="primary" small icon @click="openGraphEditData.dialog = true,openGraphEditData.address = item">
                          <v-icon>mdi-octagram-edit-outline</v-icon>
                        </v-btn>
                        <ShareOptions :onlineAddress="item.onlineAddress"/>
                        <a :href="`${protocol}//${host}/${item.onlineAddress.code}`" target="_blank" style="text-decoration: none;">
                          <v-btn color="primary" small icon>
                            <v-icon>mdi-open-in-new</v-icon>
                          </v-btn>
                        </a>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card>
                </v-card>
                </v-col>
              </v-row>
              <v-card-actions class="pl-0 pr-0 ml-0 mr-0">
                <v-spacer/>
                <v-chip-group show-arrows>
                  <v-btn class="ml-1 primary--text" x-small :disabled="(page -1) == infoproductPage.number" color="white" rounded light v-for="page in infoproductPage.totalPages" :key="page" @click="search(page -1)">
                    <v-icon x-small v-if="(page -1) == infoproductPage.number">mdi-circle-medium</v-icon>
                    {{$t('page')}} {{page}}
                  </v-btn>
                </v-chip-group>
              </v-card-actions>
            </div>
            <div v-else>
              <v-card-actions >
                <v-skeleton-loader type="chip"/>
                <v-spacer/>
                <v-skeleton-loader type="chip" class="mr-1"/>
                <v-skeleton-loader type="chip"/>
              </v-card-actions>
              <v-skeleton-loader type="article" class="mt-4" v-for="i in 4" :key="i"/>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="5" v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)" class="pa-4 pr-0 full-height">
        <v-card elevation="0" height="100%" style="overflow-x: auto" class="card-corner-radius">
          <div v-if="selected">
            <v-card-title>
              <v-spacer/>
                <v-chip color="transparent" text @click="$router.push(`/painel/links/shortened/?onlineAddressId=${selected.onlineAddress.id}&typeIn=INFOPRODUCT`)" :disabled="!selected.id">
                  <v-icon small color="blue">mdi-chart-bar</v-icon>
                  <span class="ml-2 blue--text">{{$t('followMetrics')}}</span>
                </v-chip>
              <v-spacer/>
            </v-card-title>
            <InfoproductAwnsers :data="infoproductAwnsersData" :screenData="screenData"/>
          </div>
          <div v-else class="verticle-center" style="width: 100%;">
            <v-card-title style="width: 100%;">
              <v-spacer/>
              <v-btn rounded color="primary" @click="infoProductEditData.dialog = true, newProduct()">
                <v-icon>mdi-plus</v-icon>
                {{ $t('createNewInfoproduct') }}
              </v-btn>
              <v-spacer/>
            </v-card-title>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <InfoproductEdit :data="infoProductEditData" :screenData="screenData" :actionPosSave="actionPosSave" :actionPosDelete="actionPosDelete"/>
    <OpenGraphEdit :data="openGraphEditData" :screenData="screenData"/>
    <LinkEdit :data="LinkEditData" :screenData="screenData"/>
    <LinkEditTags :data="LinkEditTagsData" :screenData="screenData"/>
    <v-dialog v-model="dialogConfig" width="800" persistent :fullscreen="['xs'].includes($vuetify.breakpoint.name)">
      <v-card>
        <v-card-actions>
          <v-btn @click="dialogConfig = false" icon><v-icon color="primary">mdi-arrow-left</v-icon></v-btn>
          <v-spacer/>
          <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
        </v-card-actions>
        <Config :screenData="screenData"/>
      </v-card>
    </v-dialog>
    <v-dialog v-model="infoDialog" width="800" persistent :fullscreen="['xs'].includes($vuetify.breakpoint.name)">
      <v-card>
        <v-carousel 
          :show-arrows="false" 
          hide-delimiter-background>
          <v-carousel-item
            v-for="(item,i) in [
              {title: 'createEbook', subtitle: 'createEbookSubtitle', text: 'createEbookText', src: '/images/infoproduct/001.png'},
              ]"
            :key="i"
          >
            <v-card-actions>
              <v-spacer/>
              <v-btn @click="infoDialog = false" rounded dark color="primary">
                <v-icon class="mr-2">mdi-check</v-icon>
                {{ $t('advance') }}
              </v-btn>
            </v-card-actions>
            <v-card-text class="primary--text">
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="12" md="7">
                  <v-card-title class="primary--text">
                  <b>{{ $t(item.title) }}</b>
                </v-card-title>
                <v-card-subtitle class="primary--text">
                  {{ $t(item.subtitle) }}
                </v-card-subtitle>
                <v-card-text>
                  {{ $t(item.text) }}
                </v-card-text>
              </v-col>
                <v-col cols="12" xs="12" sm="12" md="5" class="text-right">
                  <v-img :src="item.src"/>
                  <i>{{ $t('exemple') }}</i>
                </v-col>
              </v-row>
            </v-card-text>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { apiV1Infoproduct } from '../../service/resources/api-v1-infoproduct';
import InfoproductEdit from './fragments/InfoproductEdit.vue';
import ShareOptions from './fragments/ShareOptions.vue'
import OpenGraphEdit from './fragments/OpenGraphEdit.vue'
import LinkEdit from './fragments/LinkEdit.vue';
import LinkEditTags from './fragments/LinkEditTags.vue';
import Config from './fragments/Config.vue';
import IconInfoSubscriblePlan from './fragments/IconInfoSubscriblePlan.vue';
import InfoproductAwnsers from './fragments/InfoproductAwnsers.vue'

export default {
    name: 'InfoProdutoPage',
    props: ['screenData'],
    components: {
    InfoproductEdit,
    OpenGraphEdit,
    LinkEdit,
    LinkEditTags,
    ShareOptions,
    Config,
    IconInfoSubscriblePlan,
    InfoproductAwnsers
},
    data: () => ({
      dialogConfig: false,
      infoDialog: false,
      planData: {
        dialogPlansAndPrices: false
      },
      openGraphEditData: {dialog: false, address: null},
      LinkEditData: {dialog: false, address: null},
      LinkEditTagsData: {dialog: false, address: null},
      loading: false,
      selected: null,
      infoProductEditData: {
        dialog: false,
        infoproduct: null
      },
      infoproductAwnsersData: {
        infoproduct: null
      },
      infoproductPage: {
        content: [],
        number: 1,
        totalPages: 1
      },
      protocol: null,
      host: null
    }),
    watch: {
    },
    computed: {
    },
    mounted: function() {
      this.protocol = `${window.location.protocol}`
      this.host = `${window.location.host.replace('www.', '')}`;
      this.search(0)
    },
    methods: {
      newProduct: function() {
        this.infoProductEditData.infoproduct = 
          {
            resource: null,
            brandImage: null,
            // title: 'Estratégia financeira imbatível: guia avançado de gestão corporativa',
            // subtitle: 'DOWNLOAD GRATUITO',
            // shortDescription: 'Um e-book com dicas práticas para uma boa gestão financeira do seu negócio através do controle inteligente.',
            productImage: null,
            // action: 'DOWNLOAD',
            // itensTitle: 'Quais assuntos vou encontrar no e-book?',
            itens: [
              // {id: 1, value: 'A importância da gestão financeira inteligente', deleted: false},
              // {id: 1, value: 'Os pilares da boa gestão financeira', deleted: false},
              // {id: 1, value: 'Controles financeiros básicos', deleted: false},
              // {id: 1, value: 'Como fazer o controle inteligente da sua gestão', deleted: false},
              // {id: 1, value: 'Fluxo de caixa', deleted: false},
              // {id: 1, value: 'Balanço patrimonial', deleted: false},
              // {id: 1, value: 'DRE', deleted: false},
              // {id: 1, value: 'Capital de giro', deleted: false}
            ],
            // description: `Dirigir uma empresa ou um negócio no Brasil, não é uma tarefa fácil. A falta de planejamento financeiro é um dos principais fatores que fazem os negócios fecharem as portas. Ele é primordial para conseguir definir o que deve ser feito, dar escalabilidade para sua empresa, atingir o próximo nível de organização empresarial e, claro, aumentar o lucro e evitar custos extras.‍
            // Neste e-book, vamos explorar indicadores estratégicos e boas práticas que levarão sua gestão financeira a um novo patamar de eficiência. 
            // Prepare-se para controlar ainda mais suas finanças, impulsionar seu crescimento e alcançar mais sucesso com o poder da gestão financeira avançada, apoiada pela Conta Simples.`,
            style: {
              headerColor: '#1565C0FF', 
              titleColor: '#FFFFFFFF', 
              subtitleColor: '#FFFFFFFF', 
              shortDescriptionColor: '#FFFFFFFF',
              btnActionColor: '#FFFFFFFF',
              btnActionTextColor: '#1565C0FF',
              itensTitleColor: '#1565C0FF',
              itensColor: '#1565C0FF',
              descriptionColor: '#1565C0FF',
              descriptionBackgroundColor: '#f3f3f3',
              formColor: '#1565C0FF',
              footerBackgroundColor: '#1565C0FF',
              footerTextColor: '#fff'
            },
            formFields: [
                // {id:1, name: 'nome', type: 'TEXT_FIELD', options: []},
                // {id:1, name: 'profissão', type: '', options: []}
            ],
            footer: {
              brandImage: null,
              // text: `A Conta Simples é uma empresa com sede na Rua Gomes de Carvalho, 1765 - Cj. 22 - Vila Olímpia, São Paulo / SP - CEP: 04547-901, registrada no CNPJ 32.273.196/0001-84, seguindo as diretrizes regulatórias do Banco Central do Brasil através da Resolução BCB Nº 96/21. 
              // Segurança em primeiro lugar. Feito com muita dedicação e empenho especialmente para sua empresa. Pra cima!`,
              links: [
              //   {href: 'http://migly.in', name: 'Contact Us'},
              //   {href: 'http://migly.in', name: 'LinkedIn'},
              //   {href: 'http://migly.in', name: 'Facebook'},
              //   {href: 'http://migly.in', name: 'Instagram'}
              ]
            },
            user: this.screenData.auth.user,
            onlineAddress: {
              code: '',
              id: null
            }
          }
      },
      search: function(page) {
        this.loading = true
        apiV1Infoproduct.page({page: page, size: 10, orderBy: 'dateUpdate:DESC,dateCreate:DESC', embedItens: true, embedfooterLinks: true, embedInfoproductFormFields: true, embedInfoproductFormFieldsOptions: true}).then(response => {
          this.infoproductPage = response.data
          this.loading = false
          if(page == 0) {
            this.select(this.infoproductPage.content[0])
          }
        })
      },
      select: function(item) {
        item.user = this.screenData.auth.user
        if(this.selected == item) {
          this.selected = null
        } else {
          this.selected = item
          this.infoproductAwnsersData.infoproduct = item
        }
      },
      edit: function(item) {
        this.infoProductEditData.infoproduct = item
        this.infoProductEditData.infoproduct.user = this.screenData.auth.user,
        this.selected = item
        this.infoProductEditData.dialog = true
      },
      actionPosSave: function(infoproduct) {
        var update = false
        this.infoproductPage.content.forEach((i, index) => {
          if(infoproduct.id == i.id){
            this.infoproductPage.content.splice(index, 1)
            this.infoproductPage.content.splice(index, 0, infoproduct)
            update = true
          }
        })
        if(!update) {
          this.infoproductPage.content.splice(0, 0, infoproduct)
        }
      },
      actionPosDelete: function(infoproduct) {
        this.infoproductPage.content.forEach((i, index) => {
          if(infoproduct.id == i.id){
            this.infoproductPage.content.splice(index, 1)
          }
        })
      }

    },
  }
</script>
<style>
</style>