<template>
  <div v-if="infoproduct">
    <v-card elevation="0" :color="infoproduct.style.headerColor" style="border-radius: 0;">
      <div class="infoproduct-header"/>
      <v-card-actions v-if="edit">
        <v-spacer/>
        <v-menu offset-y left :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="white"
              v-bind="attrs"
              v-on="on"
              icon
              outlined
              rounded
            >
              <v-icon color="white">mdi-palette</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-color-picker
                dot-size="25"
                show-swatches
                swatches-max-height="200"
                mode="hexa"
                @update:color="v => style.headerColor = v.hexa"
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
      <v-container>
        <v-row>
          <v-col :cols="['xs', 'sm'].includes(view) ? 12 : 6">
            <v-row no-gutters id="header-section">
              <v-col :cols="12">
                <v-card-title :style="`color: ${infoproduct.style.titleColor}`" class="mb-0">
                  <v-spacer v-if="['xs', 'sm'].includes(view)"/>
                  <v-card elevation="0" color="transparent">
                    <v-img v-if="infoproduct.brandImage" :src="infoproduct.brandImage.src" max-width="150px"/>
                  </v-card>
                  <v-spacer/>
                </v-card-title>
              </v-col>
              <v-col :cols="12">
                <v-card-title :style="`color: ${infoproduct.style.titleColor}`" class="mt-0 pt-0 mb-0 pb-0" style="display: block">
                  <v-spacer v-if="['xs', 'sm'].includes(view)"/>
                  <p>
                    {{infoproduct.title}}
                  </p>
                    <v-menu offset-y left :close-on-content-click="false" v-if="edit && infoproduct.title">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          color="white"
                          v-bind="attrs"
                          v-on="on"
                          icon
                          outlined
                          rounded
                          class="ml-1"
                        >
                          <v-icon color="white">mdi-palette</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-color-picker
                            dot-size="25"
                            show-swatches
                            swatches-max-height="200"
                            mode="hexa"
                            @update:color="v => infoproduct.style.titleColor = v.hexa"
                          />
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  <v-spacer v-if="['xs', 'sm'].includes(view)"/>
                </v-card-title>
              </v-col>
              <v-col :cols="12">
                <v-card-actions :style="`color: ${infoproduct.style.subtitleColor}`" class="pl-4 mt-0 pt-0 mb-0 pb-0 text-overline"  style="display: block">
                  <v-spacer v-if="['xs', 'sm'].includes(view)"/>
                  <p>
                    {{infoproduct.subtitle}}
                    <v-menu offset-y left :close-on-content-click="false" v-if="edit && infoproduct.subtitle">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          color="white"
                          v-bind="attrs"
                          v-on="on"
                          icon
                          outlined
                          rounded
                          class="ml-1"
                        >
                          <v-icon color="white">mdi-palette</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-color-picker
                            dot-size="25"
                            show-swatches
                            swatches-max-height="200"
                            mode="hexa"
                            @update:color="v => infoproduct.style.subtitleColor = v.hexa"
                          />
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </p>
                  <v-spacer v-if="['xs', 'sm'].includes(view)"/>
                </v-card-actions>
              </v-col>
              <v-col :cols="12">
                <v-card-text :style="`color: ${infoproduct.style.shortDescriptionColor}`" class="mt-0 pt-0">
                  <p>
                    {{infoproduct.shortDescription}}
                    <v-menu offset-y left :close-on-content-click="false" v-if="edit && infoproduct.shortDescription">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          color="white"
                          v-bind="attrs"
                          v-on="on"
                          icon
                          outlined
                          rounded
                          class="ml-1"
                        >
                          <v-icon color="white">mdi-palette</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-color-picker
                            dot-size="25"
                            show-swatches
                            swatches-max-height="200"
                            mode="hexa"
                            @update:color="v => infoproduct.style.shortDescriptionColor = v.hexa"
                          />
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer/>
                  <a href="#form-section">
                    <v-btn rounded :color="infoproduct.style.btnActionColor" v-if="infoproduct.action" elevation="1" :style="`color: ${infoproduct.style.btnActionTextColor};`">
                      {{ $t(infoproduct.action.toLowerCase() + 'Now') }}
                    </v-btn>
                  </a>
                  <v-spacer/>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="['xs', 'sm'].includes(view) ? 12 : 6" v-if="infoproduct.productImage">
            <v-card-actions class="verticle-center">
              <v-spacer/>
              <v-card elevation="0" color="transparent">
                <v-img :src="infoproduct.productImage.src" max-height="500px"/>
              </v-card>
              <v-spacer/>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container>
      <v-row>
        <v-col :cols="['xs', 'sm'].includes(view) ? 12 : 6" v-if="infoproduct.itensTitle">
          <v-card-title :style="`color: ${infoproduct.style.itensTitleColor}`"  style="display: block; line-break: anywhere;">
            <v-spacer v-if="['xs', 'sm'].includes(view)"/>
            <b>{{ infoproduct.itensTitle }}</b>
            <v-spacer v-if="['xs', 'sm'].includes(view)"/>
          </v-card-title>
          <v-card-actions>
            <v-spacer/>
            <a href="#form-section">
              <v-btn rounded :color="infoproduct.style.btnActionColor" v-if="infoproduct.action" elevation="1" :style="`color: ${infoproduct.style.btnActionTextColor};`">
                {{ $t(infoproduct.action.toLowerCase() + 'Now') }}
              </v-btn>
            </a>
            <v-spacer/>
          </v-card-actions>
        </v-col>
        <v-col id="itens-section" :cols="['xs', 'sm'].includes(view) ? 12 : 6" v-if="infoproduct.itensTitle">
          <v-row no-gutters>
            <v-col v-for="(item, index) in infoproduct.itens" :key="index" :cols="['xs', 'sm'].includes(view) ? 12 : 6">
              <v-card-subtitle :style="`color: ${infoproduct.style.itensColor}`"  style="display: block; line-break: anywhere;" class="mb-1 pb-1">
                <v-icon :color="infoproduct.style.itensColor" class="mr-2" small>mdi-check</v-icon>                
                {{ item.value }}
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-card id="more-section" elevation="0" :color="infoproduct.style.descriptionBackgroundColor" v-if="infoproduct.description" style="border-radius: 0px;">
      <v-container>
        <v-card-text :style="`color: ${infoproduct.style.descriptionColor}`" v-if="infoproduct.description">
          <p v-for="(text, index) in infoproduct.description.split(/\r?\n|\r|\n/g)" :key="index">
            <b v-if="index > 0 && (index + 1) % 2 == 0">{{ text }}</b>
            <span v-else>{{ text }}</span>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <a href="#form-section">
            <v-btn rounded :color="infoproduct.style.btnActionColor" v-if="infoproduct.action" elevation="1" :style="`color: ${infoproduct.style.btnActionTextColor};`">
              {{ $t(infoproduct.action.toLowerCase() + 'Now') }}
            </v-btn>
          </a>
          <v-spacer/>
        </v-card-actions>
      </v-container>
    </v-card>
    <v-container>
      <v-row>
        <v-col>
          <v-card-subtitle v-if="infoproduct.type == 'E_BOOK'">
            {{ $t(`fillInFormForReceiveEbook`) }}
          </v-card-subtitle>
          <v-card-subtitle v-else-if="infoproduct.type == 'LINK'">
            {{ $t(`fillInFormForReceiveLink`) }}
          </v-card-subtitle>
          <v-fade-transition hide-on-leave leave-absolute>
            
            <v-form id="form-section" v-model="form" :disabled="loadingSend || onlyView" ref="form" v-if="!isSend">
              <v-card-text class="pb-0 mb-0">
                <v-text-field 
                  v-model="email"
                  rounded
                  :label="$t('provideYourEmail')"
                  outlined
                  hide-actions
                  dense
                  flat
                  :rules="[
                    v => !!v || $t('required'),
                    v => !!v && v.match(/^\S+@\S+\.\S+$/) || $t('invalid'),
                  ]"
                  :color="infoproduct.style.formColor"
                  >
                  <template v-slot:prepend-inner>
                    <v-icon class="mr-1" :color="infoproduct.style.formColor">
                    </v-icon>
                  </template>
                  <template v-slot:append>
                  </template>
                </v-text-field>
              </v-card-text>
              <div v-if="infoproduct.formFields && infoproduct.user && infoproduct.user.subscriptionPlan.id >= 3">
                <v-card-text v-for="field, index in infoproduct.formFields.filter(field => !field.deleted)" :key="index">
                  <div v-if="formFields.answers.filter(f => f.field.id == field.id)[0]">
                    <v-text-field 
                      v-if="field.type == 'TEXT_FIELD'"
                      v-model="formFields.answers.filter(f => f.field.id == field.id)[0].value"
                      rounded
                      :label="field.name"
                      outlined
                      hide-actions
                      hide-details
                      dense
                      flat
                      :required="field.required"
                      :rules="[
                        v => ((field.required && !!v) || !field.required) || $t('required'),
                        v => (field.inputType == `NUMERIC` && v.match(/^[0-9]*$/) || field.inputType != `NUMERIC`) || $t('invalid'),
                      ]"
                      :color="infoproduct.style.formColor"
                      @change="adjustField(field)"
                      >
                      <template v-slot:prepend-inner>
                      </template>
                      <template v-slot:append>
                      </template>
                    </v-text-field>
                    <v-select
                      v-else-if="field.type == 'SELECT_FIELD'"
                      v-model="formFields.answers.filter(f => f.field.id == field.id)[0].value"
                      :items="field.options"
                      rounded
                      :label="field.name"
                      outlined
                      :required="field.required"
                      hide-details
                      hide-actions
                      dense
                      flat
                      item-text="value"
                      item-value="value"
                      color="primary"
                      :rules="[
                        v => field.required && !!v || $t('required')
                      ]"
                    >
                      <template v-slot:selection="{item}">
                        <span>{{ item.value }}</span>
                      </template >
                      <template v-slot:item="{item, on, attrs}">
                        <span v-bind="attrs" v-on="on">{{ item.value }}</span>
                      </template>
                    </v-select>
                  </div>
                </v-card-text>
              </div>
              <v-card-subtitle>
                <small>{{ $t('fillInYourEmailPolicyPrivacy') }}</small>
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer/>
                <v-btn rounded :color="infoproduct.style.headerColor" :style="`color: ${infoproduct.style.titleColor}`" @click="send" :loading="loadingSend" :disabled="!form">
                  {{ $t('toSend') }}
                </v-btn>
                <v-spacer/>
              </v-card-actions>
            </v-form>
            <v-card v-else :color="infoproduct.style.headerColor" elevation="0" height="200">
              <div class="verticle-center" style="width: 100%;">
                <v-card color="transparent" elevation="0" :style="`width: 100%; color: ${infoproduct.style.titleColor}`">
                  <v-card-actions>
                    <v-spacer/>
                    <v-icon x-large :color="infoproduct.style.titleColor">mdi-check-bold</v-icon>
                    <v-spacer/>
                  </v-card-actions>
                  <v-card-title>
                    <v-spacer/>
                    {{ $t('toSendInfoproduct') }}
                    <v-spacer/>
                  </v-card-title>
                </v-card>
              </div>
            </v-card>
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-container>
    <v-footer id="footer-section" v-if="infoproduct.user.subscriptionPlan.id >= 3 && infoproduct.footer" :color="infoproduct.style.footerBackgroundColor" dark>
      <v-container>
        <v-card elevation="0" color="transparent" v-if="infoproduct.footer">
          <v-card-title>
            <v-spacer/>
            <v-card elevation="0" color="transparent">
              <img :src="infoproduct.footer.brandImage.src" width="150" class="mr-2" v-if="infoproduct.footer.brandImage" alt="infoproduct"/> 
            </v-card>
            <v-spacer/>
          </v-card-title>
          <v-card-text :style="`color: ${infoproduct.style.footerTextColor}`" v-if="infoproduct.footer.text">
            <p v-for="(text, index) in infoproduct.footer.text.split(/\r?\n|\r|\n/g)" :key="index">
              <b v-if="index % 2 == 0">{{ text }}</b>
              <span v-else>{{ text }}</span>
            </p>
          </v-card-text>
          <v-card-text v-if="infoproduct.footer.links">
            <v-row>
              <v-col v-for="(link, index) in infoproduct.footer.links.filter(link => !link.deleted)" :key="index">
                <center>
                  <a :href="link.href" class="link" :style="`color: ${infoproduct.style.footerTextColor}`">{{ link.name }}</a>
                </center>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-footer>
    <v-footer id="footer-section" v-else dark>
      <v-container>
        <v-card elevation="0" color="transparent">
          <v-card-actions>
            <v-spacer/>
            {{ $t('infoproductCreateBy') }}
            <v-spacer/>
          </v-card-actions>
          <v-card-actions class="pt-1 pb-1 pl-1 pr-1">
            <v-spacer/>
            <a href="/" class="link">
              <img src="/images/logo/logo-04.png" width="150" class="mr-2" alt="migly"/> 
            </a>
            <v-spacer/>
          </v-card-actions>
          <v-card-actions class="white--text">
            <v-spacer/>
            <small><p>migly.in é um produto <a class="link" target="_blank" href="http://exatamente-solucoes.com"> exatamente soluções.</a></p></small>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-footer>
  </div>
</template>
<script>
import { apiV1InfoproductFormAnswer } from '../../../service/resources/api-v1-infoproduct-form-answer'


export default {
    name: 'InfoproductView',
    props: ['infoproduct', 'screenData', 'edit', 'view', 'onlyView'],
    components: {
    },
    data: () => ({
      email: null,
      formFields: {answers: [], email: ''},
      form: false,
      loadingSend: false,
      isSend: false
    }),
    watch: {
      'infoproduct.formFields': function() {
        this.defineFormFields()
        this.$forceUpdate()
      },
    },
    mounted: function() {
      this.defineFormFields()
    },
    computed: {
    },
    methods: {
      defineFormFields: function() {
        this.formFields = {answers: [], email: ''}
        if(this.infoproduct && this.infoproduct.formFields) {
          this.infoproduct.formFields
          .filter(element => !element.deleted)
          .forEach((element) => {
            this.formFields.answers.push({field: element, value: ''})
          })
          console.log(this.formFields)
        }
      },
      adjustField: function(field) {
        console.log(field)
      },
      send: function(){
        this.formFields.email = this.email
        this.formFields.infoproduct = {id: this.infoproduct.id}
        console.log(this.formFields)
        this.loadingSend = true
        apiV1InfoproductFormAnswer.save(this.formFields).then(result => {
          console.log(result)
          this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('saved')})
          this.isSend = true
        }).finally(() => {
          this.loadingSend = false
        })
      }
    },
  }
</script>
<style scoped>
.infoproduct-header{
  /* z-index: 1; */
  opacity: .15;
  filter: blur(5px);
  background-image: radial-gradient(circle,#fff,rgba(255,255,255,0) 67%);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
</style>