<template>
  <v-card elevation="0" height="100%" color="transparent">
    <v-card-text v-if="address && address.openGraph && address.openGraph.openGraphFields">
      <v-row>
        <v-col cols="2">

        </v-col>
        <v-col cols="10">
          <v-card elevation="0" color="#004D40" class="pa-1">
            <v-card elevation="0" color="green darken-4" dark>
              <v-img :src="!address.openGraph.openGraphFields.filter(f => f.name == 'og:image')[0].deleted && address.openGraph.openGraphFields.filter(f => f.name == 'og:image')[0].value">
                <v-footer class="verticle-center" absolute color="transparent" v-if="address.openGraph.openGraphFields.filter(f => ['og:video', 'og:video:secure_url', 'og:audio', 'og:audio:secure_url'].includes(f.name) && f.value && !f.deleted)[0]">
                  <v-spacer/>
                  <v-btn icon color="grey" x-large>
                    <v-icon>mdi-play-circle</v-icon>
                  </v-btn>
                  <v-spacer/>
                </v-footer>
              </v-img>
                <v-card-subtitle class="pa-1" v-if="address.openGraph.openGraphFields.filter(f => f.name == 'og:title')[0]">
                  <b>{{ address.openGraph.openGraphFields.filter(f => f.name == 'og:title')[0].value }}</b>
                </v-card-subtitle>
                <v-card-subtitle class="pa-1 pt-0">
                  <div v-if="address.openGraph.openGraphFields.filter(f => f.name == 'og:description')[0].value">{{ address.openGraph.openGraphFields.filter(f => f.name == 'og:description')[0].value }}</div>
                  <br>
                  <div v-if="address.openGraph.openGraphFields.filter(f => f.name == 'og:url')[0]">
                    {{ address.openGraph.openGraphFields.filter(f => f.name == 'og:url')[0].value }}
                  </div>
                </v-card-subtitle>
            </v-card>
            <v-card-subtitle class="pa-1 pt-0 green--text">
              <a :href="`${getHost()}/${address.code}`" target="_blank" class="green--text">{{getHost()}}/{{ address.code }}</a>
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-card elevation="0" color="#004D40" class="pa-1">
            <v-card elevation="0" color="green darken-4" dark>
              <v-list two-line class="pa-0">
                <v-list-item class="pa-1">
                  <v-list-item-avatar tile size="80" class="pa-0 ma-0" v-if="!address.openGraph.openGraphFields.filter(f => f.name == 'og:image')[0].deleted && address.openGraph.openGraphFields.filter(f => f.name == 'og:image')[0].value">
                    <v-card elevation="0" class="rounded-l-lg rounded-r-0" height="100%">
                      <v-img :src="address.openGraph.openGraphFields.filter(f => f.name == 'og:image')[0].value" height="100%" contain/>
                    </v-card>
                  </v-list-item-avatar>
                  <v-list-item-content class="pa-1">
                    <v-list-item-subtitle v-if="address.openGraph.openGraphFields.filter(f => f.name == 'og:title')[0]">
                      <b>{{ address.openGraph.openGraphFields.filter(f => f.name == 'og:title')[0].value }}</b>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>    
                      <div v-if="address.openGraph.openGraphFields.filter(f => f.name == 'og:description')[0].value">{{ address.openGraph.openGraphFields.filter(f => f.name == 'og:description')[0].value }}</div>                
                      <br>
                      <div v-if="address.openGraph.openGraphFields.filter(f => f.name == 'og:url')[0]">
                        {{ address.openGraph.openGraphFields.filter(f => f.name == 'og:url')[0].value }}
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
            <v-card-subtitle class="pa-1 pt-0 green--text">
              <a :href="`${getHost()}/${address.code}`" target="_blank" class="green--text">{{getHost()}}/{{ address.code }}</a>
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="2">
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    name: 'WhattsAppView',
    props: ['address', 'screenData'],
    components: {},
    data: () => ({
      newMessage: '',
      messages: []
    }),
    watch: {
    },
    mounted: function() {
    },
    computed: {
    },
    methods: {
      getHost: function() {
        //`${window.location.protocol}//${window.location.host.replace('www.', '')}`;
        return `${window.location.host.replace('www.', '')}`
      },
    },
  }
</script>
<style scoped>
.chat-container {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f8f8f8;
}
.messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
}
.message {
  margin: 5px 0;
}
.link-preview {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.link-preview img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.input-container {
  display: flex;
}
input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
button {
  margin-left: 5px;
  padding: 8px;
}
</style>