<template>
  <v-card class="card-corner-radius" elevation="0" style="min-height: 100%;">
    <v-card-title class="blue--text">
      <v-icon color="blue" class="mr-2">mdi-chart-bar</v-icon>
      {{$t('metrics')}}
    </v-card-title>
    <div v-if="data.shooting">
      <v-card-title class="primary--text">
        <b>{{ data.shooting.title }}</b>
      </v-card-title>
      <v-card-subtitle class="primary--text">
        {{ data.shooting.submissions }} {{ $t('submissions').toLowerCase() }} 
        /
        {{ data.shooting.recipients }} {{ $t('recipients').toLowerCase() }}
      </v-card-subtitle>
      <v-card-subtitle>
        <v-card color="grey lighten-3" elevation="0">
          <v-card-actions class="text-overline mb-0 pb-0">
            {{ $t('SMS') }}
          </v-card-actions>
          <v-card-text>
            {{getTemplate()}}
          </v-card-text>
        </v-card>
      </v-card-subtitle>
      <div>
        <v-card-subtitle class="primary--text">
          {{ $t('links') }}
        </v-card-subtitle>
        <v-card-text>
          <div v-if="loadingShootingHasOnlineAddresses">
            <v-skeleton-loader type="chip"/>
          </div>
          <v-chip-group column v-else>
            <v-card outlined v-for="(link, index) in shootingHasOnlineAddressPage.content" :key="index" class="card-corner-radius mb-2 mr-1" elevation="0" >
              <v-list-item three-line>
                <v-list-item-avatar
                  tile
                  size="50"
                >
                  <template v-if="link.onlineAddress.openGraph && link.onlineAddress.openGraph.openGraphFields && link.onlineAddress.openGraph.openGraphFields.filter(f => f.name == ('og:image'))[0]">
                    <v-img lazy-src="/images/logo/logo-02.png" v-for="(field) in link.onlineAddress.openGraph.openGraphFields.filter(f => f.name == ('og:image'))" :key="field.id" :src="field.value" position="center center" contain style="border-radius: 10px 0px 0px 10px;" aspect-ratio="1"/>
                  </template>
                  <v-img v-else src="/images/logo/logo-02.png" contain/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-card-title class="text-overline pa-0">
                    <small><a :href="`${getShortLink(link)}?countClick=false`" target="_blank">{{ getShortLink(link) }}</a></small>
                  </v-card-title>
                  <v-list-item-title>
                    <a :href="`${getShortLink(link)}?countClick=false`" target="_blank" class="link">
                      {{ link.onlineAddress.url }}
                    </a>
                    <v-spacer/>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider/>
              <v-card-actions>
                {{ link.views }} {{ $t('views') }} 
                <v-spacer/>
                {{ link.contactUniqueViews }} {{ $t('contactClickedLinks') }} 
              </v-card-actions>
            </v-card>
          </v-chip-group>
        </v-card-text>
      </div>
      <v-card-actions>
        <v-spacer/>
        {{$t('comingSoon')}} <v-icon small>mdi-plus</v-icon>
        <v-spacer/>
      </v-card-actions>
    </div>
  </v-card>
</template>
<script>
import { apiV1SmsShootingHasOnlineAddresses } from '../../../service/resources/api-v1-sms-shootings-has-online-addresses'

  export default {
    name: 'ShootingDashboard',
    props: ['data'],
    components: {
    },
    data: () => ({
      loading: false,
      loadingShootingHasOnlineAddresses: false,
      shootingHasOnlineAddressPage: {
        content: []
      }
    }),
    watch: {
      'data.shooting.id' : function() {
        this.init()
      }
    },
    mounted: function() {
      if(this.data.shooting)
        this.init()
    },
    computed: {
    },
    methods: {
      init: function() {
        this.searchOnlineAddresses()
      },
      searchOnlineAddresses: function() {
        this.loadingShootingHasOnlineAddresses = true
        apiV1SmsShootingHasOnlineAddresses.page({page: 0, size: 100, orderBy: 'dateCreate:DESC', smsShootingId: this.data.shooting.id}).then(response => {
          this.shootingHasOnlineAddressPage = response.data
          this.loadingShootingHasOnlineAddresses = false
        })
      },
      getShortLink: function(link) {
        return `${window.location.protocol}//${window.location.host.replace('www.', '')}/${link.onlineAddress.code}/{0}`
      },
      getTemplate: function() {
        return `${this.data.smsConfig.business}: ${this.data.shooting.template}${this.$t('textOptOut')}`
      },
    },
  }
</script>
<style scoped>
</style>