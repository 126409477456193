import http from "../axios";

let apiV1Auth = {
	baseUrl: '/api/v1/auth',
	get: async () => {
		return await http.get(apiV1Auth.baseUrl);
	},
	post: async () => {
		return await http.post(apiV1Auth.baseUrl + '/v2');
	}
}

export  { apiV1Auth }