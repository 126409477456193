<template>
  <v-menu offset-y left :close-on-content-click="false" v-model="menu" max-width="300">
    <template v-slot:activator="{ on, attrs }">
      <v-card elevation="0" outlined  v-bind="attrs" v-on="on" class="rounded-pill">
        <v-card-actions class="ma-0 pa-1">
          <v-btn
            x-small
            :fab="fab"
            :icon="icon"
            elevation="4"
            :color="data[attribute]"
          >
            <v-icon :color="data[attribute]">mdi-palette</v-icon>
          </v-btn>
          <v-divider vertical class="ma-2"/>
          {{ data[attribute] }}
        </v-card-actions>
      </v-card>
    </template>
    <v-card outlined elevation="0" max-width="300">
      <v-card-actions>
        <v-btn icon color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer/>
        <b>{{ title }}</b>
        <v-spacer/>
        <v-btn icon color="#7E8EA6" @click="menu = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider class="mr-4 ml-4"/>
      <v-card-text>
        <v-color-picker
          dot-size="25"
          show-swatches
          swatches-max-height="200"
          mode="hexa"
          :value="data[attribute]"
          @update:color="v =>  (data[attribute] = v.hexa, change ? change() : undefined)"
          v-model="data[attribute]"
        />
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>

  export default {
    name: 'ColorMenu',
    props: ['data', 'attribute', 'title', 'fab', 'icon', 'change'],
    components: {
},
    data: () => ({
      menu: false,
    }),
    watch: {
    },
    mounted: function() {
    },
    computed: {
    },
    methods: {
    }
  }
</script>
<style scoped>
</style>