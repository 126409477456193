import http from "../axios";

let apiV1Credit = {
	baseUrl: '/api/v1/credits',
	update: async (dto) => {
		return await http.put(`${apiV1Credit.baseUrl}`, dto);
	},
	save: async (dto) => {
		return await http.post(`${apiV1Credit.baseUrl}`, dto);
	},
	page: async (filter) => {
		return await http.get(`${apiV1Credit.baseUrl}
		?page=${filter.page}
		&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.statusIn ? `&statusIn=${filter.statusIn}` : ''}
		`);
	},
}

export  { apiV1Credit }