import http from "../axios";

let apiV1SmsConfig = {
	baseUrl: '/api/v1/sms-configs',
	save: async (dto) => {
		return await http.post(`${apiV1SmsConfig.baseUrl}`, dto);
	},
	get: async () => {
		return await http.get(`${apiV1SmsConfig.baseUrl}/users`);
	},
	getOptIn: async (smsOptInCode) => {
		return await http.get(`${apiV1SmsConfig.baseUrl}/opt-in/${smsOptInCode}`);
	},
}

export  { apiV1SmsConfig }