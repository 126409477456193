<template>
  <div>
    <router-view v-if="showIndex"></router-view>
    <div class="full-height box" v-else>
      <head v-if="biography">
        <title>{{biography.name}}</title>
      </head>
      <div class="verticle-center" v-if="loading" key="0001">
        <v-container class="horizontaly-center">
          <div key="1">
            <v-card-actions class="mb-0 -b-0">
              <v-spacer/>
              <v-avatar width="150" height="150">
                <v-img v-if="config && config.picture && config.picture.src" :src="config.picture.src" key="1" width="150" height="150"/> 
                <v-img v-else src="/images/logo/logo-04.png" contain key="2" width="150" class="pointer" @click="$router.push('/')"/> 
              </v-avatar>
              <v-spacer/>
            </v-card-actions>
          </div>
          <v-fade-transition leave-absolute hide-on-leave>
            <v-card-title class="mb-0 -b-0" v-if="config">
              <v-spacer/>
              {{config.siteName}}
              <v-spacer/>
            </v-card-title>
          </v-fade-transition>
          <v-card-actions>
            <v-spacer/>
            <v-progress-circular x-small :indeterminate="!config && !isCancel" :value="progress" width="1" size="22" :color="isCancel ? 'transparent' : `primary`">
            </v-progress-circular>
            <v-spacer/>
          </v-card-actions>
          <v-container>
            <v-card v-if="address && address.url" elevation="0" color="grey lighten-4"  style="line-break:anywhere">
              <v-card-subtitle>
                <b class="">{{ $t('youWillBeRedirectedTo') }}</b> 
                <p><i>{{ address.url }}</i></p>
              </v-card-subtitle>
              <v-divider/>
              <v-slide-y-transition>
                <v-card-actions v-if="isCancel" class="description--text">
                  <!-- <label>0</label>
                  <v-btn icon color="primary">
                    <v-icon>mdi-thumb-up</v-icon>
                  </v-btn>
                  <label>0</label>
                  <v-btn icon color="primary">
                    <v-icon>mdi-thumb-down-outline</v-icon>
                  </v-btn> -->
                  <v-spacer/>
                  <v-btn outlined color="primary" class="rounded-lg text-none" @click="reportData.dialog = true">
                    <v-icon>mdi-flag-outline</v-icon>
                    <label>
                      {{ $t('reportLink') }}
                    </label>
                  </v-btn>
                </v-card-actions>
                <v-card-actions v-else>
                  <v-spacer/>
                  <v-card small elevation="0" @click="isCancel = true">
                      <v-card-subtitle class="pr-2 pl-2 pt-1 pb-1">
                        {{ $t('cencelRedirected') }}
                      </v-card-subtitle>
                      <v-progress-linear x-small :value="progress" height="2" color="primary" class="rounded-lg"/>
                  </v-card>
                </v-card-actions>
              </v-slide-y-transition>
            </v-card>
            <v-card-actions v-if="address && (!address.user || !address.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0])">
              <v-spacer/>
              <a href="/" target="_blank">
                <v-btn rounded class="text-none" elevation="0">
                  <img src="/images/logo/logo-02.png" width="20" height="20" class="mr-2"/> 
                  {{ $t('shortenWithMigly') }}
                </v-btn>
              </a>
              <v-spacer/>
            </v-card-actions>
          </v-container>
          <v-app-bar absolute extended color="transparent"  v-if="address && (!address.user || !address.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0])" elevation="0">
            <template v-slot:extension>
              <v-container>
                <a class="link" target="_blank" href="https://s.shopee.com.br/6V58kNuCUZ">
                  <v-card outlined elevation="0" class="ad-banner">
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-img src="https://down-br.img.susercontent.com/file/br-11134207-7r98o-lujn5utbu26se2.webp"/>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="primary--text all-text ">
                          {{ $t('yourCompanyOrProductHere') }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="all-text ">
                          {{ $t('yourCompanyOrProductHereText') }}
                        </v-list-item-subtitle>
                        <v-card-actions>
                          <v-spacer/>
                          <a href="">
                            {{ $t('callToAction') }}
                          </a>
                        </v-card-actions>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </a>
              </v-container>
            </template>
          </v-app-bar>
          <v-app-bar absolute extended color="transparent" elevation="0" v-else-if="config && config.bannerImage && !config.bannerImage.deleted && config.bannerImage.src">
            <template v-slot:extension>
              <v-container>
                <a :href="config.bannerLink" target="_blank">
                  <v-card elevation="0" outlined class="ad-banner">
                    <v-img :src="config.bannerImage.src" height="100"/>
                    <v-footer absolute color="transparent">
                      <v-spacer/>
                    </v-footer>
                  </v-card>
                </a>
              </v-container>
            </template>
          </v-app-bar>
        </v-container>
      </div>
      <div v-else-if="biography" key="0002" class="full-height">
        <v-container :style="`height: 100%; ${['xs' , 'sm'].includes($vuetify.breakpoint.name) ? 'padding: 0px' : ''}`">
          <v-card width="100%" elevation="0" height="100%">
            <BiographySmartphoneView  :bio="biography" :onlySmartphone="false"/>
          </v-card>
        </v-container>
      </div>
      <div v-else-if="infoproduct" key="0002">
        <InfoproductView :infoproduct="infoproduct" :edit="false" :view="$vuetify.breakpoint.name"/>
      </div>
    </div>
    <v-bottom-sheet v-model="reportData.dialog" v-if="['xs' , 'sm'].includes($vuetify.breakpoint.name)">
      <LinkReport :data="reportData" :onlineAddress="address"/>
    </v-bottom-sheet>
    <v-navigation-drawer 
      v-else
      v-model="reportData.dialog"
      fixed
      width="450"
      height="100%"
      class="grey lighten-5"
      >
      <LinkReport :data="reportData" :onlineAddress="address"/>
    </v-navigation-drawer>
    <v-overlay :value="reportData.dialog" ></v-overlay>
  </div>
</template>


<script>
import { apiV1Click } from "../service/resources/api-v1-click"
import { apiV1OnlineAddresses } from "../service/resources/api-v1-online-addresses"
import { apiV1ContactAccessAddress } from "../service/resources/api-v1-contact-access-address"
import BiographySmartphoneView from "./pages/fragments/BiographySmartphoneView.vue"
import InfoproductView from './pages/fragments/InfoproductView.vue'
import LinkReport from './pages/fragments/LinkReport.vue'

  export default {
    name: 'Redirect',
    components: {
    BiographySmartphoneView,
    InfoproductView,
    LinkReport
},
    data: () => ({  
      loading: true,
      biography: null,
      config: null,
      progress: 10,
      address: null,
      showIndex: false,
      infoproduct: null,
      isCancel: false,
      dialogReport: false,
      reportData: {dialog: false}
    }),
    mounted: function() {
      this.loading = false
      apiV1OnlineAddresses.get(this.$route.params.code, {view : true}).then(result => {
        this.address = result.data
        if(result && result.data) {
          if(!this.$route.query.countClick)
            apiV1Click.post({
              onlineAddress: result.data,
              clickProperties: [
                {name: "platform" , value: window.navigator.platform, type : "STANDARD"},
                {name: "breakpoint-name" , value: this.$vuetify.breakpoint.name, type : "STANDARD"},
                {name: "share-on" , value: this.$route.query.s ? this.$route.query.s : 'undefined', type : "STANDARD"},
              ],
              userSessionId: this.$cookies.get('_migly')
              },
            )
          if(result.data.user)
            this.config = result.data.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0] ?  result.data.user.config : null
          if(result.data.biography) {
            this.loading = false
            this.biography = result.data.biography
          } else if(result.data.infoproduct){
            this.loading = false
            this.infoproduct = result.data.infoproduct
            this.infoproduct.user = this.address.user
          } else {
            this.loading = true
            setTimeout(() => {
              // this.showIndex = true
              if(!this.isCancel) {
                if(result.data.url.includes(`http`) || result.data.url.includes(`http`))
                  window.location.href = result.data.url
                else
                  window.location.href = `http://${result.data.url}`
              }
            }, this.config ? this.config.loaderTime * 1000 + 2000 : (!this.address.user || !this.address.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]) ? 4000 : 2000);
            if(this.config)
              setInterval(() => {
                if(!this.isCancel) 
                  this.progress = this.progress + (100 / this.config.loaderTime)
              }, 1000);
            else
              setInterval(() => {
                if(!this.isCancel) 
                  this.progress = this.progress + (100 / 4)
            }, 1000);
          }

          // apiV1Configs.getByOnlineAddress(result.data.code).then(resultConfig => {
          //   //this.loading = true
          //     if(resultConfig.data && this.address.user && this.address.user.subscriptionPlan.id == 3) {
          //       this.config = resultConfig.data
          //     }
          //     setTimeout(() => {
          //       if(result.data.biography) {
          //         this.loading = false
          //         this.biography = result.data.biography
          //       } else if(result.data.infoproduct){
          //         this.loading = false
          //         this.infoproduct = result.data.infoproduct
          //         this.infoproduct.user = this.address.user
          //       } else {
          //         //this.showIndex = true
          //         // if(result.data.url.includes(`http`) || result.data.url.includes(`http`))
          //         //   window.location.href = result.data.url
          //         // else
          //         //   window.location.href = `http://${result.data.url}`
          //       }
          //     }, this.config ? this.config.loaderTime * 1000 : (!this.address.user || !this.address.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]) ? 4000 : 2000);

          //     if(this.config)
          //       setInterval(() => {
          //         this.progress = this.progress + (100 / this.config.loaderTime)
          //       }, 1000);
          //     else
          //       setInterval(() => {
          //           this.progress = this.progress + (100 / 4)
          //         }, 1000);
          //   })
        }else {
          this.$router.push('/')
        }
      })
    
      if(!this.$route.query.countClick)
        if(this.$route.params.contactCode) {
          apiV1ContactAccessAddress.save({
            smsShootingHasContact: {contact: {code: this.$route.params.contactCode}}, 
            smsShootingHasOnlineAddress: {onlineAddress: {code: this.$route.params.code}}}
            )
        }

    },
    methods: {
      isLoged: function() {
        return !!localStorage.getItem("authorization")
      }
    },
  }
</script>
<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 600px !important; 
  }
}

@media (min-width: 960px) {
  .container {
      max-width: 600px !important; 
  }
}

/* styles.css */

.ad-banner {
    display: inline-block;
    animation: fade 3s ease-in-out infinite;
}

@keyframes fade {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
}

</style>