import http from "../axios";

let apiV1PrivacyPolicy = {
	baseUrl: '/api/v1/privacy-policy',
	page: async (filter) => {
		return await http.get(`${apiV1PrivacyPolicy.baseUrl}
		?page=${filter.page}
		&size=${filter.size}
		${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.status ? `&status=${filter.status}` : ''}
		`);
	},
}

export  { apiV1PrivacyPolicy }