import http from "../axios";

let apiV1Configs = {
	baseUrl: '/api/v1/configs',
	post: async (dto) => {
		return await http.post(apiV1Configs.baseUrl, dto);
	},
	patch: async (dto) => {
		return await http.patch(apiV1Configs.baseUrl, dto);
	},
	getByOnlineAddress: async (code) => {
		return await http.get(`${apiV1Configs.baseUrl}/online-addresses/${code}`);
	},
	getByAuth: async () => {
		return await http.get(`${apiV1Configs.baseUrl}/auth`);
	},
}

export  { apiV1Configs }