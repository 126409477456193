<template>
  <v-main>
    <router-view :screenData="screenData" ></router-view>
  </v-main>
</template>

<script>
export default {
    name: 'PainelInfoProduct',
    components: {
    },
    props: ['screenData'],
    data: () => ({
    }),
    computed: {
    },
    mounted: function() {
    },
    methods: {
    },
  }
</script>
<style>
</style>