<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" xs="12" sm="12" md="7" class="pl-0 ml-0 full-height">
        <v-card-title class="primary--text pa-0 mt-4">
          <b>{{$t('contacts')}}</b>
          <v-spacer/>
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            icon
            :disabled="!smsConfig"
            @click="dialogSaveContacs = true, contacts.push({number: '', segments: [], properties: [], tags: '', edit: true})"
          >
            <v-icon>mdi-account-multiple-plus-outline</v-icon>
          </v-btn>
          <v-menu offset-y left :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                :loading="loadingCsv"
                icon
                :disabled="!smsConfig"
              >
                <v-icon>mdi-file-upload</v-icon>
              </v-btn>
            </template>
            <v-card width="500" max-height="500" style="overflow: auto;">
              <v-card-title class="primary--text">
                <b>{{ $t('addRecipientByFile') }}</b>
              </v-card-title>
              <v-card-subtitle class="primary--text">
                {{ $t('loadCsv') }}
              </v-card-subtitle>
              <v-card-text>
                <v-file-input
                  :rules="[]"
                  accept="text/csv"
                  :placeholder="$t('addRecipientByFile')"
                  prepend-icon="mdi-file-delimited"
                  :label="$t('loadCsv')"
                  outlined
                  rounded
                  dense
                  flat
                  hide-details
                  hide-actions
                  hide-controls
                  @change="handleCSV"
                  :disabled="loadingCsv"
                />
                <v-progress-linear
                  v-if="loadingCsv"
                  class="mt-2"
                  color="blue"
                  indeterminate
                  rounded
                  height="2"
                />
              </v-card-text>
              <v-card-subtitle>
                {{ $t('addRecipientByFileDesc') }}
              </v-card-subtitle>
              <v-card-text>
                <v-btn @click="createFile()" block color="primary">
                  {{ $t('downloadFileTemplate') }}
                </v-btn>
              </v-card-text>
              <div v-for="template in $t('smsTemplateExemples')" :key="template.title">
                <v-card-subtitle class="primary--text">
                  {{ template.title }}
                </v-card-subtitle>
                <v-card-text>
                  <v-card>
                    <v-img :src="template.img"/>
                  </v-card>
                </v-card-text>
                <v-card-text>
                  {{ template.description }}
                </v-card-text>
                <v-divider/>
              </div>
              <v-card-text>
                <v-alert type="info">
                  {{ $t('infoSegmentsAndTags') }}
                </v-alert>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-menu offset-y right :close-on-content-click="false" >
            <template v-slot:activator="{ on, attrs }">
              <v-badge overlap bordered left color="red" :value="Object.keys(filter).map((key) => filter[key]).filter(v => Array.isArray(v) ? v.length :  v).length" :content="Object.keys(filter).map((key) => filter[key]).filter(v => Array.isArray(v) ? v.length :  v).length">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :disabled="!smsConfig"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <v-card width="500" max-height="500" style="overflow: auto;">
              <v-card-title class="primary--text">
                <b>{{ $t('filter') }}</b>
              </v-card-title>
              <v-card-subtitle>
                <v-alert type="info">
                  {{$t('inclusiveAndExclusiveFilter')}}
                  <v-divider class="mb-2"/>
                  <p>
                    <v-icon>mdi-toggle-switch-off</v-icon>
                    {{$t('inclusiveFilter')}}
                  </p>
                  <p>
                    <v-icon>mdi-toggle-switch</v-icon>
                    {{$t('exclusiveFilter')}}
                  </p>
                </v-alert>
              </v-card-subtitle>
              <v-card-text>
                <v-select
                    rounded
                    v-model="filter.status"
                    :items="statusItens"
                    :label="$t('status')"
                    outlined
                    :item-text="item => item.id ? $t(item.id) : $t('all')"
                    :item-value="item => item.id"
                    required
                    persistent-hint
                    flat
                    dense
                    @change="searchContacts(0)"
                  />
                <v-autocomplete
                  v-model="filter.segments"
                  flat
                  dense
                  :label="$t('segments')"
                  :placeholder="$t('segments')"
                  rounded
                  outlined
                  :search-input.sync="findSegments"
                  :items="segmentPage.content"
                  :item-text="v => v.name"  
                  return-object
                  hide-details
                  no-filter
                  @change="searchContacts(0), !filter.segments.length ? filter.segmentsExclusive = false : ''"
                  width="400"
                  multiple
                  small-chips
                  chips
                  clearable
                  >
                  <template v-slot:prepend-inner>
                    <v-switch inset v-model="filter.segmentsExclusive" :disabled="!filter.segments || !filter.segments.length" @change="searchContacts(0)"/>
                  </template>
                  <template v-slot:append>
                    <v-slide-x-transition leave-absolute hide-on-leave>
                      <v-progress-circular x-small  indeterminate width="1" size="22" v-if="loadingSegments" color="green"/>
                    </v-slide-x-transition>
                  </template>
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      small
                      color="green"
                      outlined
                      @click:close="filter.segments = filter.segments.filter(v => v != data.item || v.id != data.item.id),!filter.segments.length ? filter.segmentsExclusive = false : '',searchContacts(0)"
                    >
                      <v-icon>mdi-segment</v-icon>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-card-text>
              <v-card-text>
                <v-autocomplete
                  v-model="filter.tags"
                  flat
                  dense
                  :label="$t('tags')"
                  :placeholder="$t('tags')"
                  rounded
                  outlined
                  hide-details
                  :search-input.sync="findTags"
                  :items="tagPage.content"
                  no-filter
                  @change="searchContacts(0), !filter.tags.length ? filter.tagsExclusive = false : ''"
                  width="400"
                  multiple
                  small-chips
                  chips
                  clearable
                  >
                  <template v-slot:prepend-inner>
                    <v-switch inset v-model="filter.tagsExclusive" :disabled="!filter.tags || !filter.tags.length" @change="searchContacts(0)"/>
                  </template>
                  <template v-slot:append>
                    <v-slide-x-transition leave-absolute hide-on-leave>
                      <v-progress-circular x-small  indeterminate width="1" size="22" v-if="loadingTags" color="green"/>
                    </v-slide-x-transition>
                  </template>
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      small
                      color="primary"
                      outlined
                      @click:close="filter.tags = filter.tags.filter(v => v != data.item),!filter.tags.length ? filter.tagsExclusive = false : '', searchContacts(0)"
                    >
                      <v-icon>mdi-tag</v-icon>
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-card-text>
              <v-card-text>
                <v-autocomplete
                  v-model="filter.properties"
                  flat
                  dense
                  :label="$t('properties')"
                  :placeholder="$t('properties')"
                  rounded
                  outlined
                  hide-details
                  :search-input.sync="findProperties"
                  :items="propertyPage.content"
                  :item-text="v => v"  
                  no-filter
                  @change="searchContacts(0),!filter.properties.length ? filter.propertiesExclusive = false : ''"
                  multiple
                  small-chips
                  chips
                  clearable
                  >
                  <template v-slot:prepend-inner>
                    <v-switch inset v-model="filter.propertiesExclusive" :disabled="!filter.properties || !filter.properties.length" @change="searchContacts(0)"/>
                  </template>
                  <template v-slot:append>
                    <v-slide-x-transition leave-absolute hide-on-leave>
                      <v-progress-circular x-small  indeterminate width="1" size="22" v-if="loadingProperties" color="blue"/>
                    </v-slide-x-transition>
                  </template>
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      small
                      color="blue"
                      outlined
                      @click:close="filter.properties = filter.properties.filter(v => v != data.item),!filter.properties.length ? filter.propertiesExclusive = false : ''"
                    >
                      <v-icon>mdi-variable-box</v-icon>
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer/>
                <v-btn text color="red" @click="$router.push({query: {}}),updateFilter(),searchContacts(0)">{{ $t('clearFilter') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-menu offset-y left :close-on-content-click="false" >
            <template v-slot:activator="{ on, attrs }">
              <v-badge overlap bordered left color="red" :value="0" :content="0">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :disabled="!smsConfig"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <v-card width="500" max-height="500" style="overflow: auto;">
              <v-card-title class="primary--text">
                <b>{{ $t('moreActions') }}</b>
              </v-card-title>
            </v-card>
          </v-menu>
        </v-card-title>
        <v-card-actions  class="pl-0 pr-0 ml-0 mr-0">
          <v-btn class="ml-1 primary--text" x-small color="white" rounded light elevation="0" disabled>
            {{contactPage.totalElements}}
          </v-btn>
          <v-spacer/>
          <v-chip-group show-arrows>
            <v-btn class="ml-1 primary--text" x-small :disabled="(page -1) == contactPage.number" color="white" rounded light v-for="page in contactPage.totalPages" :key="page" @click="searchContacts(page -1)">
              <v-icon x-small v-if="(page -1) == contactPage.number">mdi-circle-medium</v-icon>
              {{$t('page')}} {{page}}
            </v-btn>
          </v-chip-group>
        </v-card-actions>
        <v-card-text class="pa-0">
          <v-row no-gutters>
            <v-col cols="12" v-if="!smsConfig">
              <v-alert type="info">
                {{ $t('businerRequiredInfo') }}
                <v-card-actions>
                  <v-spacer/>
                  <v-btn icon @click="$router.push(`/painel/sms?smsConfig=true`)">
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-alert>
            </v-col>
            <v-col cols="12" v-else>
              <v-alert type="info" v-html="$t('smsHelp')" color="blue lighten-2"/>
            </v-col>
            <v-col cols="12" v-if="countSaved">
              <v-alert type="error" outlined>
                {{ $t('sendRequireOptInAllSavedInfo').replace("_", countSaved) }}
                <v-divider/>
                <v-card-actions class="pl-0">
                  <v-btn color="green" outlined @click="dialogCredits = true" :loading="loadingCredits" rounded small>
                    <u>{{ new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(shootingCreditPage.content.map(item => item.remaining).reduce((partialSum, a) => partialSum + a, 0)) }}</u>
                  </v-btn>
                  <v-spacer/>
                  <v-btn
                    text
                    @click="confimOptInAll.dialog = true"
                    :loading="loadingOptInRequired"
                    color="primary"
                  >
                    {{ $t('sendRequireOptInAllSaved') }} {{ countSaved }}
                  </v-btn>
                </v-card-actions>
              </v-alert>
            </v-col>
            <v-col v-for="contact, index in contactPage.content" :key="index" cols="12" sm="12" md="12">
              <v-card elevation="0" outlined class="mb-2">
                <v-card-title class="primary--text">
                  +{{ contact.number }}
                  <v-spacer/>
                  <v-menu
                    v-if="contact.status == 'SAVED'"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="warning"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        icon
                      >
                        <v-icon>mdi-block-helper</v-icon>
                      </v-btn>
                    </template>

                    <v-card width="500">
                      <v-card-title class="warning--text">
                        <b>{{$t('optInRequired')}}</b>
                        <v-spacer/>
                        <v-icon color="warning">mdi-block-helper</v-icon>
                      </v-card-title>
                      <v-card-subtitle>
                        {{ $t('optInRequiredSubtitle') }}
                      </v-card-subtitle>
                      <v-divider/>
                      <v-card-text>
                        {{ $t('optInRequiredText') }}
                      </v-card-text>
                      <v-card-text>
                        <v-card elevation="0" outlined>
                          <v-card-text class="black--text">
                            {{ $t('exempleSmsOptInDesc').replace(/_/gi, smsConfig.business.toUpperCase()) }}
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                      <v-card-subtitle class="pt-0 pb-0">
                        <v-alert type="info">{{ $t('messageSendoptInRequired') }}</v-alert>
                      </v-card-subtitle>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="requireOptIn(contact)"
                          :loading="loadingOptInRequired"
                        >
                          {{ $t('requireOptIn') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                  <v-menu
                    v-else-if="contact.status == 'OPT_IN_REQUIRED'"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        icon
                      >
                        <v-icon>mdi-square-rounded-badge-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-card width="500">
                      <v-card-title class="red--text">
                        <b>{{$t('optInRequiredSend')}}</b>
                        <v-spacer/>
                        <v-icon color="red">mdi-square-rounded-badge-outline</v-icon>
                      </v-card-title>
                      <v-card-subtitle>
                        {{ $t('optInRequiredSendSubtitle') }}
                      </v-card-subtitle>
                    </v-card>
                  </v-menu>
                  <v-menu
                    v-else-if="contact.status == 'OPT_IN_SENDED'"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        icon
                      >
                        <v-icon>mdi-message-badge-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-card width="500">
                      <v-card-title class="red--text">
                        <b>{{$t('optInRequiredSend')}}</b>
                        <v-spacer/>
                        <v-icon color="red">mdi-message-badge-outline</v-icon>
                      </v-card-title>
                      <v-card-subtitle>
                        {{ $t('optInRequiredSendSubtitle') }}
                      </v-card-subtitle>
                    </v-card>
                  </v-menu>
                  <v-menu
                    v-else-if="contact.status == 'OPT_IN'"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        icon
                      >
                        <v-icon>mdi-check-circle</v-icon>
                      </v-btn>
                    </template>

                    <v-card width="500">
                      <v-card-title class="green--text">
                        <b>{{$t('optInRequiredSendAccept')}}</b>
                        <v-spacer/>
                        <v-icon color="green">mdi-check-circle</v-icon>
                      </v-card-title>
                      <v-card-subtitle>
                        {{ $t('optInRequiredSendAcceptSubtitle') }}
                      </v-card-subtitle>
                    </v-card>
                  </v-menu>
                  <v-menu
                    v-else-if="contact.status == 'OPT_OUT'"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        icon
                      >
                        <v-icon>mdi-cellphone-message-off</v-icon>
                      </v-btn>
                    </template>

                    <v-card width="500">
                      <v-card-title class="error--text">
                        <b>{{$t('OPT_OUT')}}</b>
                        <v-spacer/>
                        <v-icon color="error">mdi-cellphone-message-off</v-icon>
                      </v-card-title>
                    </v-card>
                  </v-menu>
                  <div v-else>
                    {{ $t(contact.status) }}
                  </div>
                </v-card-title>
                <v-card-subtitle class="pb-0 mb-0">
                    <v-chip-group column>
                      <v-chip v-for="segment, index in contact.segments" :key="index" small color="green" outlined>
                        <v-icon class="mr-1" small>mdi-segment</v-icon>
                        {{ segment.name }}
                      </v-chip>
                    </v-chip-group>
                </v-card-subtitle>
                <v-card-subtitle class="pb-0 mb-0">
                    <v-chip-group row>
                      <v-chip v-for="tag, index in contact.tags.split(',')" :key="index" x-small color="blue" outlined v-show="tag">
                        <v-icon class="mr-1" x-small>mdi-tag</v-icon>
                        {{ tag }}
                      </v-chip>
                    </v-chip-group>
                </v-card-subtitle>
                <v-divider/>
                <v-card-text class="pt-0 mt-0 pb-0">
                  <v-chip-group column>
                    <v-chip v-for="p, index in contact.properties" :key="index" color="blue" dark x-small>
                      <v-icon class="mr-1" x-small>mdi-variable</v-icon>
                      {{ p.name }}: {{ p.value }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
      <v-col md="5" v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)" class="full-height pa-4 pr-0">
        <v-card class="card-corner-radius" elevation="0" style="height: 100%;">
          <v-card-title class="blue--text">
            <v-icon color="blue" class="mr-2">mdi-timeline</v-icon>
            {{$t('metricsAndTimeLine')}}
          </v-card-title>
          <v-card-actions>
            <v-spacer/>
            {{$t('comingSoon')}}
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogSaveContacs" width="800" persistent>
      <v-card class="card-corner-radius" color="grey lighten-3">
        <v-form v-model="formContacts" ref="formContacts" :disabled="loadingSaveContacts">
          <v-card-title class="primary--text pr-4 pl-4">
            <v-btn icon color="primary" @click="dialogSaveContacs = false" :disabled="loadingSaveContacts">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <b>{{ $t('contacts') }}</b>
            <v-spacer/>
            <v-btn icon color="primary" @click="contacts.splice(0, 0, {number: '', segments: [], properties: [], tags: '', edit: true})" class="mr-2">
              <v-icon x-large>mdi-plus-circle</v-icon>
            </v-btn>
            <v-progress-circular :value="contacts.length / totalContatcs * 100" color="primary"/>
          </v-card-title>
          <v-card-text style="overflow: auto; max-height: 60vh;">
            <v-row>
              <v-col cols="12">
                <v-card elevation="0">
                  <v-card-actions>
                    <v-card class="pa-1 card-corner-radius" elevation="0" outlined dense flat>
                      <small>
                        {{ contacts.length }}
                      </small>
                    </v-card>
                    <v-spacer/>
                    <v-chip-group>
                      <v-chip color="primary" :disabled="page == lote" v-for="page in Math.floor(contacts.length / 100)" :key="page" @click="lote = page" small>{{ page }}</v-chip>
                    </v-chip-group>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="4" v-for="contact, index in pageContatcs()" :key="index">
                <v-card outlined :color="isInvalid(contact) ? 'error lighten-3' : ''">
                  <v-card-text v-if="contact.edit">
                    <v-text-field
                      v-model="contact.number"
                      class="mt-1"
                      outlined
                      rounded
                      dense
                      flat
                      counter="13"
                      max="13"
                      type="number"
                      :maxlength="13"
                      :placeholder="5511999999999"
                      :label="$t('number')"
                      :rules="[
                        v => !!v || $t('required'),
                        v => !!v && v.length == 13 || $t('invalidSize'),
                      ]"
                    />
                  </v-card-text>
                  <div v-else>
                    <v-card-title class="primary--text"><b>+{{ contact.number }}</b></v-card-title>
                    <v-card-subtitle class="pb-0 mb-0">
                      <v-chip-group column>
                        <v-chip v-for="segment, index in contact.segments" :key="index" x-small color="green" outlined>
                          <v-icon class="mr-1" x-small>mdi-segment</v-icon>
                          {{ segment.name }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-subtitle>
                    <v-card-subtitle class="pb-0 mb-0">
                      <v-chip-group row>
                        <v-chip v-for="tag, index in contact.tags.split(',')" :key="index" x-small color="blue" outlined>
                          <v-icon class="mr-1" x-small>mdi-tag</v-icon>
                          {{ tag }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-subtitle>
                    <v-divider/>
                    <v-card-text class="pt-0 mt-0">
                      <v-chip-group column>
                        <v-chip v-for="p, index in contact.properties" :key="index" color="blue" dark x-small>
                          <v-icon class="mr-1" x-small>mdi-variable</v-icon>
                          {{ p.name }}: {{ p.value }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" :loading="loadingSaveContacts || loadingCsv" @click="contacts = contacts.filter(c => !isInvalid(c))" :disabled="!contacts.length || !contacts.filter(c => isInvalid(c)).length" rounded>
              {{ $t('cleanInvalidContacts') }}
            </v-btn>
            <v-spacer/>
            <v-btn color="primary" :loading="loadingSaveContacts || loadingCsv" @click="saveContacts" :disabled="!contacts.length || !formContacts" rounded>
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-fade-transition>
          <v-overlay
            absolute
            v-model="loadingCsv"
            color="grey"
          >
            <v-chip color="blue">
              {{contacts.length}} / {{totalContatcs}}
            </v-chip>
          </v-overlay>
        </v-fade-transition>
      </v-card>
      <v-overlay absolute v-model="loadingSaveContacts"/>
    </v-dialog>
    <v-dialog v-model="dialogCredits" width="800" persistent>
      <v-card class="card-corner-radius" color="grey lighten-3">
        <v-card-title class="primary--text pr-4 pl-4">
          <v-btn icon color="primary" @click="dialogCredits = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <b>{{ $t('credit') }}</b>
          <v-spacer/>
        </v-card-title>
        <credits :confirmAction="() => {dialogCredits = false, searchCredits()}"/>
      </v-card>
    </v-dialog>

    <ConfirmOperation :data="confimOptInAll" :confimAction="requireOptInForAll" :text="$t('totalCostWillBe').replace('_', new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(0.3 * countSaved))"/>
    <v-overlay v-model="loadingOptInRequired"/>
  </div>
</template>
<script>
import { apiV1Credit } from '../../service/resources/api-v1-credits'
import { apiV1SmsConfig } from '../../service/resources/api-v1-sms-configs'
import {apiV1Contacts} from './../../service/resources/api-v1-contacts'
import {apiV1Segments} from './../../service/resources/api-v1-segments'
import ConfirmOperation from './fragments/ConfirmOperation.vue'
import Credits from './fragments/Credits.vue'
  
  export default {
    name: 'ContactsPage',
    props: ['screenData'],
    components: {
    Credits,
    ConfirmOperation
},
    data: () => ({
      confimOptInAll: {
        dialog: false,
      },
      dialogCredits: false,
      filter: {status: null, text: null, segments: [], tags: [], properties: [], segmentsExclusive: false,tagsExclusive: false,propertiesExclusive: false},
      shootingCreditPage: {content: []},
      statusItens: [
        {id: null},
        {id: 'SAVED'},
        {id: 'OPT_IN_REQUIRED'},
        {id: 'OPT_IN_SENDED'},
        {id: 'OPT_IN_REQUIRED_ERROR'},
        {id: 'OPT_IN'},
        {id: 'OPT_OUT'},
      ],
      formContacts: false,
      contactPage: {
        content: []
      },
      loadingTags: false,
      loadingContacts: false,
      loadingCsv: false,
      contacts: [],
      dialogSaveContacs: false,
      loadingSaveContacts: false,
      smsConfig: null,
      loadingOptInRequired: false,
      loadingGetSmsConfig: false,
      countSaved: 0,
      tagPage: {
        content: []
      },
      select: null,
      segmentPage: {
        content: []
      },
      loadingSegments: false,
      findSegments: null,
      findTags: null,
      loadingCredits: false,
      totalContatcs: 0,
      lote: 1,
      propertyPage: {
        content: []
      },
      loadingProperties: false,
      findProperties: null
    }),
    mounted: function() {
      this.updateFilter()
      this.searchCredits()
      this.getSmsConfig()
      this.searchContacts(this.$route.query.page ? this.$route.query.page : 0)
      this.searchSegments(null)
      this.searchContactsTags(null)
      this.searchContactsProperties(null)
    },
    computed: {
    },
    watch: {
      '$route': function() {
        this.getSmsConfig()
      },
      '$route.query.text': function() {
        this.updateFilter()
        this.searchContacts(0)
      },
      '$route.query.select': function() {
        this.updateFilter()
        this.searchContacts(0)
      },
      'findTags': function(tag) {
        this.searchContactsTags(tag)
      },
      'findSegments': function (text) {
        this.searchSegments(text)
      },
      'findProperties': function (text) {
        this.searchContactsProperties(text)
      }
    },
    methods: {
      isInvalid: function(contact) {
        return `${contact.number}`.length < 13 || !`${contact.number}`.startsWith('55')
      },
      pageContatcs: function() {
        console.log(this.lote)
        var result = []
        this.contacts.forEach((item, index) => {
          if(index >= ((this.lote -1) * 100) && index < (this.lote * 100)) {
            result.push(item)
          }
        })
        return result
      },
      updateFilter: function(){
        this.select = this.$route.query.select
        this.filter.status = this.$route.query.status
        this.filter.tags = this.$route.query.tags ? Array.isArray(this.$route.query.tags) ? this.$route.query.tags : [this.$route.query.tags] : []
        this.filter.text = this.$route.query.text
        this.filter.properties = this.$route.query.properties
        this.filter.segments = this.$route.query.segments ? Array.isArray(this.$route.query.segments) ? this.$route.query.segments.map(v => ({id: v})) : [{id: this.$route.query.segments}] : []
        this.filter.segmentsExclusive = this.filter.segments && this.filter.segments.length ? this.$route.query.segmentsExclusive : false
        this.filter.tagsExclusive = this.filter.tags && this.filter.tags.length ? this.$route.query.tagsExclusive : false
        this.filter.propertiesExclusive = this.filter.properties && this.filter.properties.length ? !!this.$route.query.propertiesExclusive : false
        
        if(this.$route.query.segments) {
          this.loadingSegments = true
          apiV1Segments.page({page: 0, size: 100, text: null, ids: this.$route.query.segments}).then(result => {
            this.filter.segments = result.data.content
            this.segmentPage = result.data
            this.loadingSegments = false
          })
        }
      },
      getSmsConfig: function(){
        this.loadingGetSmsConfig = true
        apiV1SmsConfig.get().then(result => {
          this.smsConfig = result.data
          this.loadingGetSmsConfig = false
        }).catch(() => {
          // this.$commons.updateSnackbar({show: true, color: 'red', text: result})
          // this.loadingGetSmsConfig = false
        })
      },
      searchContacts: function(page) {
        var query = JSON.parse(JSON.stringify(this.$route.query))
        query.status = this.filter.status
        query.tags = this.filter.tags
        query.text = this.filter.text
        query.properties = this.filter.properties
        query.segmentsExclusive = this.filter.segmentsExclusive
        query.tagsExclusive = this.filter.tagsExclusive
        query.propertiesExclusive = this.filter.propertiesExclusive
        
        query.segments = this.filter.segments && this.filter.segments.length ? this.filter.segments.map(v => v.id) : undefined
        this.$router.push({query : query}).catch(()=>{})

        this.loadingContacts = true
        apiV1Contacts.page({page: page, size: 10, 
          status: this.filter.status, 
          tags: this.filter.tags, 
          text: this.filter.text, 
          embedSegments: true, 
          segmentIds: this.filter.segments.map(v => v.id), 
          propertiesNames : this.filter.properties, 
          segmentsExclusive: this.filter.segmentsExclusive,
          tagsExclusive: this.filter.tagsExclusive, 
          propertiesExclusive: this.filter.propertiesExclusive
        }).then(result => {
          this.contactPage = result.data
          this.loadingContacts = false
          if(query.select && this.contactPage.content.filter(v => v.id == query.select)[0]) {
            this.contactPage.content.splice(0, 0, this.contactPage.content.splice(this.contactPage.content.indexOf(v => v.id == query.select), 1)[0])
          }
        })
        this.searchContactsSaved()
      },
      searchContactsSaved: function() {
        apiV1Contacts.page({page: 0, size: 1, status: 'SAVED'}).then(result => {
          this.countSaved = result.data.totalElements
        })
      },
      createFile() {
        var coll = this.$t('number');
        [this.$t('segments'),this.$t('tags'), this.$t('name'), this.$t('age')].filter(v => v).forEach(v => {
          coll = `${coll};${v}`
        })
        const file = new Blob([coll], {'template': 'text/csv'});
    
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file, `${this.$t('sms')}-${this.$t('template')}.csv`)
          return
        }
        
        const a = document.createElement("a")
        const url = URL.createObjectURL(file)
            
        a.href = url;
        a.download =  `${this.$t('sms')}-${this.$t('template')}.csv`
        
        document.body.appendChild(a);
        
        a.click();
        
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);  
        },0); 

      },
      handleCSV: function(event) {
        this.contacts = []
        this.totalContatcs = 0
        if(event) {
          this.loadingCsv = true
          var reader = new FileReader()
          reader.readAsText(event)
          reader.onload = () => {
            console.log(reader.result)
            if(reader.result) {
              this.dialogSaveContacs = true
              let rows = reader.result.split(/\r\n|\n/);
              let headers = rows[0].split(';').length ? rows[0].split(';') : rows[0].split(',')
              this.totalContatcs = rows.length -1
              var list = []
              rows.forEach((row, r) => {
                if(r != 0) {
                  list.push(row)
                  if(list.length % 100 == 0 || r >= this.totalContatcs){
                    let data = list.filter(v => v)
                    setTimeout(() => {
                      data.forEach((row) => {
                          let contato = {number: '', segments: [], properties: [], tags: '', success: null}
                          let cols = row.split(';').length ? row.split(';') : row.split(',')
                          
                          cols.forEach((col, c) => {
                            if(c == 0)
                              contato.number = parseInt(col)
                            else if(c == 1)
                              col.split(',').forEach(value => contato.segments.push({name: value.trim()}))
                            else if(c == 2)
                              contato.tags = col
                            else {
                              contato.properties.push({name: headers[c], value: col})
                            }
                          })
                          this.contacts.push(contato)
                          this.contacts.sort((a, b) => !this.isInvalid(a) - !this.isInvalid(b));
                          if(this.contacts.length >= this.totalContatcs -1)
                            this.loadingCsv = false
                      })
                    }, 1);
                    list = []
                  }
                }
              })
            }else {
              this.loadingCsv = false
            }
          }
          setTimeout(() => {
            if(this.$refs.formContacts)
              this.$refs.formContacts.validate()
          }, 1000);
        }
      },
      saveContacts: function() {
        this.loadingSaveContacts = true
          apiV1Contacts.saveAll(this.contacts).then(() => {
            this.loadingSaveContacts = false
            this.searchContacts(0)
            this.dialogSaveContacs = false
            this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('saved')})
            this.contacts = []
            this.totalContatcs = 0
          }).catch(() => {
            this.loadingSaveContacts = false
          })
      },
      requireOptIn: function(contact){
        this.loadingOptInRequired = true
        apiV1Contacts.optInRequest(contact.id).then((result) => {
          this.loadingOptInRequired = false
          this.contactPage.content.forEach((element, index) => {
              if(element.id == result.data.id)
                this.contactPage.content[index] = result.data
            })
          this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('success')})
          this.searchContactsSaved()
          this.searchCredits()
        }).catch(() => {
          this.loadingOptInRequired = false
        })
      },
      requireOptInForAll: function(){
        this.loadingOptInRequired = true
        apiV1Contacts.optInRequestAll().then(() => {
          this.loadingOptInRequired = false
          this.searchContacts(0)
          this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('success')})
        }).catch(() => {
          this.loadingOptInRequired = false
        })
      },
      searchContactsTags: function(tagLike) {
        this.loadingTags = true
        apiV1Contacts.pageTags({page: 0, size: 100, tagLike: tagLike}).then(result => {
          this.tagPage = result.data
          this.loadingTags = false
        })
      },
      searchContactsProperties: function(nameStartWith) {
        this.loadingProperties = true
        apiV1Contacts.pageProperties({page: 0, size: 100, nameStartWith: nameStartWith}).then(result => {
          this.propertyPage = result.data
          this.loadingProperties = false
        })
      },
      searchSegments: function(text) {
        this.loadingSegments = true
        apiV1Segments.page({page: 0, size: 100, text: text}).then(result => {
          this.segmentPage = result.data
          this.loadingSegments = false
        })
      },
      searchCredits: function() {
        this.loadingCredits = true
        apiV1Credit.page({statusIn: ['PAID', 'SUCCESS'], page: 0, size: 100, orderBy: 'dateCreate:DESC'}).then(result => {
          this.shootingCreditPage = result.data
          this.loadingCredits = false
        })
      },
    },
  }
</script>
<style scoped>

</style>