import http from "../axios";

let apiV1Biographies = {
	baseUrl: '/api/v1/biographies',
	post: async (dto) => {
		return await http.post(apiV1Biographies.baseUrl, dto);
	},
	patch: async (dto) => {
		return await http.patch(apiV1Biographies.baseUrl, dto);
	},
	get: async (id) => {
		return await http.get(`${apiV1Biographies.baseUrl}/${id}`);
	},
	page: async (filter) => {
		return await http.get(`${apiV1Biographies.baseUrl}?page=${filter.page}&size=${filter.size}
			${filter.embedBiographyHasOnlineAddress ? `&embedBiographyHasOnlineAddress=${filter.embedBiographyHasOnlineAddress}` : ''}
			${filter.embedSocialLink ? `&embedSocialLink=${filter.embedSocialLink}` : ''}
			${filter.userAuth ? `&userAuth=${filter.userAuth}` : ''}`);
	}
}

export  { apiV1Biographies }